import React, { useContext } from 'react';
import myAxios from '../../myAxios';
import {
  Table,
  Space,
  Spin,
  Row,
  Col,
  message,
  Tooltip,
  Form,
  Input,
  Select,
  Modal,
} from 'antd';
import { Button } from 'semantic-ui-react';
import { LoadingOutlined } from '@ant-design/icons';
import { motion } from 'framer-motion';
import { useState, useEffect } from 'react';
import { UserContext } from '../../context/UserContext';
import { ArrowUpRight } from 'react-bootstrap-icons';
import Waiting from '../../assets/img/waiting_search.svg';
import NoData from '../../assets/img/nodata.svg';
import { useHistory } from 'react-router-dom';

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
const tableLoading = {
  indicator: <Spin className='loading-data-table' indicator={antIcon} />,
};

const optionsDate = {
  weekday: 'long',
  year: 'numeric',
  month: 'long',
  day: 'numeric',
};
const Pencarian = () => {
  const [user, setUser] = useContext(UserContext);

  const [loading, setLoading] = useState(false);
  const [loadingT, setLoadingT] = useState(false);
  const [loadingAct, setLoadingAct] = useState(false);

  const [modalVisible, setModal] = useState(false);
  const [pencarian, setPencarian] = useState(true);
  const [detail, setDetail] = useState([]);
  const [search, setSearch] = useState('');

  const [modalCon, setModalCon] = useState(null);
  let history = useHistory();
  const [form] = Form.useForm();

  useEffect(() => {}, []);

  const getHighlightedText = (text, highlight) => {
    const parts = text.split(new RegExp(`(${highlight})`, 'gi'));
    return (
      <span>
        {parts.map((part, i) => (
          <span
            key={i}
            style={
              part.toLowerCase() === highlight.toLowerCase()
                ? { backgroundColor: 'yellow' }
                : {}
            }>
            {part}
          </span>
        ))}
      </span>
    );
  };

  const onChange = (param) => {
    setSearch({ ...search, kata_kunci: param.target.value });
  };

  const onChangeKategori = (param) => {
    setSearch({ ...search, kategori: param });
  };

  const handleCancel = () => {
    setModal(false);
  };

  const onFinish = (values) => {
    setLoading(true);
    setLoadingT(tableLoading);
    // setPencarian(true);
    let newObj = {
      kata_kunci: values.kata_kunci,
      kategori: values.kategori,
    };

    myAxios
      .post(`search`, newObj, {
        headers: {
          Authorization: 'Bearer ' + user.token,
        },
      })
      .then((res) => {
        setSearch({ kategori: newObj.kategori, kata_kunci: newObj.kata_kunci });
        setPencarian(false);
        setLoading(false);
        setLoadingT(false);
        setDetail(res.data.data.data);
        if (res.data.data.data.length === 0) {
          setPencarian('Tidak Ada Hasil');
        }
      })
      .catch((err) => {
        if (err.response.data.message === 'unauthorized') {
          message.error('Sesi anda telah habis!');
          localStorage.removeItem('user');
          setUser(null);
        } else if (err.response.data.msg === 'Unauthorization Role') {
          message.error(err.response.data.msg);
          history.goBack();
        } else {
          message.error(err.response.data.msg);
        }

        setLoading(false);
      });
  };

  const onFinishFailed = (errorInfo) => {};

  const onDetail = (param) => {
    setLoadingAct(true);

    let newObj = {
      id_rapat: param.id,
      kata_kunci: search.kata_kunci,
      kategori: search.kategori,
    };

    myAxios
      .post(`search-detail`, newObj, {
        headers: {
          Authorization: 'Bearer ' + user.token,
        },
      })
      .then((res) => {
        const data = res.data.data;

        setModal(true);

        setModalCon({
          judul: 'Detail Pencarian',
          dataRapat: data.dataRapat,
          dataDetail: data.dataDetail,
          content: data.content,
        });
        setLoadingAct(false);
      })
      .catch((err) => {
        if (err.response.data.message === 'unauthorized') {
          message.error('Sesi anda telah habis!');
          localStorage.removeItem('user');
          setUser(null);
        } else if (err.response.data.msg === 'Unauthorization Role') {
          message.error(err.response.data.msg);
          history.goBack();
        } else {
          message.error(err.response.data.msg);
        }

        setLoadingAct(false);
      });
  };

  const columns = [
    {
      title: 'Nama Tim',
      dataIndex: 'team',
      key: 'team',
      sorter: (a, b) => a.team.length - b.team.length,
      ellipsis: true,
    },
    {
      title: 'Tanggal Rapat',
      dataIndex: 'date',
      key: 'date',
      sorter: (a, b) => a.date.length - b.date.length,
      ellipsis: true,
      render: (text) => (
        <>{new Date(text).toLocaleDateString('id-ID', optionsDate)}</>
      ),
    },
    {
      title: 'Nama Rapat',
      dataIndex: 'name',
      key: 'name',
      sorter: (a, b) => a.name.length - b.name.length,
      ellipsis: true,
    },

    {
      key: 'action',
      dataIndex: 'id',
      align: 'center',
      render: (dataIndex, row) => (
        <div>
          {!loadingAct && (
            <>
              {search.kategori !== 'NamaRapat' && (
                <Space size='middle'>
                  <Tooltip
                    placement='bottom'
                    title='Lihat Detail'
                    color='#1f1f1f'
                    key='white'>
                    <Button
                      className='aksiEdit'
                      color='orange'
                      size='mini'
                      icon={<ArrowUpRight />}
                      onClick={() => onDetail(row)}></Button>
                  </Tooltip>
                </Space>
              )}
            </>
          )}
          {loadingAct && (
            <Spin className='loading-data-table' indicator={antIcon} />
          )}
        </div>
      ),
    },
  ];

  const columnsDetail = [
    {
      title: 'Nama',
      dataIndex: 'name',
      key: 'name',
      width: '30%',
      sorter: (a, b) => a.name.length - b.name.length,
    },
    {
      title: 'Agenda',
      dataIndex: 'agenda',
      key: 'agenda',
      sorter: (a, b) => a.agenda.length - b.agenda.length,
      ellipsis: true,
    },
    {
      title: 'Detail',
      dataIndex: 'detail',
      key: 'detail',
      sorter: (a, b) => a.detail.length - b.detail.length,
      render: (text) => <>{getHighlightedText(text, search.kata_kunci)}</>,
    },
  ];
  const columnsDetailAgenda = [
    {
      title: 'Agenda',
      dataIndex: 'detail',
      key: 'detail',
      sorter: (a, b) => a.detail.length - b.detail.length,
      ellipsis: true,
      render: (text) => <>{getHighlightedText(text, search.kata_kunci)}</>,
    },
  ];

  return (
    <div className='container-content karyawan'>
      <div className='container-table'>
        <h1 className='table-title'>PENCARIAN</h1>
        <div className='scrollable'>
          <Form
            layout='vertical'
            form={form}
            name='basic'
            initialValues={{ remember: false }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}>
            <Row justify='space-between'>
              <Col md={6}>
                <Form.Item
                  labelAlign='left'
                  name='kategori'
                  rules={[
                    {
                      required: true,
                      message: 'Bagian ini wajib diisi',
                    },
                  ]}>
                  <Select
                    placeholder='Masukan kategori'
                    onChange={onChangeKategori}>
                    <Select.Option value='NamaRapat'>Nama Rapat</Select.Option>
                    <Select.Option value='Agenda'>Agenda</Select.Option>
                    <Select.Option value='Kesimpulan'>Kesimpulan</Select.Option>
                    <Select.Option value='MinuteMeeting'>
                      Minute Meeting
                    </Select.Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col md={13}>
                <Form.Item
                  labelAlign='left'
                  name='kata_kunci'
                  rules={[
                    {
                      message: 'Bagian ini wajib diisi!',
                      required: true,
                    },
                  ]}>
                  <Input name='kata_kunci' placeholder='Masukan kata kunci' />
                </Form.Item>
              </Col>
              <Col md={4}>
                <Form.Item>
                  <Button
                    style={{ width: '100%' }}
                    floated='right'
                    type='submit'
                    loading={loading}
                    color='green'>
                    Cari
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
          {pencarian === true && (
            <>
              <motion.img
                initial={{ opacity: 0, y: -100 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 1 }}
                className='imgDiv'
                style={{
                  marginTop: '20px',
                  width: '100%',
                  height: '150px',
                  display: 'flex',
                  justifyContent: 'center',
                }}
                src={Waiting}
                alt='404.svg'
              />
              <p
                style={{
                  textAlign: 'center',
                  marginTop: '10px',
                  lineHeight: '0',
                }}>
                <motion.h3
                  style={{
                    fontWeight: 'normal',
                    fontSize: '15px',
                    color: 'grey',
                  }}
                  initial={{ opacity: 0, x: -25 }}
                  animate={{ opacity: 1, x: 0 }}
                  transition={{ duration: 0.6, delay: 0.6 }}>
                  Menunggu pencarian ...
                </motion.h3>
              </p>
            </>
          )}
          {pencarian === 'Tidak Ada Hasil' && (
            <>
              <motion.img
                initial={{ opacity: 0, y: -100 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 1 }}
                className='imgDiv'
                style={{
                  marginTop: '20px',
                  width: '100%',
                  height: '150px',
                  display: 'flex',
                  justifyContent: 'center',
                }}
                src={NoData}
                alt='404.svg'
              />
              <p
                style={{
                  textAlign: 'center',
                  marginTop: '10px',
                  lineHeight: '0',
                }}>
                <motion.h3
                  style={{ fontWeight: 'normal', fontSize: '15px' }}
                  initial={{ opacity: 0, x: -25 }}
                  animate={{ opacity: 1, x: 0 }}
                  transition={{ duration: 0.6, delay: 0.6 }}>
                  Tidak ada hasil
                </motion.h3>
              </p>
            </>
          )}
          {detail.length > 0 && (
            <Table
              loading={loadingT}
              loadingIndicator={antIcon}
              columns={columns}
              dataSource={detail}
              scroll={{ x: 900, y: 1500 }}
              size='small'
            />
          )}
        </div>

        {modalCon && (
          <Modal
            centered
            visible={modalVisible}
            onCancel={handleCancel}
            footer={[]}
            title={modalCon.judul}
            width={1000}>
            <div className='data-rapat'>
              <Row justify='start'>
                <Col md={8} xs={8}>
                  <label className='label-bold'>Nama Tim</label>
                </Col>

                <Col md={2} xs={2}>
                  :
                </Col>

                <Col md={14} xs={14}>
                  <label> {modalCon.dataRapat.team}</label>
                </Col>
              </Row>
              <Row justify='start'>
                <Col md={8} xs={8}>
                  <label className='label-bold'>Tanggal Rapat</label>
                </Col>

                <Col md={2} xs={2}>
                  :
                </Col>

                <Col md={14} xs={14}>
                  <label>
                    {new Date(modalCon.dataRapat.date).toLocaleDateString(
                      'id-ID',
                      optionsDate
                    )}
                  </label>
                </Col>
              </Row>
              <Row justify='start'>
                <Col md={8} xs={8}>
                  <label className='label-bold'>Nama Rapat</label>
                </Col>
                <Col md={2} xs={2}>
                  :
                </Col>
                <Col md={14} xs={14}>
                  <label> {modalCon.dataRapat.name}</label>
                </Col>
              </Row>
              <Row justify='start'>
                <Col md={8} xs={8}>
                  <label className='label-bold'>{modalCon.content}</label>
                </Col>
                <Col md={2} xs={2}>
                  :
                </Col>
              </Row>
              <Row justify='start' style={{ marginTop: '10px' }}>
                <Col md={24} xs={24}>
                  <Table
                    loading={loadingT}
                    loadingIndicator={antIcon}
                    columns={
                      search?.kategori === 'Agenda'
                        ? columnsDetailAgenda
                        : columnsDetail
                    }
                    dataSource={modalCon.dataDetail}
                    scroll={{ x: 900, y: 1500 }}
                    size='small'
                  />
                  {/* {getHighlightedText(modalCon.data.detail, search)} */}
                  {/* <label id="content-detail">{modalCon.data.detail}</label> */}
                </Col>
              </Row>
            </div>
          </Modal>
        )}
      </div>
    </div>
  );
};

export default Pencarian;
