import React from 'react';

import { Tabs } from 'antd';

import 'bootstrap/dist/css/bootstrap.min.css';

import EditTim from './ComponentTim/EditTim';
import DokumenSK from './ComponentTim/DokumenSK';
import { useContext } from 'react';
import { UserContext } from '../../context/UserContext';

const Tim = () => {
  const { TabPane } = Tabs;
  const [user] = useContext(UserContext);

  function callback(key) {}
  return (
    <div className='container-content'>
      {/* {user.role_kantor === 'admin' || user.role_unit === 'kepala_tu' ? (
        <div className='container-detail'>
          <h1 className='table-title'>DOKUMEN SK</h1>
          <DokumenSK />
        </div>
      ) : ( */}
      <div className='container-detail' style={{ paddingTop: '5px' }}>
        <Tabs defaultActiveKey='1' onChange={callback}>
          <TabPane tab='Tim' key='1'>
            <h1 className='table-title'>UBAH DATA TIM</h1>
            <EditTim />
          </TabPane>
          <TabPane tab='Dokumen SK' key='2'>
            <h1 className='table-title'>DOKUMEN SK</h1>
            <DokumenSK />
          </TabPane>
        </Tabs>
      </div>
      {/* )} */}
    </div>
  );
};

export default Tim;
