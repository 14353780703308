import React, { useContext } from 'react';
import myAxios from '../../../myAxios';
import {
  Table,
  Space,
  Spin,
  Row,
  Col,
  message,
  Modal,
  Tooltip,
  Popconfirm,
  Form,
  Select,
  DatePicker,
} from 'antd';
import { Input, Button } from 'semantic-ui-react';
import { LoadingOutlined } from '@ant-design/icons';

import { useState, useEffect } from 'react';
import { UserContext } from '../../../context/UserContext';
import { useHistory, useParams } from 'react-router-dom';
import moment from 'moment';

const { RangePicker } = DatePicker;
const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
const tableLoading = {
  indicator: <Spin className='loading-data-table' indicator={antIcon} />,
};
const { Option } = Select;
const ShowTim = () => {
  const [user, setUser] = useContext(UserContext);
  const [tim, setTim] = useState({
    data: '',
  });
  const [idPengurus, setPengurus] = useState({
    chairman_id: '',
    secretary_id: '',
  });
  const [anggota, setAnggota] = useState(null);
  const [tempData, setData] = useState(false);
  const [searchText, setSearch] = useState(null);
  const [loadingTable, setLoadingTable] = useState(false);
  const [tipeTim, setTipeTim] = useState('Reguler');
  const [form] = Form.useForm();
  const [formAnggota] = Form.useForm();

  let history = useHistory();
  const { idTim } = useParams();

  useEffect(() => {
    getTim();
  }, []);

  const getTim = () => {
    setLoadingTable(tableLoading);
    myAxios
      .get(`team/${idTim}`, {
        headers: {
          Authorization: 'Bearer ' + user.token,
        },
      })
      .then((res) => {
        const data = res.data.data;
        if (data !== null) {
          setTim((prev) => {
            return {
              ...prev,
              data: data,
            };
          });
          // setTim(data);
          setData(data.member);
          setAnggota(data.member);
          const durasi = [
            moment(new Date(data.data.start_date)),
            moment(new Date(data.data.end_date)),
          ];
          const tipe_tim = data.data.type === 'Ad_hoc' ? 'Ad-Hoc' : 'Reguler';
          const sekretaris =
            data.sekretaris !== null ? data.sekretaris.name : null;
          form.setFieldsValue({
            nama_tim: data.data.name,
            chairman_id: data.ketua.name,
            secretary_id: sekretaris,
            tipe_tim,
            durasi,
          });
          setTipeTim(tipe_tim);
        } else {
          setData([]);
          setTim([]);
        }
        setLoadingTable(false);
      })
      .catch((err) => {
        if (err.response.data.message === 'unauthorized') {
          message.error('Sesi anda telah habis!');
          localStorage.removeItem('user');
        } else if (err.response.data.msg === 'Data Tim Tidak Ditemukan') {
          message.error(err.response.data.msg);
          history.goBack();
        } else if (err.response.data.msg === 'Unauthorization Role') {
          message.error(err.response.data.msg);
          history.goBack();
        } else {
          message.error(err.response.data.msg);
        }
        setTim([]);
        setLoadingTable(false);
      });
  };

  const clearFilters = () => {
    setAnggota(tempData);
    setSearch('');
  };

  const onChangeSearch = (evt) => {
    setSearch(evt.target.value);
    if (tempData) {
      if (evt.target.value === '') {
        setAnggota(tempData);
      } else {
        let filter = tempData.filter((i) => {
          return (
            i.name.toLowerCase().includes(evt.target.value.toLowerCase()) ||
            // i.email.includes(evt.target.value.toLowerCase()) ||
            i.username.toLowerCase().includes(evt.target.value.toLowerCase())
          );
        });

        setAnggota(filter);
      }
    }
  };

  const onFinish = (values) => {};

  const onFinishFailed = (errorInfo) => {};

  const columns = [
    {
      title: 'Nama',
      dataIndex: 'name',
      key: 'name',
      sorter: (a, b) => a.name.length - b.name.length,
      ellipsis: true,
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      sorter: (a, b) => a.email.length - b.email.length,
      ellipsis: true,
    },
    {
      title: 'NPP',
      dataIndex: 'username',
      key: 'username',
      sorter: (a, b) =>
        a.username ? a.username.length - b.username.length : null,
      ellipsis: true,
    },
    {
      title: 'Jabatan',
      dataIndex: 'id',
      key: 'id',
      sorter: (a, b) => a.id - b.id,
      ellipsis: true,
      render: (text, row) => (
        <>
          {row.id == idPengurus.chairman_id
            ? 'Ketua'
            : row.id == idPengurus.secretary_id
            ? 'Sekretaris'
            : 'Anggota'}
        </>
      ),
    },
  ];

  return (
    <div className='container-content'>
      <div className='container-detail'>
        <h1 className='table-title'>DETAIL TIM</h1>
        {loadingTable && !tim && (
          <h1 className='loading-data'>
            <Spin indicator={antIcon} />
            <p>Mengambil data..</p>
          </h1>
        )}

        {tim && (
          <Row justify='space-between'>
            <Col md={9} sm={24} className='width-100'>
              <Form
                layout='vertical'
                form={form}
                name='basic'
                initialValues={{ remember: false }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}>
                <Form.Item
                  labelAlign='left'
                  label='Nama Tim'
                  name='nama_tim'
                  rules={[
                    {
                      required: true,
                      message: 'Bagian ini wajib diisi',
                    },
                  ]}>
                  <Input disabled fluid size='small' />
                </Form.Item>

                {tim && (
                  <>
                    <Form.Item
                      labelAlign='left'
                      label='Nama Ketua'
                      name='chairman_id'
                      rules={[
                        { required: true, message: 'Bagian ini wajib diisi' },
                      ]}>
                      <Input disabled fluid size='small' />
                    </Form.Item>

                    <Form.Item
                      labelAlign='left'
                      label='Nama Sekretaris'
                      name='secretary_id'
                      rules={[
                        { required: true, message: 'Bagian ini wajib diisi' },
                      ]}>
                      <Input disabled fluid size='small' />
                    </Form.Item>

                    <Form.Item
                      labelAlign='left'
                      label='Tipe Tim'
                      name='tipe_tim'
                      rules={[
                        {
                          required: true,
                          message: 'Bagian ini wajib diisi',
                        },
                      ]}>
                      <Input disabled fluid size='small' />
                    </Form.Item>

                    {tipeTim === 'Ad-Hoc' && (
                      <Form.Item
                        labelAlign='left'
                        label='Durasi kerja'
                        name='durasi'
                        rules={[
                          {
                            required: true,
                            message: 'Bagian ini wajib diisi',
                          },
                        ]}>
                        <RangePicker
                          disabled
                          placeholder={['Tanggal Mulai', 'Tanggal Selesai']}
                          disabledDate={(current) => {
                            return current < moment().subtract(1, 'day');
                          }}
                        />
                      </Form.Item>
                    )}
                  </>
                )}
              </Form>
            </Col>

            <Col md={14} sm={24}>
              <Row justify='space-between' style={{ marginBottom: '20px' }}>
                <Col md={12} sm={24}>
                  <Space>
                    {/* <Button
                    secondary
                    className='btn-hapus-filter'
                    type='danger'
                    onClick={clearFilters}>
                    Hapus Filter
                  </Button> */}
                  </Space>
                </Col>
                <Col
                  md={12}
                  sm={24}
                  style={{ width: '100%' }}
                  className='mt-mobile'>
                  <Input
                    value={searchText}
                    style={{ width: '100%' }}
                    placeholder='Cari data anggota disini'
                    onChange={onChangeSearch}
                  />
                </Col>
              </Row>

              <Table
                loading={loadingTable}
                loadingIndicator={antIcon}
                columns={columns}
                dataSource={anggota}
                scroll={{ x: 750, y: 1500 }}
                size='small'
              />
            </Col>
          </Row>
        )}
      </div>
    </div>
  );
};

export default ShowTim;
