import React, { useContext } from 'react';
import myAxios from '../../../myAxios';
import {
  Table,
  Space,
  Spin,
  Row,
  Col,
  message,
  Tooltip,
  Popconfirm,
  Form,
  Modal,
  Tag,
  Pagination,
  Dropdown,
  Select,
  Input,
  Menu,
} from 'antd';
import { Button, Icon } from 'semantic-ui-react';
import { LoadingOutlined } from '@ant-design/icons';

import { useState, useEffect } from 'react';
import { UserContext } from '../../../context/UserContext';
import { TamuContext } from '../../../context/TamuContext';
import { useHistory } from 'react-router-dom';

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
const tableLoading = {
  indicator: <Spin className='loading-data-table' indicator={antIcon} />,
};
const { Option } = Select;
const TamuSaran = () => {
  const [user, setUser] = useContext(UserContext);
  // const [tamu, updateTamu] = useContext(TamuContext);
  const [saranTamu, setSaranTamu] = useState(null);
  const [modalCon, setModalCon] = useState(null);
  const [searchText, setSearch] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loadingTambah, setLoadingTambah] = useState(false);
  const [loadingT, setLoadingT] = useState(false);
  const [loadingAct, setLoadingAct] = useState(false);
  const [modalVisible, setModal] = useState(false);
  const [pagination, setPagination] = useState(null);
  const [instance, setInstance] = useState(null);
  const [currInstance, setCurrInstance] = useState(null);
  const [idAdmin, setIdAdmin] = useState(null);
  const [page, setPage] = useState('10');
  let { history } = useHistory;
  const [form] = Form.useForm();

  useEffect(() => {
    getInstance();
    if (currInstance) {
      setLoadingT(tableLoading);
      const timeOutId = setTimeout(
        () => getTamu(searchText, null, null, null, null, page),
        500
      );
      return () => clearTimeout(timeOutId);
    }
  }, [searchText, currInstance]);

  const getTamu = (
    search = undefined,
    page = '1',
    sortBy = undefined,
    sorting = 'ASC',
    type = '1',
    show = '10'
  ) => {
    myAxios
      .get(`guests/${currInstance.id}/${currInstance.type}`, {
        params: {
          page: Number(page),
          search: search,
          show: '10',
          sortBy: sortBy,
          sorting: sorting,
          type: type,
          show: show,
        },
        headers: {
          Authorization: 'Bearer ' + user.token,
        },
      })
      .then((res) => {
        const data = res.data.data.data;
        setSaranTamu(data);
        setPagination({
          total: res.data.data.total,
          current: res.data.data.current_page,
          perPage: res.data.data.per_page,
        });
        setLoadingT(false);
      })
      .catch((err) => {
        if (err.response.data.message === 'unauthorized') {
          message.error('Sesi anda telah habis!');
          localStorage.removeItem('user');
          setUser(null);
        } else if (err.response.data.msg === 'Unauthorization Role') {
          message.error(err.response.data.msg);
          history.goBack();
        } else {
          message.error(err.response.data.msg);
        }
        setLoadingT(false);
      });
  };

  const getInstance = async () => {
    await myAxios
      .get(`guests-instance`, {
        headers: {
          Authorization: 'Bearer ' + user.token,
        },
      })
      .then((res) => {
        const data = res.data.data;
        if (data.length > 0) {
          if (!currInstance) {
            setCurrInstance(data[0]);
            setIdAdmin(data[0].admin_id);
          }
          setInstance(data);
        } else {
          setInstance([]);
        }

        // setLoadingAdmin(false);
      })
      .catch((err) => {
        if (err.response.data.message === 'unauthorized') {
          message.error('Sesi anda telah habis!');
          localStorage.removeItem('user');
        } else if (err.response.data.msg === 'Unauthorization Role') {
          message.error(err.response.data.msg);
          history.goBack();
        } else {
          message.error(err.response.data.msg);
        }
        // setLoadingAdmin(false);
      });
  };

  const clearFilters = () => {
    setSearch('');
  };

  const onChange = () => {};
  const onFocus = () => {};
  const onBlur = () => {};
  const onSearch = () => {};

  const onChangeSearch = (evt) => {
    setSearch(evt.target.value);
  };

  const menu = (
    <Menu>
      {instance &&
        instance.map((val, item) => (
          <Menu.Item>
            <a
              target='_blank'
              rel='noopener noreferrer'
              onClick={() => onChangeFilter(val)}>
              {val.name}
            </a>
          </Menu.Item>
        ))}
    </Menu>
  );

  const onChangeFilter = (val) => {
    setCurrInstance(val);
    setIdAdmin(val.admin_id);
  };

  const onAccept = (param) => {
    setLoadingAct(true);
    myAxios
      .put(`accept-guest/${param}`, null, {
        headers: {
          Authorization: 'Bearer ' + user.token,
        },
      })
      .then((res) => {
        getTamu();
        // updateTamu();
        message.success(res.data.msg);
        setLoadingAct(false);
      })
      .catch((err) => {
        if (err.response.data.message === 'unauthorized') {
          message.error('Sesi anda telah habis!');
          localStorage.removeItem('user');
          setUser(null);
        } else if (err.response.data.msg === 'Unauthorization Role') {
          message.error(err.response.data.msg);
          history.goBack();
        } else {
          message.error(err.response.data.msg);
        }
        setLoadingAct(false);
      });
  };

  const onDecline = (param) => {
    setLoadingAct(true);
    myAxios
      .put(`decline-guest/${param}`, null, {
        headers: {
          Authorization: 'Bearer ' + user.token,
        },
      })
      .then((res) => {
        getTamu();
        message.success('Berhasil menghapus tamu');
        setLoadingAct(false);
      })
      .catch((err) => {
        if (err.response.data.message === 'unauthorized') {
          message.error('Sesi anda telah habis!');
          localStorage.removeItem('user');
          setUser(null);
        } else if (err.response.data.msg === 'Unauthorization Role') {
          message.error(err.response.data.msg);
          history.goBack();
        } else {
          message.error(err.response.data.msg);
        }
        setLoadingAct(false);
      });
  };

  const onFinish = (values) => {
    setLoading(true);
    if (modalCon.judul === 'Tambah Tamu') {
      if (values.nip === undefined || values.nip === '') values.nip = '-';
      if (values.institusi === undefined || values.institusi === '')
        values.institusi = '-';

      let newObj = {
        name: values.nama,
        email: values.email,
        nip: values.nip,
        type_name: values.instance,
        type_id: instance.filter((e) => {
          return e.type === values.instance;
        })[0].id,
        institusi: values.institusi,
      };
      myAxios
        .post(`guest`, newObj, {
          headers: {
            Authorization: 'Bearer ' + user.token,
          },
        })
        .then((res) => {
          message.success(res.data.msg);
          setLoading(false);
          setModal(false);
          form.resetFields();
          getTamu();
        })
        .catch((err) => {
          if (err.response.data.message === 'unauthorized') {
            message.error('Sesi anda telah habis!');
            localStorage.removeItem('user');
            setUser(null);
          } else if (err.response.data.msg === 'Unauthorization Role') {
            message.error(err.response.data.msg);
            history.goBack();
          } else {
            message.error(err.response.data.msg);
          }
          setLoading(false);
        });
    } else {
      if (values.nip === undefined || values.nip === '') values.nip = '-';

      let newObj = {
        name: values.nama,
        email: values.email,
        nip: values.nip,
        institusi: values.institusi,
      };

      myAxios
        .put(`guest/${modalCon.id}`, newObj, {
          headers: {
            Authorization: 'Bearer ' + user.token,
          },
        })
        .then((res) => {
          message.success(res.data.msg);
          setLoading(false);
          setModal(false);
          form.resetFields();
          getTamu();
        })
        .catch((err) => {
          if (err.response.data.message === 'unauthorized') {
            message.error('Sesi anda telah habis!');
            localStorage.removeItem('user');
            setUser(null);
          } else if (err.response.data.msg === 'Unauthorization Role') {
            message.error(err.response.data.msg);
            history.goBack();
          } else {
            message.error(err.response.data.msg);
          }
          setLoading(false);
        });
    }
  };

  const onEditModal = (param) => {
    setModal(true);
    setModalCon({
      judul: 'Ubah Tamu',
      id: param.id,
    });

    form.setFieldsValue({
      nama: param.name,
      email: param.email,
      nip: param.nip,
      institusi: param.institution,
    });
  };

  const onUbahStatusTamu = (param) => {
    setLoadingAct(true);
    myAxios
      .delete(`guest/${param}`, {
        headers: {
          Authorization: 'Bearer ' + user.token,
        },
      })
      .then((res) => {
        message.success(res.data.msg);
        setLoadingAct(false);
        setModal(false);
        form.resetFields();
        getTamu();
      })
      .catch((err) => {
        if (err.response.data.message === 'unauthorized') {
          message.error('Sesi anda telah habis!');
          localStorage.removeItem('user');
          setUser(null);
        } else if (err.response.data.msg === 'Unauthorization Role') {
          message.error(err.response.data.msg);
          history.goBack();
        } else {
          message.error(err.response.data.msg);
        }
        setLoadingAct(false);
      });
  };

  const onChangePagination = (evt, show) => {
    setPage(show);
    getTamu('', evt, null, null, null, show);
  };

  const columns = [
    {
      title: 'Nama',
      dataIndex: 'name',
      key: 'name',
      sorter: (a, b) => a.name.length - b.name.length,
      ellipsis: true,
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      // sorter: (a, b) => a.email.length - b.email.length,
      ellipsis: true,
    },
    {
      title: 'Institusi',
      dataIndex: 'institution',
      key: 'institution',
      sorter: (a, b) => a.institution.length - b.institution.length,
      ellipsis: true,
    },
    {
      title: 'Nomor Induk Pegawai',
      dataIndex: 'nip',
      key: 'nip',
      sorter: (a, b) => a.nip.length - b.nip.length,
      ellipsis: true,
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      sorter: (a, b) => a.status.length - b.status.length,
      render: (dataIndex) => (
        <Tag color={dataIndex === 'Terverifikasi' ? 'green' : 'red'}>
          {dataIndex}
        </Tag>
      ),
    },

    {
      key: 'action',
      dataIndex: 'id',
      align: 'center',
      render: (dataIndex, row) => (
        <div>
          <>
            {!loadingAct && idAdmin && (
              <>
                {/* {user.id === idAdmin && ( */}
                <Space size='middle'>
                  <Tooltip
                    placement='bottom'
                    title='Ubah Data Tamu'
                    color='#1f1f1f'
                    key='white'>
                    <Button
                      className='aksiEdit'
                      color='facebook'
                      disabled={
                        (row.created_by === user.npp &&
                          row.status === 'Belum Terverifikasi') ||
                        user.id === currInstance.chairman_id ||
                        user.id === currInstance.admin_id
                          ? false
                          : true
                      }
                      size='mini'
                      icon='pencil alternate'
                      onClick={() => onEditModal(row)}
                    />
                  </Tooltip>
                  <Tooltip
                    placement='bottom'
                    title='Hapus Tamu'
                    color='#1f1f1f'
                    key='white'>
                    <Popconfirm
                      placement='bottomRight'
                      title='Apakah yakin ingin menghapus tamu ?'
                      onConfirm={() => onDecline(dataIndex)}
                      okText='Hapus'
                      cancelText='Batal'>
                      <Button
                        className='aksiHapus'
                        disabled={
                          (row.created_by === user.npp &&
                            row.status === 'Belum Terverifikasi') ||
                          user.id === currInstance.chairman_id ||
                          user.id === currInstance.admin_id
                            ? false
                            : true
                        }
                        negative
                        icon='trash alternate'
                        size='mini'></Button>
                    </Popconfirm>
                  </Tooltip>
                </Space>
                {/* )} */}
              </>
            )}
            {loadingAct && (
              <Spin className='loading-data-table' indicator={antIcon} />
            )}
          </>
        </div>
      ),
    },
  ];

  const columnsAnggota = [
    {
      title: 'Nama',
      dataIndex: 'name',
      key: 'name',
      sorter: (a, b) => a.name.length - b.name.length,
      ellipsis: true,
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      sorter: (a, b) => a.email.length - b.email.length,
      ellipsis: true,
    },
    {
      title: 'Nomor Induk Pegawai',
      dataIndex: 'nip',
      key: 'nip',
      sorter: (a, b) => a.nip.length - b.nip.length,
      ellipsis: true,
    },

    {
      title: 'Status',
      dataIndex: 'role',
      key: 'role',
      sorter: (a, b) => a.role.length - b.role.length,
      render: (dataIndex) =>
        dataIndex === 'Tamu' ? (
          <Tag color='green'>Terverifikasi</Tag>
        ) : (
          <Tag color='red'>Belum Terverifikasi</Tag>
        ),
    },

    {
      key: 'action',
      dataIndex: 'id',
      align: 'center',
      render: (dataIndex, row) => (
        <div>
          {user.role === 'Admin' && (
            <>
              {!loadingAct && (
                <Space size='middle'>
                  <Tooltip
                    placement='bottom'
                    title='Ubah Data Tamu'
                    color='#1f1f1f'
                    key='white'>
                    <Button
                      className='aksiEdit'
                      color='facebook'
                      size='mini'
                      icon='pencil alternate'
                      onClick={() => onEditModal(row)}></Button>
                  </Tooltip>
                </Space>
              )}
              {loadingAct && (
                <Spin className='loading-data-table' indicator={antIcon} />
              )}
            </>
          )}
        </div>
      ),
    },
  ];

  const onTambahModal = async (evt) => {
    setLoadingTambah(true);
    // await getInstance();
    setLoadingTambah(false);
    setModal(true);
    setModalCon({
      judul: 'Tambah Tamu',
      id: null,
    });

    form.setFieldsValue({
      instance: currInstance.type,
    });
  };

  const handleCancel = () => {
    setModal(false);
    form.resetFields();
  };

  const onFinishFailed = (errorInfo) => {};

  const handleReset = () => {
    if (modalCon.id === null) form.resetFields();
    else {
      let filter = saranTamu.filter((e) => {
        return e.id === modalCon.id;
      });
      form.setFieldsValue({
        nama: filter[0].name,
        email: filter[0].email,
        nip: filter[0].nip,
      });
    }
  };

  const onChangeTable = (pagination, filters, sorter, extra) => {
    const order = sorter.order === 'descend' ? 'DESC' : 'ASC';
    const field = sorter.order === undefined ? undefined : sorter.field;

    getTamu('', '1', field, order);
  };

  return (
    <div className=''>
      <Row justify='space-between' style={{ marginBottom: '20px' }}>
        <Col md={12} sm={24}>
          <Space>
            {user.role !== 'Kepala Departemen' && (
              <>
                <Button
                  loading={loadingTambah}
                  className='btn-hapus-filter'
                  onClick={onTambahModal}
                  icon
                  labelPosition='left'
                  color='green'>
                  <Icon name='user plus' />
                  Tambah Tamu
                </Button>
                {/* <Button
                  className='btn-hapus-filter'
                  secondary
                  onClick={clearFilters}>
                  Hapus Filter
                </Button> */}
                {instance && (
                  <Dropdown
                    overlay={menu}
                    placement='bottomLeft'
                    trigger={['click']}>
                    <Button
                      secondary
                      className='btn-hapus-filter'
                      icon='angle down'
                      labelPosition='right'
                      content={
                        !currInstance
                          ? 'Filter Instansi'
                          : instance[
                              instance.findIndex(
                                (x) => x.type === currInstance.type
                              )
                            ].name
                      }
                    />
                  </Dropdown>
                )}
              </>
            )}
            {/* <Button
              secondary
              className='btn-hapus-filter'
              type='danger'
              onClick={clearFilters}>
              Hapus Filter
            </Button> */}
          </Space>
        </Col>
        <Col md={12} sm={24} style={{ width: '100%' }} className='mt-mobile'>
          <Input
            value={searchText}
            style={{ width: '100%' }}
            placeholder='Cari data tamu disini'
            onChange={onChangeSearch}
          />
        </Col>
      </Row>

      <Table
        onChange={onChangeTable}
        pagination={false}
        loading={loadingT}
        loadingIndicator={antIcon}
        columns={columns}
        dataSource={saranTamu}
        scroll={{ x: 600, y: 1500 }}
        size='small'
      />
      {pagination && (
        <div className='pagination'>
          <Pagination
            total={pagination.total}
            showTotal={(total, range) =>
              `Menampilkan ${range[0]}-${range[1]} dari ${total} data`
            }
            defaultPageSize={Number(pagination.perPage)}
            current={pagination.current}
            onChange={onChangePagination}
          />
        </div>
      )}

      {modalCon && (
        <Modal
          centered
          visible={modalVisible}
          onCancel={handleCancel}
          footer={[]}
          title={modalCon.judul}>
          <Form
            layout='vertical'
            form={form}
            name='basic'
            initialValues={{ remember: false }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}>
            <Form.Item
              labelAlign='left'
              label='Nama'
              name='nama'
              rules={[
                {
                  required: true,
                  message: 'Bagian ini wajib diisi',
                },
              ]}>
              <Input />
            </Form.Item>

            <Form.Item
              labelAlign='left'
              label='Email'
              name='email'
              rules={[
                {
                  type: 'email',
                  message: 'Bagian ini wajib diisi!',
                  required: true,
                },
              ]}>
              <Input />
            </Form.Item>

            <Form.Item labelAlign='left' label='Nomor Induk Pegawai' name='nip'>
              <Input />
            </Form.Item>

            <Form.Item
              labelAlign='left'
              label='Institusi Asal'
              name='institusi'>
              <Input placeholder='Masukan institusi asal' />
            </Form.Item>

            {instance && !modalCon.id && (
              <Form.Item
                labelAlign='left'
                label='Kantor/Unit/SubUnit'
                name='instance'
                rules={[
                  {
                    required: true,
                    message: 'Bagian ini wajib diisi',
                  },
                ]}>
                <Select
                  style={{ width: '100%' }}
                  autoComplete='off'
                  showSearch
                  placeholder='Pilih Kantor/Unit/SubUnit'
                  optionFilterProp='label'
                  onChange={onChange}
                  onFocus={onFocus}
                  onBlur={onBlur}
                  onSearch={onSearch}
                  // filterOption={(input, option) =>
                  //   option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  //   0
                  // }
                >
                  {instance.map((val, item) => (
                    <Option key={val.type} value={val.type} label={val.name}>
                      {val.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            )}

            <Form.Item>
              <Button
                floated='right'
                type='submit'
                loading={loading}
                color='green'>
                Simpan
              </Button>

              <Button
                type='button'
                floated='right'
                onClick={handleReset}
                loading={loading}
                negative>
                Reset
              </Button>
            </Form.Item>
          </Form>
        </Modal>
      )}
    </div>
  );
};

export default TamuSaran;
