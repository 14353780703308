import React, { useContext, useMemo } from 'react';
import myAxios from '../../../myAxios';
import {
  Table,
  Space,
  Spin,
  Row,
  Col,
  message,
  Tooltip,
  Form,
  Modal,
  Tag,
  Input,
  Pagination,
} from 'antd';
import { Button } from 'semantic-ui-react';
import { LoadingOutlined } from '@ant-design/icons';

import { useState, useEffect } from 'react';
import { UserContext } from '../../../context/UserContext';
import { PenugasanContext } from '../../../context/PenugasanContext';
import { useHistory } from 'react-router-dom';

const { TextArea } = Input;
const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
const tableLoading = {
  indicator: <Spin className='loading-data-table' indicator={antIcon} />,
};

const Penugasan = () => {
  const [user, setUser] = useContext(UserContext);
  const [penugasan, updatePenugasan] = useContext(PenugasanContext);
  const [tugas, setTugas] = useState(null);
  const [modalCon, setModalCon] = useState(null);
  const [tempData, setData] = useState(null);
  const [searchText, setSearch] = useState(null);
  const [loading, setLoading] = useState(false);

  const [modalVisible, setModal] = useState(false);
  const [pagination, setPagination] = useState(null);
  const [page, setPage] = useState('10');
  const [form] = Form.useForm();
  let { history } = useHistory;

  useEffect(() => {
    setLoading(tableLoading);
    const timeOutId = setTimeout(
      () => getPenugasan(searchText, null, null, null, page),
      500
    );
    return () => clearTimeout(timeOutId);
  }, [searchText]);

  const getPenugasan = (
    search = undefined,
    page = '1',
    sortBy = undefined,
    sorting = 'ASC',
    show = '10'
  ) => {
    myAxios
      .get(`conclusion-member/${user.id}`, {
        params: {
          page: Number(page),
          search: search,
          show: show,
          sortBy: sortBy,
          sorting: sorting,
        },
        headers: {
          Authorization: 'Bearer ' + user.token,
        },
      })
      .then((res) => {
        const data = res.data.data.data;

        setLoading(false);
        setData(data);
        setTugas(data);
        setPagination({
          total: res.data.data.total,
          current: res.data.data.current_page,
          perPage: res.data.data.per_page,
        });
      })
      .catch((err) => {
        if (err.response.data.message === 'unauthorized') {
          message.error('Sesi anda telah habis!');
          localStorage.removeItem('user');
          setUser(null);
        } else if (err.response.data.msg === 'Unauthorization Role') {
          message.error(err.response.data.msg);
          history.goBack();
        } else {
          message.error(err.response.data.message);
        }
        setTugas([]);
        setLoading(false);
      });
  };

  const clearFilters = () => {
    setTugas(tempData);
    setSearch('');
  };

  const onChangeSearch = (evt) => {
    setSearch(evt.target.value);
    if (tempData) {
      if (evt.target.value === '') {
        setTugas(tempData);
      } else {
        setTugas(
          tempData.filter((i) => {
            return (
              i.nama_rapat
                .toLowerCase()
                .includes(evt.target.value.toLowerCase()) ||
              i.kesimpulan
                .toLowerCase()
                .includes(evt.target.value.toLowerCase()) ||
              i.hasil_kesimpulan
                .toLowerCase()
                .includes(evt.target.value.toLowerCase()) ||
              i.status_kesimpulan
                .toLowerCase()
                .includes(evt.target.value.toLowerCase())
            );
          })
        );
      }
    }
  };

  const handleCancel = () => {
    setModal(false);
    form.resetFields();
  };

  const handleReset = () => {
    if (modalCon.id === null) form.resetFields();
    else {
      let filter = tempData.filter((e) => {
        return e.id === modalCon.id;
      });
      form.setFieldsValue({
        nama: filter[0].name,
        email: filter[0].email,
        npp: filter[0].npp,
      });
    }
  };

  const onFinish = (values) => {
    setLoading(true);

    let newObj = {
      user_id: user.id,
      result: values.hasil_penugasan,
    };

    myAxios
      .put(`submit-task/${modalCon.id}`, newObj, {
        headers: {
          Authorization: 'Bearer ' + user.token,
        },
      })
      .then((res) => {
        message.success(res.data.msg);
        form.resetFields();
        updatePenugasan();
        getPenugasan();
        setLoading(false);
        setModal(false);
      })
      .catch((err) => {
        if (err.response.data.message === 'unauthorized') {
          message.error('Sesi anda telah habis!');
          localStorage.removeItem('user');
          setUser(null);
        } else if (err.response.data.msg === 'Unauthorization Role') {
          message.error(err.response.data.msg);
          history.goBack();
        } else {
          message.error(err.response.data.msg);
        }
        setLoading(false);
      });
  };

  const onFinishFailed = (errorInfo) => {};

  const onEditModal = (row) => {
    setModal(true);
    setModalCon({
      judul: 'Hasil Penugasan',
      id: row.id,
    });

    if (row.hasil_kesimpulan === '-') row.hasil_kesimpulan = null;
    form.setFieldsValue({
      nama_rapat: row.name,
      kesimpulan: row.conclusion,
      hasil_penugasan: row.result,
    });
  };

  const onChangePagination = (evt, show) => {
    setPage(show);
    getPenugasan('', evt, null, null, show);
  };

  const onChangeTable = (pagination, filters, sorter, extra) => {
    const order = sorter.order === 'descend' ? 'DESC' : 'ASC';
    const field = sorter.order === undefined ? undefined : sorter.field;

    getPenugasan('', '1', field, order);
  };

  const columns = [
    {
      title: 'Nama Tim',
      dataIndex: 'team',
      key: 'team',
      sorter: (a, b) => a.team.length - b.team.length,
      ellipsis: true,
    },
    {
      title: 'Nama Rapat',
      dataIndex: 'name',
      key: 'name',
      sorter: (a, b) => a.name.length - b.name.length,
      ellipsis: true,
    },
    {
      title: 'Kesimpulan',
      dataIndex: 'conclusion',
      key: 'conclusion',
      sorter: (a, b) => a.conclusion.length - b.conclusion.length,
      ellipsis: true,
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      sorter: (a, b) => a.status.length - b.status.length,
      ellipsis: true,
      render: (text) =>
        text === 'Menunggu Verifikasi' ? (
          <Tag color='orange'>{text}</Tag>
        ) : text === 'Selesai' ? (
          <Tag color='green'>{text}</Tag>
        ) : (
          <Tag color='red'>{text}</Tag>
        ),
    },
    {
      title: 'Hasil',
      dataIndex: 'result',
      key: 'result',
      sorter: (a, b) => a.result.length - b.result.length,
      ellipsis: true,
    },
    {
      title: 'Feedback',
      dataIndex: 'feedback',
      key: 'feedback',
      sorter: (a, b) => a.feedback.length - b.feedback.length,
      ellipsis: true,
    },
    {
      key: 'action',
      dataIndex: 'id',
      align: 'center',
      render: (dataIndex, row) => (
        <div>
          {row.status !== 'Selesai' && (
            <Space size='middle'>
              <Tooltip
                placement='bottom'
                title='Ubah Penugasan'
                color='#1f1f1f'
                key='white'>
                <Button
                  disabled={row.status_kesimpulan === 'Selesai' ? true : false}
                  className='aksiEdit'
                  color='facebook'
                  size='mini'
                  icon='pencil alternate'
                  onClick={() => onEditModal(row)}></Button>
              </Tooltip>
            </Space>
          )}
        </div>
      ),
    },
  ];

  return (
    <div className='container-content tugas'>
      <div className='container-table'>
        <h1 className='table-title'>PENUGASAN</h1>
        <Row justify='space-between' style={{ marginBottom: '20px' }}>
          <Col md={12} sm={24}>
            <Space>
              <Button
                secondary
                className='btn-hapus-filter'
                type='danger'
                onClick={clearFilters}>
                Hapus Filter
              </Button>
            </Space>
          </Col>
          <Col md={12} sm={24} style={{ width: '100%' }} className='mt-mobile'>
            <Input
              value={searchText}
              style={{ width: '100%' }}
              placeholder='Cari data penugasan disini'
              onChange={onChangeSearch}
            />
          </Col>
        </Row>
        <Table
          onChange={onChangeTable}
          pagination={false}
          loading={loading}
          loadingIndicator={antIcon}
          columns={columns}
          dataSource={tugas}
          scroll={{ x: 900, y: 1500 }}
          size='small'
        />
        {pagination && (
          <div className='pagination'>
            <Pagination
              total={pagination.total}
              showTotal={(total, range) =>
                `Menampilkan ${range[0]}-${range[1]} dari ${total} data`
              }
              defaultPageSize={Number(pagination.perPage)}
              current={pagination.current}
              onChange={onChangePagination}
            />
          </div>
        )}
        {modalCon && (
          <Modal
            centered
            visible={modalVisible}
            onCancel={handleCancel}
            footer={[]}
            title={modalCon.judul}>
            <Form
              layout='vertical'
              form={form}
              name='basic'
              initialValues={{ remember: false }}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}>
              <Form.Item
                labelAlign='left'
                label='Nama Rapat'
                name='nama_rapat'
                rules={[
                  {
                    required: true,
                    message: 'Bagian ini wajib diisi',
                  },
                ]}>
                <Input disabled />
              </Form.Item>
              <Form.Item
                labelAlign='left'
                label='Kesimpulan'
                name='kesimpulan'
                rules={[
                  {
                    required: true,
                    message: 'Bagian ini wajib diisi',
                  },
                ]}>
                <Input disabled />
              </Form.Item>
              <Form.Item
                labelAlign='left'
                label='Hasil Penugasan'
                name='hasil_penugasan'
                rules={[
                  {
                    required: true,
                    message: 'Bagian ini wajib diisi',
                  },
                ]}>
                <TextArea placeholder='Masukan hasil penugasan' rows={5} />
              </Form.Item>

              <Form.Item>
                <Button
                  floated='right'
                  type='submit'
                  loading={loading}
                  color='green'>
                  Simpan
                </Button>

                <Button
                  type='button'
                  floated='right'
                  onClick={handleReset}
                  loading={loading}
                  negative>
                  Reset
                </Button>
              </Form.Item>
            </Form>
          </Modal>
        )}
      </div>
    </div>
  );
};
export default Penugasan;
