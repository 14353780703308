import React, { useContext } from 'react';
import myAxios from '../../../myAxios';
import {
  Table,
  Space,
  Spin,
  Row,
  Col,
  message,
  Modal,
  Tooltip,
  Popconfirm,
  Form,
  Select,
  DatePicker,
} from 'antd';
import { Input, Button } from 'semantic-ui-react';
import { LoadingOutlined } from '@ant-design/icons';

import { useState, useEffect } from 'react';
import { UserContext } from '../../../context/UserContext';
import { useHistory, useParams } from 'react-router-dom';
import moment from 'moment';

const { RangePicker } = DatePicker;
const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
const tableLoading = {
  indicator: <Spin className='loading-data-table' indicator={antIcon} />,
};
const { Option } = Select;
const EditTim = () => {
  const [user, setUser] = useContext(UserContext);
  const [tim, setTim] = useState({
    data: '',
    karyawanKetua: null,
    karyawanSekretaris: null,
    anggotaBaru: null,
  });
  const [idPengurus, setPengurus] = useState({
    chairman_id: '',
    secretary_id: '',
  });
  const [anggota, setAnggota] = useState(null);
  const [modalCon, setModalCon] = useState(null);
  const [tempData, setData] = useState(false);
  const [searchText, setSearch] = useState(null);
  const [loadingTable, setLoadingTable] = useState(false);
  const [loadingReq, setLoadingReq] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingTambahAnggota, setLoadingTambahAnggota] = useState(false);
  const [loadingAct, setLoadingAct] = useState(false);
  const [modalVisible, setModal] = useState(false);
  const [tipeTim, setTipeTim] = useState('Reguler');
  const [form] = Form.useForm();
  const [formAnggota] = Form.useForm();

  let history = useHistory();
  const { idTim } = useParams();

  useEffect(() => {
    getAllData();
  }, []);

  const getAllData = async () => {
    setLoadingReq(tableLoading);
    await getTim();
    await getDDMember();
    await getDDKetua();
    await getDDSekretaris();
    setLoadingReq(false);
  };

  const getTim = () => {
    setLoadingTable(tableLoading);
    myAxios
      .get(`team/${idTim}`, {
        headers: {
          Authorization: 'Bearer ' + user.token,
        },
      })
      .then((res) => {
        const data = res.data.data;

        // if (data !== null) {
        setTim((prev) => {
          return {
            ...prev,
            data: data.data,
          };
        });
        setData(data.member);
        setAnggota(data.member);
        const durasi = [
          moment(new Date(data.data.start_date)),
          moment(new Date(data.data.end_date)),
        ];
        const tipe_tim = data.data.type === 'Ad_hoc' ? 'Ad-Hoc' : 'Reguler';

        form.setFieldsValue({
          nama_tim: data.data.name,
          chairman_id: data.data.chairman_id,
          secretary_id: data.data.secretary_id,
          tipe_tim,
          durasi,
        });
        setTipeTim(tipe_tim);

        setPengurus({
          chairman_id: data.data.chairman_id,
          secretary_id: data.data.secretary_id,
        });
        setLoadingTable(false);
        // } else {
        //   setData([]);
        //   setTim([]);
        // }
      })
      .catch((err) => {
        if (err.response.data.message === 'unauthorized') {
          message.error('Sesi anda telah habis!');
          localStorage.removeItem('user');
        } else if (err.response.data.msg === 'Data Tim Tidak Ditemukan') {
          message.error(err.response.data.msg);
          history.goBack();
        } else if (err.response.data.msg === 'Unauthorization Role') {
          message.error(err.response.data.msg);
          history.goBack();
        } else {
          message.error(err.response.data.msg);
        }
        setTim([]);
        setLoadingTable(false);
      });
  };

  const getDDMember = async () => {
    await myAxios
      .get(`team-member-dropdown/${idTim}`, {
        headers: {
          Authorization: 'Bearer ' + user.token,
        },
      })
      .then((res) => {
        const data = res.data.data;

        setTim((prev) => {
          return {
            ...prev,
            anggotaBaru: data.anggotaBaru,
            tempKaryawan: data.tempKaryawan,
          };
        });
      })
      .catch((err) => {
        if (err.response.data.message === 'unauthorized') {
          message.error('Sesi anda telah habis!');
          localStorage.removeItem('user');
        } else if (err.response.data.msg === 'Data Tim Tidak Ditemukan') {
          message.error(err.response.data.msg);
          history.goBack();
        } else if (err.response.data.msg === 'Unauthorization Role') {
          message.error(err.response.data.msg);
          history.goBack();
        } else {
          message.error(err.response.data.msg);
        }
        setTim([]);
        setLoadingTable(false);
      });
  };
  const getDDKetua = () => {
    myAxios
      .get(`team-chairman-dropdown/${idTim}`, {
        headers: {
          Authorization: 'Bearer ' + user.token,
        },
      })
      .then((res) => {
        const data = res.data.data.karyawanKetua;
        setTim((prev) => {
          return {
            ...prev,
            karyawanKetua: data,
          };
        });
      })
      .catch((err) => {
        if (err.response.data.message === 'unauthorized') {
          message.error('Sesi anda telah habis!');
          localStorage.removeItem('user');
        } else if (err.response.data.msg === 'Data Tim Tidak Ditemukan') {
          message.error(err.response.data.msg);
          history.goBack();
        } else if (err.response.data.msg === 'Unauthorization Role') {
          message.error(err.response.data.msg);
          history.goBack();
        } else {
          message.error(err.response.data.msg);
        }
        setTim([]);
        setLoadingTable(false);
      });
  };
  const getDDSekretaris = () => {
    myAxios
      .get(`team-secretary-dropdown/${idTim}`, {
        headers: {
          Authorization: 'Bearer ' + user.token,
        },
      })
      .then((res) => {
        const data = res.data.data.karyawanSekretaris;

        setTim((prev) => {
          return {
            ...prev,
            karyawanSekretaris: data,
          };
        });
      })
      .catch((err) => {
        if (err.response.data.message === 'unauthorized') {
          message.error('Sesi anda telah habis!');
          localStorage.removeItem('user');
        } else if (err.response.data.msg === 'Data Tim Tidak Ditemukan') {
          message.error(err.response.data.msg);
          history.goBack();
        } else if (err.response.data.msg === 'Unauthorization Role') {
          message.error(err.response.data.msg);
          history.goBack();
        } else {
          message.error(err.response.data.msg);
        }
        setTim([]);
        setLoadingTable(false);
      });
  };

  const onHapus = (param) => {
    setLoadingAct(true);

    myAxios
      .delete(`team/delete-member/${tim.data.id}/${param}`, {
        headers: {
          Authorization: 'Bearer ' + user.token,
        },
      })
      .then((res) => {
        getAllData();
        message.success(res.data.msg);
        setLoadingAct(false);
      })
      .catch((err) => {
        if (err.response.data.message === 'unauthorized') {
          message.error('Sesi anda telah habis!');
          localStorage.removeItem('user');
          setUser(null);
        } else if (err.response.data.msg === 'Unauthorization Role') {
          message.error(err.response.data.msg);
          history.goBack();
        } else {
          message.error(err.response.data.msg);
        }
        setLoadingAct(false);
      });
  };

  const clearFilters = () => {
    setAnggota(tempData);
    setSearch('');
  };

  const onChangeSearch = (evt) => {
    setSearch(evt.target.value);
    if (tempData) {
      if (evt.target.value === '') {
        setAnggota(tempData);
      } else {
        let filter = tempData.filter((i) => {
          return (
            i.name.toLowerCase().includes(evt.target.value.toLowerCase()) ||
            // i.email.toLowerCase().includes(evt.target.value.toLowerCase()) ||
            i.username.toLowerCase().includes(evt.target.value.toLowerCase())
          );
        });

        setAnggota(filter);
      }
    }
  };

  const handleCancel = () => {
    setModal(false);
    formAnggota.resetFields();
  };

  const onFinish = (values) => {
    setLoading(true);
    // var start_date = null;
    // var end_date = null;

    // if (values.durasi !== undefined) {
    //   start_date = moment(values.durasi[0]).format('l');
    //   end_date = moment(values.durasi[1]).format('l');
    // }

    if (
      values.secretary_id === '' ||
      values.secretary_id === null ||
      values.secretary_id === undefined
    ) {
      values.secretary_id = '-';
    }

    let newObj = {
      name: values.nama_tim,
      chairman_id: values.chairman_id,
      secretary_id: values.secretary_id,
      // start_date,
      // end_date,
    };

    myAxios
      .put(`team/${tim.data.id}`, newObj, {
        headers: {
          Authorization: 'Bearer ' + user.token,
        },
      })
      .then((res) => {
        message.success(res.data.msg);
        setLoading(false);
        setModal(false);
        getTim();
        //jika ketua edit ketua menjadi orang lain
        // if (
        //   user.id != newObj.chairman_id &&
        //   user.role !== 'Kepala Departemen'
        // ) {
        //   history.push('/kelolaTim');
        //
        // }
      })
      .catch((err) => {
        if (err.response.data.message === 'unauthorized') {
          message.error('Sesi anda telah habis!');
          localStorage.removeItem('user');
          setUser(null);
        } else if (err.response.data.msg === 'Unauthorization Role') {
          message.error(err.response.data.msg);
          history.goBack();
        } else {
          message.error(err.response.data.msg);
        }
        setLoading(false);
      });
  };
  const onFinishAnggota = (values) => {
    setLoading(true);
    let newObj = {
      member: values.anggota,
    };

    myAxios
      .post(`team/store-member/${tim.data.id}`, newObj, {
        headers: {
          Authorization: 'Bearer ' + user.token,
        },
      })
      .then((res) => {
        message.success(res.data.msg);
        setLoading(false);
        setModal(false);
        getTim();
        formAnggota.resetFields();
      })
      .catch((err) => {
        if (err.response.data.message === 'unauthorized') {
          message.error('Sesi anda telah habis!');
          localStorage.removeItem('user');
          setUser(null);
        } else if (err.response.data.msg === 'Unauthorization Role') {
          message.error(err.response.data.msg);
          history.goBack();
        } else {
          message.error(err.response.data.msg);
        }
        setLoading(false);
      });
  };

  const onChange = ({ status, value }) => {
    if (status === 'Ketua') {
      let filterKetua = [];
      filterKetua = tim.tempKaryawan.filter((element) => {
        return element.id != value;
      });
      setTim((prev) => {
        return {
          ...prev,
          karyawanSekretaris: filterKetua,
          chairman_id: value,
        };
      });
      setTim((prev) => {
        return {
          ...prev,
          chairman_id: value,
        };
      });
    } else {
      let filterSekre = [];
      filterSekre = tim.tempKaryawan.filter((element) => {
        return element.id != value;
      });
      setTim((prev) => {
        return {
          ...prev,
          karyawanKetua: filterSekre,
          secretary_id: value,
        };
      });
      setTim((prev) => {
        return {
          ...prev,
          secretary_id: value,
        };
      });
    }
  };
  const onFocus = () => {};
  const onBlur = () => {};
  const onSearch = () => {};

  const onFinishFailed = (errorInfo) => {};

  const onTambahModal = async () => {
    setLoadingTambahAnggota(true);
    // await getDDMember();
    setLoadingTambahAnggota(false);
    setModal(true);
    setModalCon({
      judul: 'Tambah Anggota',
    });
  };

  const columns = [
    {
      title: 'Nama',
      dataIndex: 'name',
      key: 'name',
      sorter: (a, b) => a.name.length - b.name.length,
      ellipsis: true,
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      sorter: (a, b) => a.email.length - b.email.length,
      ellipsis: true,
    },
    {
      title: 'NPP',
      dataIndex: 'username',
      key: 'username',
      sorter: (a, b) =>
        a.username ? a.username.length - b.username.length : null,
      ellipsis: true,
    },
    {
      title: 'Jabatan',
      dataIndex: 'id',
      key: 'id',
      sorter: (a, b) => a.id - b.id,
      ellipsis: true,
      render: (text, row) => (
        <>
          {row.id == idPengurus.chairman_id && row.id == idPengurus.secretary_id
            ? 'Ketua Sekretaris'
            : row.id == idPengurus.chairman_id
            ? 'Ketua'
            : row.id == idPengurus.secretary_id
            ? 'Sekretaris'
            : 'Anggota'}
        </>
      ),
    },
    {
      key: 'action',
      dataIndex: 'id',
      align: 'center',
      render: (dataIndex, row) => (
        <div>
          {!loadingAct && (
            <Space size='middle'>
              {idPengurus.chairman_id != row.id &&
                idPengurus.secretary_id != row.id && (
                  <Tooltip
                    placement='bottom'
                    title='Hapus Anggota'
                    color='#1f1f1f'
                    key='white'>
                    <Popconfirm
                      placement='bottomRight'
                      title='Apakah yakin ingin mengeluarkan anggota dari tim ?'
                      onConfirm={() => onHapus(dataIndex)}
                      okText='Hapus'
                      cancelText='Batal'>
                      <Button
                        negative
                        className='aksiHapus'
                        size='mini'
                        icon='trash'></Button>
                    </Popconfirm>
                  </Tooltip>
                )}
            </Space>
          )}
          {loadingAct && (
            <Spin className='loading-data-table' indicator={antIcon} />
          )}
        </div>
      ),
    },
  ];

  function handleChange(value) {}

  const onReset = () => {
    const data = tim;
    form.setFieldsValue({
      nama_tim: data.data.name,
      chairman_id: data.data.chairman_id,
      secretary_id: data.data.secretary_id,
    });
  };

  return (
    <div className=''>
      {(loadingReq ||
        !tim.anggotaBaru ||
        !tim.karyawanKetua ||
        !tim.karyawanSekretaris ||
        !tim.tempKaryawan) && (
        <h1 className='loading-data'>
          <Spin indicator={antIcon} />
          <p>Mengambil data..</p>
        </h1>
      )}

      {tim.anggotaBaru &&
        tim.karyawanKetua &&
        tim.karyawanSekretaris &&
        tim.tempKaryawan && (
          <Row justify='space-between'>
            <Col md={9} sm={24} className='width-100'>
              <Form
                layout='vertical'
                form={form}
                name='basic'
                initialValues={{ remember: false }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}>
                <Form.Item
                  labelAlign='left'
                  label='Nama Tim'
                  name='nama_tim'
                  rules={[
                    {
                      required: true,
                      message: 'Bagian ini wajib diisi',
                    },
                  ]}>
                  <Input fluid size='small' />
                </Form.Item>

                {tim && (
                  <>
                    {tim.karyawanKetua && (
                      <Form.Item
                        labelAlign='left'
                        label='Nama Ketua'
                        name='chairman_id'
                        rules={[
                          { required: true, message: 'Bagian ini wajib diisi' },
                        ]}>
                        <Select
                          style={{ width: '100%' }}
                          autoComplete='off'
                          showSearch
                          placeholder='Pilih Ketua Tim'
                          optionFilterProp='children'
                          onChange={(value) =>
                            onChange({ status: 'Ketua', value })
                          }
                          onFocus={onFocus}
                          onBlur={onBlur}
                          onSearch={onSearch}
                          optionFilterProp='label'
                          // filterOption={(input, option) =>
                          //   option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                          //   0
                          // }
                        >
                          {tim.karyawanKetua.map((val, item) => (
                            <Option
                              key={val.id}
                              value={val.id}
                              label={val.name}>
                              {val.name}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                    )}

                    {tim.karyawanSekretaris && (
                      <Form.Item
                        labelAlign='left'
                        label='Nama Sekretaris'
                        name='secretary_id'
                        // rules={[
                        //   { required: true, message: 'Bagian ini wajib diisi' },
                        // ]}
                      >
                        <Select
                          style={{ width: '100%' }}
                          autoComplete='off'
                          showSearch
                          placeholder='Pilih Sekretaris Tim'
                          optionFilterProp='children'
                          onChange={(value) =>
                            onChange({ status: 'Sekretaris', value })
                          }
                          onFocus={onFocus}
                          onBlur={onBlur}
                          onSearch={onSearch}
                          // filterOption={(input, option) =>
                          //   option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                          //   0
                          // }
                        >
                          {tim.karyawanSekretaris.map((val, item) => (
                            <Option key={val.id} value={val.id}>
                              {val.name}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                    )}
                    <Form.Item
                      labelAlign='left'
                      label='Tipe Tim'
                      name='tipe_tim'
                      rules={[
                        {
                          required: true,
                          message: 'Bagian ini wajib diisi',
                        },
                      ]}>
                      <Select
                        disabled
                        placeholder='Masukan tipe tim'
                        onChange={(evt) => setTipeTim(evt)}>
                        <Select.Option value='Reguler'>Reguler</Select.Option>
                        <Select.Option value='Ad-Hoc'>Ad-Hoc</Select.Option>
                      </Select>
                    </Form.Item>

                    {tipeTim === 'Ad-Hoc' && (
                      <Form.Item
                        labelAlign='left'
                        label='Durasi kerja'
                        name='durasi'
                        rules={[
                          {
                            required: true,
                            message: 'Bagian ini wajib diisi',
                          },
                        ]}>
                        <RangePicker
                          disabled
                          placeholder={['Tanggal Mulai', 'Tanggal Selesai']}
                          disabledDate={(current) => {
                            return current < moment().subtract(1, 'day');
                          }}
                        />
                      </Form.Item>
                    )}
                  </>
                )}

                <Form.Item>
                  <Button
                    floated='right'
                    type='submit'
                    color='green'
                    htmlType='submit'
                    loading={loading}>
                    Simpan
                  </Button>
                  <Button
                    negative
                    floated='right'
                    type='button'
                    onClick={onReset}
                    loading={loading}>
                    Reset
                  </Button>
                </Form.Item>
              </Form>
            </Col>

            <Col md={14} sm={24}>
              <Row justify='space-between' style={{ marginBottom: '20px' }}>
                <Col md={12} sm={24}>
                  <Space>
                    {/* <Button
                    secondary
                    className='btn-hapus-filter'
                    type='danger'
                    onClick={clearFilters}>
                    Hapus Filter
                  </Button> */}
                    <Button
                      loading={loadingTambahAnggota}
                      color='green'
                      className='btn-hapus-filter'
                      onClick={onTambahModal}>
                      Tambah Anggota
                    </Button>
                  </Space>
                </Col>
                <Col
                  md={12}
                  sm={24}
                  style={{ width: '100%' }}
                  className='mt-mobile'>
                  <Input
                    value={searchText}
                    style={{ width: '100%' }}
                    placeholder='Cari data anggota disini'
                    onChange={onChangeSearch}
                  />
                </Col>
              </Row>

              <Table
                loading={loadingTable}
                loadingIndicator={antIcon}
                columns={columns}
                dataSource={anggota}
                scroll={{ x: 750, y: 1500 }}
                size='small'
              />
            </Col>
          </Row>
        )}
      {modalCon && (
        <Modal
          centered
          title={modalCon.judul}
          visible={modalVisible}
          onCancel={handleCancel}
          footer={[]}>
          <Form
            layout='vertical'
            form={formAnggota}
            name='basic'
            initialValues={{ remember: false }}
            onFinish={onFinishAnggota}
            onFinishFailed={onFinishFailed}>
            {tim.anggotaBaru && (
              <Form.Item
                labelAlign='left'
                label='Anggota'
                name='anggota'
                rules={[
                  {
                    required: true,
                    message: 'Bagian ini wajib diisi',
                  },
                ]}>
                <Select
                  mode='multiple'
                  placeholder='Silahkan pilih anggota'
                  onChange={handleChange}
                  optionFilterProp='label'
                  style={{ width: '100%' }}>
                  {tim.anggotaBaru.map((val, item) => (
                    <Option
                      style={{ height: '30px' }}
                      key={val.id}
                      value={val.id}
                      label={val.name}>
                      <p>
                        <b>{val.name}</b>
                      </p>
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            )}

            <Form.Item>
              <Button
                size='small'
                floated='right'
                type='submit'
                color='green'
                loading={loading}>
                Simpan
              </Button>
              <Button
                size='small'
                floated='right'
                type='button'
                negative
                onClick={() => formAnggota.resetFields()}
                loading={loading}>
                Reset
              </Button>
            </Form.Item>
          </Form>
        </Modal>
      )}
    </div>
  );
};

export default EditTim;
