import React, { useContext } from 'react';
import myAxios from '../../../../myAxios';
import {
  Table,
  Space,
  Spin,
  Row,
  Col,
  message,
  Input,
  Tooltip,
  Popconfirm,
  Form,
  Upload,
  Modal,
  Button as BA,
  Pagination,
  Select,
} from 'antd';
import { Button } from 'semantic-ui-react';
import { LoadingOutlined } from '@ant-design/icons';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useState, useEffect } from 'react';
import { UserContext } from '../../../../context/UserContext';
import { useHistory, useParams } from 'react-router-dom';

import { CloudArrowUp } from 'react-bootstrap-icons';
const { Option } = Select;

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
const tableLoading = {
  indicator: <Spin className='loading-data-table' indicator={antIcon} />,
};

const Dokumen = () => {
  const [user, setUser] = useContext(UserContext);

  const [dokumen, setDokumen] = useState(null);
  const [agendaDD, setAgendaDD] = useState([]);

  const [auth, setAuth] = useState(null);

  const [searchText, setSearch] = useState(null);

  const [loading, setLoading] = useState(false);
  const [loadingAct, setLoadingAct] = useState(false);
  const [loadingMinute, setLoadingM] = useState(false);
  const [docCek, setDocCek] = useState(false);

  const [modalVisible, setModal] = useState(false);
  const [data, setData] = useState(null);
  const [pagination, setPagination] = useState(null);
  const [page, setPage] = useState('10');
  const [form] = Form.useForm();
  const [formEdit] = Form.useForm();
  let { history } = useHistory;
  const { idRapat } = useParams();

  const optionsDate = {
    weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  };
  useEffect(() => {
    const timeOutId = setTimeout(
      () => getDokumen(searchText, null, null, null, page),
      500
    );
    return () => clearTimeout(timeOutId);
  }, [searchText]);

  const getDokumen = (
    search = undefined,
    page = '1',
    sortBy = undefined,
    sorting = 'ASC',
    show = '10'
  ) => {
    setLoading(tableLoading);
    myAxios
      .get(`meeting-document/${idRapat}`, {
        params: {
          page: Number(page),
          search: search,
          show: show,
          sortBy: sortBy,
          sorting: sorting,
        },
        headers: {
          Authorization: 'Bearer ' + user.token,
        },
      })
      .then((res) => {
        const data = res.data.data;

        if (data.data.data.length > 0) {
          data.data.data.map((e) => {
            e.created_at = new Date(e.created_at).toLocaleDateString(
              'id-ID',
              optionsDate
            );
          });
          setDokumen(data.data.data);
          setPagination({
            total: res.data.data.data.total,
            current: res.data.data.data.current_page,
            perPage: res.data.data.data.per_page,
          });
        } else {
          setDokumen([]);
          setAuth([]);
        }
        setAgendaDD(data.agenda);
        setAuth(data.auth);
        setLoading(false);
      })
      .catch((err) => {
        if (err.response.data.message === 'unauthorized') {
          message.error('Sesi anda telah habis!');
          localStorage.removeItem('user');
          setUser(null);
        } else if (err.response.data.msg === 'Unauthorization Role') {
          message.error(err.response.data.msg);
          history.goBack();
        } else {
          message.error(err.response.data.msg);
        }
        setLoading(false);
        setDokumen([]);
      });
  };

  const onChange = () => {};
  const onFocus = () => {};
  const onBlur = () => {};
  const onSearch = () => {};

  const onChangePagination = (evt, show) => {
    setPage(show);
    getDokumen('', evt, null, null, show);
  };

  const onChangeTable = (pagination, filters, sorter, extra) => {
    const order = sorter.order === 'descend' ? 'DESC' : 'ASC';
    const field = sorter.order === undefined ? undefined : sorter.field;

    getDokumen('', '1', field, order);
  };

  const onHapus = (param) => {
    setLoadingAct(true);
    myAxios
      .delete(`meeting-document/${param}`, {
        headers: {
          Authorization: 'Bearer ' + user.token,
        },
      })
      .then((res) => {
        getDokumen();
        message.success(res.data.msg);
        setLoadingAct(false);
      })
      .catch((err) => {
        if (err.response.data.message === 'unauthorized') {
          message.error('Sesi anda telah habis!');
          localStorage.removeItem('user');
          setUser(null);
        } else if (err.response.data.msg === 'Unauthorization Role') {
          message.error(err.response.data.msg);
          history.goBack();
        } else {
          message.error(err.response.data.msg);
        }
        setLoadingAct(false);
      });
  };

  const clearFilters = () => {
    setSearch(null);
  };

  const onChangeSearch = (evt) => {
    setLoading(tableLoading);
    setSearch(evt.target.value);
  };

  const handleCancel = () => {
    setModal(false);
  };

  const onFinish = (values) => {
    setLoadingM(true);

    const formData = new FormData();

    let tempFile = values.dokumen_rapat.fileList;
    tempFile.forEach((e) => {
      formData.append('filelist[]', e.originFileObj);
    });
    formData.append('user_id', user.id);
    formData.append('meeting_id', idRapat);
    formData.append('agenda_id', values.agenda);

    myAxios
      .post(`meeting-document`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: 'Bearer ' + user.token,
        },
      })
      .then((res) => {
        message.success(res.data.msg);
        setLoadingM(false);
        setModal(false);
        form.resetFields();
        getDokumen();
      })
      .catch((err) => {
        if (err.response.data.message === 'unauthorized') {
          message.error('Sesi anda telah habis!');
          localStorage.removeItem('user');
          setUser(null);
        } else if (err.response.data.msg === 'Unauthorization Role') {
          message.error(err.response.data.msg);
          history.goBack();
        } else {
          message.error(err.response.data.msg);
        }
        setLoadingM(false);
      });
  };

  const onFinishFailed = (errorInfo) => {};

  const onFinishEdit = (value) => {
    console.log(data);
    setLoading(true);
    const newObj = {
      agenda_id: value.agenda,
    };
    myAxios
      .put(`meeting-document/${data.id}`, newObj, {
        headers: {
          Authorization: 'Bearer ' + user.token,
        },
      })
      .then((res) => {
        message.success(res.data.msg);
        setLoading(false);
        setModal(false);
        getDokumen();
      })
      .catch((err) => {
        if (err.response.data.message === 'unauthorized') {
          message.error('Sesi anda telah habis!');
          localStorage.removeItem('user');
          setUser(null);
        } else if (err.response.data.msg === 'Unauthorization Role') {
          message.error(err.response.data.msg);
          history.goBack();
        } else {
          message.error(err.response.data.msg);
        }
        setLoading(false);
        setDokumen([]);
      });
  };

  const onEditDokumen = (value) => {
    console.log(value);
    setModal(true);
    setData(value);
    formEdit.setFieldsValue({
      document_name: value.filename,
      agenda: value.agenda_id,
    });
  };

  const onUnduhDokumen = (param) => {
    setLoadingAct(true);

    myAxios
      .get(`meeting-document-download/${param.id}`, {
        headers: {
          Authorization: 'Bearer ' + user.token,
        },
        responseType: 'blob',
      })
      .then((res) => {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', param.filename);
        // link.target = "_blank";
        document.body.appendChild(link);
        link.click();
        setLoadingAct(false);
      })
      .catch((err) => {
        if (err.response.data.message === 'unauthorized') {
          message.error('Sesi anda telah habis!');
          localStorage.removeItem('user');
          setUser(null);
        } else if (err.response.data.msg === 'Unauthorization Role') {
          message.error(err.response.data.msg);
          history.goBack();
        } else {
          message.error(err.response.data.msg);
        }
        setLoadingAct(false);
      });
  };

  const columns = [
    {
      title: 'Nama Dokumen',
      dataIndex: 'filename',
      key: 'filename',
      sorter: (a, b) => a.filename.length - b.filename.length,
      ellipsis: true,
    },
    {
      title: 'Diunggah oleh',
      dataIndex: 'uploader',
      key: 'uploader',
      sorter: (a, b) => a.uploader.length - b.uploader.length,
      ellipsis: true,
    },
    {
      title: 'Agenda',
      dataIndex: 'agenda',
      key: 'agenda',
      sorter: (a, b) => a.agenda.length - b.agenda.length,
      ellipsis: true,
    },
    {
      title: 'Tanggal Upload',
      dataIndex: 'created_at',
      key: 'created_at',
      sorter: (a, b) => a.created_at.length - b.created_at.length,
      ellipsis: true,
    },
    {
      key: 'action',
      dataIndex: 'id',
      align: 'center',
      render: (dataIndex, text) => (
        <div>
          {!loadingAct && (
            <>
              {auth.status !== 'Selesai' && (
                <Space size='middle'>
                  <Tooltip
                    placement='bottom'
                    title='Unduh Dokumen'
                    color='#1f1f1f'
                    key='white'>
                    <Button
                      color='orange'
                      className='aksiEdit'
                      size='mini'
                      onClick={() => onUnduhDokumen(text)}
                      icon='download'
                    />
                  </Tooltip>
                  <Tooltip
                    placement='bottom'
                    title='Ubah Dokumen'
                    color='#1f1f1f'
                    key='white'>
                    <Button
                      disabled={
                        text.user_id === user.id || user.id == auth.chairman_id
                          ? false
                          : true
                      }
                      color='facebook'
                      className='aksiEdit'
                      size='mini'
                      onClick={() => onEditDokumen(text)}
                      icon='pencil'
                    />
                  </Tooltip>
                  <Tooltip
                    placement='bottom'
                    title='Hapus Dokumen'
                    color='#1f1f1f'
                    key='white'>
                    <Popconfirm
                      placement='bottomRight'
                      title='Apakah yakin ingin menghapus dokumen ?'
                      onConfirm={() => onHapus(dataIndex)}
                      okText='Hapus'
                      cancelText='Batal'>
                      <Button
                        disabled={
                          text.user_id === user.id ||
                          user.id == auth.chairman_id
                            ? false
                            : true
                        }
                        negative
                        className='aksiHapus'
                        size='mini'
                        icon='trash'
                      />
                    </Popconfirm>
                  </Tooltip>
                </Space>
              )}
            </>
          )}
          {loadingAct && (
            <Spin className='loading-data-table' indicator={antIcon} />
          )}
        </div>
      ),
    },
  ];

  const onChangeDokumen = ({ file, fileList }) => {
    setDocCek(fileList.length > 0 ? true : false);
    if (file.catatan !== 'uploading') {
    }
  };

  return (
    <>
      {!auth && (
        <>
          <h1 className='loading-data'>
            <Spin indicator={antIcon} />
            <p>Mengambil data ...</p>
          </h1>
        </>
      )}
      {auth && (
        <Row justify='space-between'>
          {auth.status !== 'Selesai' && (
            <Col md={9} sm={24} className='width-100'>
              <div className=''>
                <Form
                  form={form}
                  layout='vertical'
                  onFinish={onFinish}
                  autoComplete='off'>
                  {agendaDD && (
                    <Form.Item
                      name='agenda'
                      label='Agenda'
                      rules={[
                        {
                          required: true,
                          message: 'Bagian ini wajib diisi',
                        },
                      ]}>
                      <Select
                        style={{ width: '100%' }}
                        autoComplete='off'
                        showSearch
                        placeholder='Pilih agenda'
                        optionFilterProp='label'
                        onChange={onChange}
                        onFocus={onFocus}
                        onBlur={onBlur}
                        onSearch={onSearch}>
                        {agendaDD.map((val, item) => (
                          <Option
                            key={val.id}
                            value={val.id}
                            label={val.agenda}>
                            {val.agenda}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  )}
                  <Form.Item name='dokumen_rapat'>
                    <Upload
                      multiple
                      onChange={onChangeDokumen}
                      beforeUpload={(file) => {
                        const reader = new FileReader();

                        reader.onload = (e) => {
                          //
                        };
                        reader.readAsText(file);

                        return false;
                      }}>
                      <BA className='tambah-dokumen' type='dashed'>
                        <div className='logo-upload'>
                          <CloudArrowUp />
                        </div>
                        <p>Seret dan lepas untuk mengunggah dokumen</p>
                        <p>atau</p>
                        <p>
                          <b> Klik untuk pilih dokumen</b>
                        </p>
                      </BA>
                    </Upload>
                  </Form.Item>

                  <Form.Item>
                    {docCek && (
                      <Button
                        size='small'
                        color='green'
                        type='submit'
                        loading={loadingMinute}>
                        Unggah
                      </Button>
                    )}
                  </Form.Item>
                </Form>
              </div>
            </Col>
          )}
          <Col md={auth.status !== 'Selesai' ? 14 : 24} sm={24}>
            <div className='' style={{ minHeight: '500px' }}>
              <Row justify='space-between' style={{ marginBottom: '20px' }}>
                <Col md={12} sm={24}>
                  <Space>
                    <Button
                      secondary
                      className='btn-hapus-filter'
                      type='danger'
                      onClick={clearFilters}>
                      Hapus Filter
                    </Button>
                  </Space>
                </Col>
                <Col
                  md={12}
                  sm={24}
                  style={{ width: '100%' }}
                  className='mt-mobile'>
                  <Input
                    value={searchText}
                    style={{ width: '100%' }}
                    placeholder='Cari dokumen rapat disini'
                    onChange={onChangeSearch}
                  />
                </Col>
              </Row>

              <Table
                loading={loading}
                onChange={onChangeTable}
                pagination={false}
                loadingIndicator={antIcon}
                columns={columns}
                dataSource={dokumen}
                scroll={{ x: 200, y: 575 }}
                size='small'
              />
              {pagination && (
                <div className='pagination'>
                  <Pagination
                    total={pagination.total}
                    showTotal={(total, range) =>
                      `Menampilkan ${range[0]}-${range[1]} dari ${total} data`
                    }
                    defaultPageSize={Number(pagination.perPage)}
                    current={pagination.current}
                    onChange={onChangePagination}
                  />
                </div>
              )}
            </div>
          </Col>
        </Row>
      )}
      <Modal
        title='Ubah Dokumen'
        visible={modalVisible}
        onCancel={handleCancel}
        footer={[]}>
        <Form
          form={formEdit}
          layout='vertical'
          onFinish={onFinishEdit}
          autoComplete='off'>
          <Form.Item
            labelAlign='left'
            label='Nama Dokumen'
            name='document_name'
            rules={[
              {
                required: true,
              },
            ]}>
            <Input disabled={true} />
          </Form.Item>
          {agendaDD && (
            <Form.Item
              name='agenda'
              label='Agenda'
              rules={[
                {
                  required: true,
                  message: 'Bagian ini wajib diisi',
                },
              ]}>
              <Select
                style={{ width: '100%' }}
                autoComplete='off'
                showSearch
                placeholder='Pilih agenda'
                optionFilterProp='label'
                onChange={onChange}
                onFocus={onFocus}
                onBlur={onBlur}
                onSearch={onSearch}>
                {agendaDD.map((val, item) => (
                  <Option key={val.id} value={val.id} label={val.agenda}>
                    {val.agenda}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          )}

          <Form.Item>
            <Button
              floated='right'
              type='submit'
              color='green'
              loading={loading}>
              Simpan
            </Button>
            <Button floated='right' type='button' negative loading={loading}>
              Reset
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default Dokumen;
