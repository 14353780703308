import React, { useContext } from 'react';
import myAxios from '../../myAxios';
import {
  Table,
  Space,
  Spin,
  Row,
  Col,
  message,
  Tooltip,
  Popconfirm,
  Form,
  Modal,
  Select,
  Input,
  Pagination,
  Tag,
  Switch,
} from 'antd';
import { Button, Icon } from 'semantic-ui-react';
import { LoadingOutlined } from '@ant-design/icons';

import { useState, useEffect } from 'react';
import { UserContext } from '../../context/UserContext';
import { useHistory } from 'react-router-dom';
import bimAxios from '../../bimAxios';

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
const tableLoading = {
  indicator: <Spin className='loading-data-table' indicator={antIcon} />,
};
const { Option } = Select;
const LihatKaryawan = () => {
  const [user, setUser] = useContext(UserContext);
  const [karyawan, setKaryawan] = useState(null);
  const [karyawanDD, setKaryawanDD] = useState(null);
  const [programStudy, setProgramStudy] = useState(null);
  const [jabatan, setJabatan] = useState(null);
  const [modalCon, setModalCon] = useState(null);
  const [tempData, setData] = useState(null);
  const [searchText, setSearch] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loadingT, setLoadingT] = useState(false);
  const [loadingAct, setLoadingAct] = useState(false);
  const [modalVisible, setModal] = useState(false);
  const [pagination, setPagination] = useState(null);
  const [page, setPage] = useState('10');
  const [isOld, setIsOld] = useState(false);
  const [switchAdd, setSwitchAdd] = useState(false);
  let history = useHistory();
  const [matches, setMatches] = useState(
    window.matchMedia('(max-width: 1728px)').matches
  );
  const [form] = Form.useForm();
  useEffect(() => {
    function handleResize() {
      setMatches(window.matchMedia('(max-width: 1728px)').matches);
    }
    window.addEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    const timeOutId = setTimeout(
      () => getKaryawan(searchText, null, null, null, page),
      500
    );
    return () => clearTimeout(timeOutId);
  }, [searchText]);

  const getKaryawan = (
    search = undefined,
    page = 1,
    sortBy = undefined,
    sorting = 'ASC',
    show = 10
  ) => {
    const url =
      user.role_unit === 'kepala_tu'
        ? 'unit/list-employee'
        : 'offices/list-employee';
    setLoadingT(tableLoading);
    myAxios
      .get(`${url}`, {
        params: {
          page: Number(page),
          search: search,
          show: show,
          sortBy: sortBy,
          sorting: sorting,
        },
        headers: {
          Authorization: 'Bearer ' + user.token,
        },
      })
      .then((res) => {
        const data = res.data.data.data;
        setLoadingT(false);
        // data.map((e) => {
        //   e.email === null ? '-' : e.email;
        // });
        setKaryawan(data);
        setPagination({
          total: res.data.data.total,
          current: res.data.data.current_page,
          perPage: res.data.data.per_page,
        });
      })
      .catch((err) => {
        if (err.response.data.message === 'unauthorized') {
          message.error('Sesi anda telah habis!');
          localStorage.removeItem('user');
          setUser(null);
        } else if (err.response.data.msg === 'Unauthorization Role') {
          message.error(err.response.data.msg);
          history.goBack();
        } else {
          message.error(err.response.data.msg);
        }
        setLoadingT(false);
      });
  };

  const onChangePagination = (evt, show) => {
    setPage(show);
    getKaryawan(searchText, evt, null, null, show);
  };

  const getKaryawanDD = () => {
    myAxios
      .get(`users-office-store`, {
        headers: {
          Authorization: 'Bearer ' + user.token,
        },
      })
      .then((res) => {
        const data = res.data.data;
        setKaryawanDD(data);
      })
      .catch((err) => {
        if (err.response.data.message === 'unauthorized') {
          message.error('Sesi anda telah habis!');
          localStorage.removeItem('user');
          setUser(null);
        } else if (err.response.data.msg === 'Unauthorization Role') {
          message.error(err.response.data.msg);
          history.goBack();
        } else {
          message.error(err.response.data.msg);
        }
      });
  };
  const getProgramStudy = () => {
    myAxios
      .get(`unit/studyPrograms`, {
        headers: {
          Authorization: 'Bearer ' + user.token,
        },
      })
      .then((res) => {
        const data = res.data.data;
        setProgramStudy(data);
      })
      .catch((err) => {
        if (err.response.data.message === 'unauthorized') {
          message.error('Sesi anda telah habis!');
          localStorage.removeItem('user');
          setUser(null);
        } else if (err.response.data.msg === 'Unauthorization Role') {
          message.error(err.response.data.msg);
          history.goBack();
        } else {
          message.error(err.response.data.msg);
        }
      });
  };

  const getJabatanKaryawan = async () => {
    await myAxios
      .get(`office-list-role`, {
        headers: {
          Authorization: 'Bearer ' + user.token,
        },
      })
      .then((res) => {
        const data = res.data.data;
        setJabatan(data);
      })
      .catch((err) => {
        if (err.response.data.message === 'unauthorized') {
          message.error('Sesi anda telah habis!');
          localStorage.removeItem('user');
          setUser(null);
        } else if (err.response.data.msg === 'Unauthorization Role') {
          message.error(err.response.data.msg);
          history.goBack();
        } else {
          message.error(err.response.data.msg);
        }
      });
  };

  const onKeluarKaryawan = (param) => {
    let url;
    if (user.role_kantor === 'admin') {
      url = 'offices/remove-employee';
    } else {
      url = 'unit/remove-employee';
    }
    setLoadingAct(true);
    myAxios
      .delete(`${url}/${param}`, {
        headers: {
          Authorization: 'Bearer ' + user.token,
        },
      })
      .then((res) => {
        getKaryawan(undefined, null, undefined, null, page);
        message.success(res.data.msg);
        setLoadingAct(false);
      })
      .catch((err) => {
        if (err.response.data.message === 'unauthorized') {
          message.error('Sesi anda telah habis!');
          localStorage.removeItem('user');
          setUser(null);
        } else if (err.response.data.msg === 'Unauthorization Role') {
          message.error(err.response.data.msg);
          history.goBack();
        } else {
          message.error(err.response.data.msg);
        }
        setLoadingAct(false);
      });
  };

  const clearFilters = () => {
    setKaryawan(tempData);
    setSearch('');
  };

  const onChangeSearch = (evt) => {
    setLoadingT(tableLoading);
    setSearch(evt.target.value);
  };

  const handleCancel = () => {
    setModal(false);
    setIsOld(false);
    form.resetFields();
  };

  const handleReset = () => {
    if (modalCon.data.id === null) form.resetFields();
    else {
      form.setFieldsValue({
        nama: modalCon.data.name,
        email: modalCon.data.email,
        npp: modalCon.data.npp,
      });
    }
  };

  const onFinish = (values) => {
    setLoading(true);
    setSearch('');

    if (modalCon.judul === 'Tambah Karyawan / Dosen') {
      var url;
      let newObj;

      if (user.role_unit === 'kepala_tu') {
        if (switchAdd) {
          //add dosen menggunakana API kak Rio
          newObj = {
            email: values.email,
            username: values.username,
            name: values.nama,
            academic_position: values.jabatan_akademik,
            id: -1,
            study_program: programStudy.filter((e) => {
              return e.id === values.program_studi;
            })[0].name,
            study_program_id: values.program_studi,
          };
          url = 'user/lectures';
        } else {
          newObj = {
            email: values.email,
            username: values.username,
            name: values.nama,
          };
          url = 'unit/store-employee';
        }
      } else {
        if (isOld) {
          newObj = {
            employees: values.karyawan,
          };
          url = 'offices/store-employee';
        } else {
          newObj = {
            email: values.email,
            username: values.username,
            name: values.nama,
          };
          url = 'offices/store-new-employee';
        }
      }

      if (!switchAdd) {
        myAxios
          .post(url, newObj, {
            headers: {
              Authorization: 'Bearer ' + user.token,
            },
          })
          .then((res) => {
            message.success(res.data.msg);
            setLoading(false);
            setModal(false);
            form.resetFields();
            getKaryawan(undefined, null, undefined, null, page);
          })
          .catch((err) => {
            if (err.response.data.message === 'unauthorized') {
              message.error('Sesi anda telah habis!');
              localStorage.removeItem('user');
              setUser(null);
            } else if (err.response.data.msg === 'Unauthorization Role') {
              message.error(err.response.data.msg);
              history.goBack();
            } else {
              message.error(err.response.data.msg);
            }
            setLoading(false);
          });
      } else {
        bimAxios
          .post(url, newObj, {
            headers: {
              Authorization: 'Bearer ' + user.token,
            },
          })
          .then((res) => {
            message.success(res.data.msg);
            setLoading(false);
            setModal(false);
            form.resetFields();
            getKaryawan(undefined, null, undefined, null, page);
          })
          .catch((err) => {
            if (err.response.data.message === 'unauthorized') {
              message.error('Sesi anda telah habis!');
              localStorage.removeItem('user');
              setUser(null);
            } else if (err.response.data.msg === 'Unauthorization Role') {
              message.error(err.response.data.msg);
              history.goBack();
            } else {
              message.error(err.response.data.msg);
            }
            setLoading(false);
          });
      }
    } else {
      let newObj;
      let url;
      if (user.role_unit === 'kepala_tu') {
        newObj = {
          id: modalCon.data.id,
          email: values.email,
          username: values.username,
          name: values.nama,
        };
        url = 'edit-user';
      } else {
        newObj = {
          id: modalCon.data.id,
          email: values.email,
          username: values.username,
          name: values.nama,
          role_id: values.jabatan,
        };
        url = 'offices/update-employee';
      }

      myAxios
        .put(url, newObj, {
          headers: {
            Authorization: 'Bearer ' + user.token,
          },
        })
        .then((res) => {
          message.success(res.data.msg);
          setLoading(false);
          setModal(false);
          form.resetFields();
          getKaryawan(undefined, null, undefined, null, page);
        })
        .catch((err) => {
          if (err.response.data.message === 'unauthorized') {
            message.error('Sesi anda telah habis!');
            localStorage.removeItem('user');
            setUser(null);
          } else if (err.response.data.msg === 'Unauthorization Role') {
            message.error(err.response.data.msg);
            history.goBack();
          } else {
            message.error(err.response.data.msg);
          }
          setLoading(false);
        });
    }
  };

  const onFinishFailed = (errorInfo) => {};

  const onTambahModal = (evt) => {
    getKaryawanDD();
    getProgramStudy();
    setModal(true);
    setModalCon({
      judul: 'Tambah Karyawan / Dosen',
      id: null,
    });

    form.setFieldsValue({
      jabatan: 'Karyawan',
    });
  };
  const onEditModal = async (param) => {
    setLoadingAct(true);
    if (user.role_kantor === 'admin') {
      await getJabatanKaryawan();
      form.setFieldsValue({
        jabatan: param.office_role_id,
      });
    }
    setIsOld(false);
    setModalCon({
      judul: 'Ubah Karyawan / Dosen',
      data: param,
    });
    setLoadingAct(false);
    setModal(true);
    form.setFieldsValue({
      nama: param.name,
      email: param.email,
      username: param.username,
    });
    setSwitchAdd(false);
  };

  const onResetPassword = (param) => {
    setLoadingAct(true);
    myAxios
      .put(`reset-user-password/${param}`, null, {
        headers: {
          Authorization: 'Bearer ' + user.token,
        },
      })
      .then((res) => {
        message.success(res.data.msg);
        setLoadingAct(false);
        getKaryawan(undefined, null, undefined, null, page);
      })
      .catch((err) => {
        if (err.response.data.message === 'unauthorized') {
          message.error('Sesi anda telah habis!');
          localStorage.removeItem('user');
          setUser(null);
        } else if (err.response.data.msg === 'Unauthorization Role') {
          message.error(err.response.data.msg);
          history.goBack();
        } else {
          message.error(err.response.data.msg);
        }
        setLoadingAct(false);
      });
  };
  const onChangeSwitch = () => {
    setIsOld(!isOld);
  };

  const onChangeSwitchTambah = () => {
    setSwitchAdd(!switchAdd);
  };

  const onChange = () => {};
  const onFocus = () => {};
  const onBlur = () => {};
  const onSearch = () => {};

  const onChangeTable = (pagination, filters, sorter, extra) => {
    const order = sorter.order === 'descend' ? 'DESC' : 'ASC';
    const field = sorter.order === undefined ? undefined : sorter.field;
    getKaryawan('', '1', field, order);
  };

  const columns = [
    {
      title: 'Nama',
      dataIndex: 'name',
      key: 'name',
      sorter: (a, b) => a.name.length - b.name.length,
      ellipsis: true,
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      // sorter: (a, b) =>
      // a.email || b.email === null ? '' : a.email.length - b.email.length,
      ellipsis: true,
    },
    {
      title: 'Nomor Induk Pegawai',
      dataIndex: 'username',
      key: 'username',
      sorter: (a, b) => a.username.length - b.username.length,
      ellipsis: true,
    },
    {
      title: 'Jabatan',
      dataIndex: 'role',
      key: 'role',
      sorter: (a, b) => a.role.length - b.role.length,
      ellipsis: true,
      render: (dataIndex) =>
        'kepala_tu' === dataIndex ? (
          <>KEPALA TU</>
        ) : (
          <>{dataIndex.toUpperCase()}</>
        ),
    },
    // {
    //   title: 'Status',
    //   dataIndex: 'is_deleted',
    //   key: 'is_deleted',
    //   sorter: (a, b) => Number(a.is_deleted) - Number(b.is_deleted),
    //   render: (dataIndex) =>
    //     Number(dataIndex) === 0 ? (
    //       <Tag color='green'>Aktif</Tag>
    //     ) : (
    //       <Tag color='red'>Tidak Aktif</Tag>
    //     ),
    // },
    {
      key: 'action',
      dataIndex: 'id',
      align: 'center',
      render: (dataIndex, row) => (
        <div>
          {!loadingAct && (
            <Space size='middle'>
              <Tooltip
                placement='bottom'
                title='Ubah Karyawan / Dosen'
                color='#1f1f1f'
                key='white'>
                <Button
                  disabled={row.role_type === 'admin' ? true : false}
                  className='aksiEdit'
                  color='facebook'
                  size='mini'
                  icon='pencil alternate'
                  onClick={() => onEditModal(row)}></Button>
              </Tooltip>
              <Tooltip
                placement='bottom'
                title='Reset Kata Sandi'
                color='#1f1f1f'
                key='white'>
                <Popconfirm
                  placement='bottomRight'
                  title='Apakah yakin ingin mereset kata sandi ?'
                  onConfirm={() => onResetPassword(dataIndex)}
                  okText='Reset'
                  cancelText='Batal'>
                  <Button
                    className='aksiEdit'
                    color='orange'
                    size='mini'
                    icon='key'></Button>
                </Popconfirm>
              </Tooltip>
              <Tooltip
                placement='bottom'
                title='Keluarkan Karyawan'
                color='#1f1f1f'
                key='white'>
                <Popconfirm
                  placement='bottomRight'
                  title='Apakah yakin ingin mengeluarkan karyawan ?'
                  onConfirm={() => onKeluarKaryawan(dataIndex)}
                  okText='Ya'
                  cancelText='Batal'>
                  <Button
                    disabled={
                      row.role_type === 'admin' ||
                      row.role_type === 'kepalakantor' ||
                      row.role_type === 'staffkeuangan' ||
                      row.role === 'kadep' ||
                      row.role === 'kepala_tu' ||
                      row.role === 'dekan'
                        ? true
                        : false
                    }
                    className='aksiHapus'
                    color='red'
                    icon='trash alternate'
                    size='mini'></Button>
                </Popconfirm>
              </Tooltip>
            </Space>
          )}
          {loadingAct && (
            <Spin className='loading-data-table' indicator={antIcon} />
          )}
        </div>
      ),
    },
  ];

  return (
    <div className='container-content karyawan'>
      <div className='container-table'>
        <h1 className='table-title'>
          DATA KARYAWAN {user.unit ? user.unit : user.kantor}
        </h1>
        <Row justify='space-between' style={{ marginBottom: '20px' }}>
          <Col md={12} sm={24}>
            <Space>
              <Button
                className='btn-hapus-filter'
                onClick={onTambahModal}
                icon
                labelPosition='left'
                color='green'>
                <Icon name='user plus' />
                Tambah Karyawan / Dosen
              </Button>
            </Space>
          </Col>
          <Col md={12} sm={24} style={{ width: '100%' }} className='mt-mobile'>
            <Input
              value={searchText}
              style={{ width: '100%' }}
              placeholder='Cari data karyawan disini'
              onChange={onChangeSearch}
            />
          </Col>
        </Row>
        <div className='scrollable-table'>
          <Table
            onChange={onChangeTable}
            loading={loadingT}
            pagination={false}
            loadingIndicator={antIcon}
            columns={columns}
            dataSource={karyawan}
            scroll={{ x: 900 }}
            size='small'
          />
        </div>
        {pagination && (
          <div className='pagination'>
            <Pagination
              showSizeChanger={true}
              pageSize={page}
              total={pagination.total}
              showTotal={(total, range) =>
                `Menampilkan ${range[0]}-${range[1]} dari ${total} data`
              }
              defaultPageSize={Number(pagination.perPage)}
              current={pagination.current}
              onChange={onChangePagination}
            />
          </div>
        )}
        {/* {modalCon && (
          <Modal
            centered
            visible={modalVisible}
            onCancel={handleCancel}
            footer={[]}
            title={modalCon.judul}>
            <Form
              layout='vertical'
              form={form}
              name='basic'
              initialValues={{ remember: false }}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}>
              {modalCon.judul === 'Ubah Karyawan / Dosen' && (
                <Form.Item
                  labelAlign='left'
                  label='Nama Karyawan'
                  name='nama_karyawan'
                  rules={[
                    {
                      required: true,
                      message: 'Bagian ini wajib diisi',
                    },
                  ]}>
                  <Input disabled />
                </Form.Item>
              )}

              {karyawanDD && modalCon.judul === 'Tambah Karyawan' && (
                <Form.Item
                  labelAlign='left'
                  label='Karyawan'
                  name='karyawan'
                  rules={[
                    {
                      required: true,
                      message: 'Bagian ini wajib diisi',
                    },
                  ]}>
                  <Select
                    mode='multiple'
                    style={{ width: '100%' }}
                    autoComplete='off'
                    showSearch
                    placeholder='Pilih Karyawan'
                    optionFilterProp='label'
                    onChange={onChange}
                    onFocus={onFocus}
                    onBlur={onBlur}
                    onSearch={onSearch}
                    // filterOption={(input, option) =>
                    //   option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                    //   0
                    // }
                  >
                    {karyawanDD.map((val, item) => (
                      <Option key={val.id} value={val.id} label={val.name}>
                        {val.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              )}

              {modalCon.judul === 'Ubah Karyawan / Dosen' && jabatan && (
                <Form.Item
                  labelAlign='left'
                  label='Jabatan'
                  name='jabatan'
                  rules={[
                    {
                      required: true,
                      message: 'Bagian ini wajib diisi',
                    },
                  ]}>
                  <Select
                    style={{ width: '100%' }}
                    autoComplete='off'
                    showSearch
                    placeholder='Ubah Jabatan'
                    optionFilterProp='label'
                    onChange={onChange}
                    onFocus={onFocus}
                    onBlur={onBlur}
                    onSearch={onSearch}
                    // filterOption={(input, option) =>
                    //   option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                    //   0
                    // }
                  >
                    {jabatan.map((val, item) => (
                      <Option key={val.id} value={val.id} label={val.name}>
                        {val.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              )}
              <Form.Item>
                <Button
                  floated='right'
                  type='submit'
                  loading={loading}
                  color='green'>
                  Simpan
                </Button>

                <Button
                  type='button'
                  floated='right'
                  onClick={handleReset}
                  loading={loading}
                  negative>
                  Reset
                </Button>
              </Form.Item>
            </Form>
          </Modal>
        )} */}
        {modalCon && (
          <Modal
            centered
            title={modalCon.judul}
            visible={modalVisible}
            onCancel={handleCancel}
            footer={[]}>
            <Form
              layout='vertical'
              form={form}
              name='basic'
              initialValues={{ remember: false }}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}>
              {modalCon.judul === 'Tambah Karyawan / Dosen' &&
                user.role_unit === 'kepala_tu' && (
                  <Form.Item
                    // labelAlign='left'
                    name='isOld'
                    rules={[
                      {
                        message: 'Bagian ini wajib diisi',
                      },
                    ]}>
                    <Switch
                      checked={switchAdd}
                      onChange={onChangeSwitchTambah}
                      style={{ marginRight: '10px' }}
                    />
                    <label>
                      <strong>
                        {switchAdd
                          ? 'Tambah Data Dosen'
                          : 'Tambah Data Karyawan'}
                      </strong>
                    </label>
                  </Form.Item>
                )}
              {modalCon.judul === 'Tambah Karyawan / Dosen' &&
                user.role_kantor === 'admin' && (
                  <>
                    <Form.Item
                      // labelAlign='left'
                      name='isOld'
                      rules={[
                        {
                          message: 'Bagian ini wajib diisi',
                        },
                      ]}>
                      <Switch
                        onChange={onChangeSwitch}
                        style={{ marginRight: '10px' }}
                      />
                      <label>
                        <strong>Cari karyawan yang sudah ada</strong>
                      </label>
                    </Form.Item>

                    {isOld && (
                      <>
                        {karyawanDD && (
                          <Form.Item
                            labelAlign='left'
                            label='Karyawan'
                            name='karyawan'
                            rules={[
                              {
                                required: true,
                                message: 'Bagian ini wajib diisi',
                              },
                            ]}>
                            <Select
                              mode='multiple'
                              style={{ width: '100%' }}
                              autoComplete='off'
                              showSearch
                              placeholder='Cari karyawan'
                              optionFilterProp='label'
                              onChange={onChange}
                              onFocus={onFocus}
                              onBlur={onBlur}
                              onSearch={onSearch}
                              // filterOption={(input, option) =>
                              //   option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                              //   0
                              // }
                            >
                              {karyawanDD.map((val, item) => (
                                <Option
                                  key={val.id}
                                  value={val.id}
                                  label={val.name}>
                                  {val.name}
                                </Option>
                              ))}
                            </Select>
                          </Form.Item>
                        )}
                      </>
                    )}
                  </>
                )}
              {!isOld && (
                <>
                  <Form.Item
                    labelAlign='left'
                    label='Username (NIP)'
                    name='username'
                    rules={[
                      {
                        required: true,
                        message: 'Bagian ini wajib diisi',
                      },
                    ]}>
                    <Input placeholder='Masukan username' />
                  </Form.Item>

                  <Form.Item
                    labelAlign='left'
                    label='Nama'
                    name='nama'
                    rules={[
                      {
                        required: true,
                        message: 'Bagian ini wajib diisi',
                      },
                    ]}>
                    <Input placeholder='Masukan nama' />
                  </Form.Item>

                  <Form.Item
                    labelAlign='left'
                    label='Email'
                    name='email'
                    rules={[
                      {
                        type: 'email',
                        required: true,
                        message: 'Bagian ini wajib diisi',
                      },
                    ]}>
                    <Input placeholder='email@uajy.com' />
                  </Form.Item>
                  {modalCon.judul === 'Ubah Karyawan / Dosen' &&
                    jabatan &&
                    user.role_kantor === 'admin' && (
                      <Form.Item
                        labelAlign='left'
                        label='Jabatan'
                        name='jabatan'
                        rules={[
                          {
                            required: true,
                            message: 'Bagian ini wajib diisi',
                          },
                        ]}>
                        <Select
                          style={{ width: '100%' }}
                          autoComplete='off'
                          showSearch
                          placeholder='Ubah Jabatan'
                          optionFilterProp='label'
                          onChange={onChange}
                          onFocus={onFocus}
                          onBlur={onBlur}
                          onSearch={onSearch}
                          // filterOption={(input, option) =>
                          //   option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                          //   0
                          // }
                        >
                          {jabatan.map((val, item) => (
                            <Option
                              key={val.id}
                              value={val.id}
                              label={val.name}>
                              {val.name}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                    )}
                </>
              )}
              {switchAdd && (
                <>
                  <Form.Item
                    labelAlign='left'
                    label='Jabatan Akademik'
                    name='jabatan_akademik'
                    rules={[
                      {
                        required: true,
                        message: 'Bagian ini wajib diisi',
                      },
                    ]}>
                    <Select placeholder='Masukan jabatan akademik'>
                      <Select.Option value='Asisten Ahli'>
                        Asisten Ahli
                      </Select.Option>
                      <Select.Option value='Lektor'>Lektor</Select.Option>
                      <Select.Option value='Lektor Kepala'>
                        Lektor Kepala
                      </Select.Option>
                      <Select.Option value='Guru Besar'>
                        Guru Besar
                      </Select.Option>
                      <Select.Option value='Tenaga Pengajar'>
                        Tenaga Pengajar
                      </Select.Option>
                    </Select>
                  </Form.Item>
                  {programStudy && (
                    <Form.Item
                      labelAlign='left'
                      label='Program Studi'
                      name='program_studi'
                      rules={[
                        {
                          required: true,
                          message: 'Bagian ini wajib diisi',
                        },
                      ]}>
                      <Select
                        style={{ width: '100%' }}
                        autoComplete='off'
                        showSearch
                        placeholder='Cari Program Studi'
                        optionFilterProp='label'
                        onChange={onChange}
                        onFocus={onFocus}
                        onBlur={onBlur}
                        onSearch={onSearch}
                        // filterOption={(input, option) =>
                        //   option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                        //   0
                        // }
                      >
                        {programStudy.map((val, item) => (
                          <Option key={val.id} value={val.id} label={val.name}>
                            {val.name}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  )}
                </>
              )}
              <Form.Item>
                <Button
                  size='small'
                  floated='right'
                  type='submit'
                  color='green'
                  loading={loading}>
                  Simpan
                </Button>

                <Button
                  negative
                  size='small'
                  floated='right'
                  type='button'
                  onClick={handleReset}
                  loading={loading}>
                  Reset
                </Button>
              </Form.Item>
            </Form>
          </Modal>
        )}
      </div>
    </div>
  );
};

export default LihatKaryawan;
