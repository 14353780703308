import React, { useContext } from 'react';
import myAxios from '../../../../myAxios';
import {
  Table,
  Space,
  Spin,
  Row,
  Col,
  message,
  Input,
  Tooltip,
  Form,
  Tag,
  Select,
  Modal,
  Image,
} from 'antd';
import { Input as InputSUI } from 'semantic-ui-react';
import { Button } from 'semantic-ui-react';
import { LoadingOutlined } from '@ant-design/icons';

import 'bootstrap/dist/css/bootstrap.min.css';
import { useState, useEffect } from 'react';
import { UserContext } from '../../../../context/UserContext';
import { useHistory, useParams } from 'react-router-dom';
import { ArrowLeftRight } from 'react-bootstrap-icons';
import { RapatContext } from '../../../../context/DataRapatContext';

const { TextArea } = Input;
const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
const tableLoading = {
  indicator: <Spin className='loading-data-table' indicator={antIcon} />,
};

const optionsDate = {
  weekday: 'long',
  year: 'numeric',
  month: 'long',
  day: 'numeric',
};

const DetailRapat = () => {
  const [user, setUser] = useContext(UserContext);
  const [
    tempData,
    setData,
    detail,
    setDetail,
    anggota,
    setAnggota,
    tempAng,
    setTempAng,
  ] = useContext(RapatContext);
  const [searchText, setSearch] = useState(null);
  // const [detail, setDetail] = useState(null);

  // const [tempData, setData] = useState(null);

  // const [anggota, setAnggota] = useState(null);
  // const [tempAng, setTempAng] = useState(null);

  const [loading, setLoading] = useState(false);
  const [loadingTab, setLoadingTab] = useState(false);

  const [loadingUnduh, setLoadingUnduh] = useState(false);
  const [loadingPresensi, setLoadingPresensi] = useState(false);

  const [modalVisible, setModal] = useState(false);
  const [modalFoto, setModalFoto] = useState(false);
  const [imageUrl, setImageUrl] = useState(null);
  const [prevImg, setImgPrev] = useState(null);

  const [idEdit, setIdEdit] = useState(null);
  const [status, setStatus] = useState(null);
  const [pagination, setPagination] = useState(null);
  const [form] = Form.useForm();
  const [formDetail] = Form.useForm();
  let { history } = useHistory;

  const { idRapat } = useParams();
  useEffect(() => {
    getRapat();
  }, []);

  const getRapat = () => {
    setLoadingTab(tableLoading);
    myAxios
      .get(`meeting-details/${idRapat}`, {
        headers: {
          Authorization: 'Bearer ' + user.token,
        },
      })
      .then((res) => {
        const data = res.data.data;
        data.meeting.date = new Date(data.meeting.date).toLocaleDateString(
          'id-ID',
          optionsDate
        );
        setData(data.meeting);
        setDetail(data.meeting);

        setAnggota(data.absensi);
        setTempAng(data.absensi);

        setLoadingTab(false);
      })
      .catch((err) => {
        if (err.response.data.message === 'unauthorized') {
          message.error('Sesi anda telah habis!');
          localStorage.removeItem('user');
          setUser(null);
        } else if (err.response.data.msg === 'Unauthorization Role') {
          message.error(err.response.data.msg);
          history.goBack();
        } else {
          message.error(err.response.data.msg);
          setDetail([]);
        }
        setLoadingTab(false);
      });
  };

  const clearFilters = () => {
    setAnggota(tempAng);
    setSearch(null);
  };

  const onImageChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      let img = event.target.files[0];
      setImageUrl(img);
      setImgPrev(URL.createObjectURL(img));
    }
  };

  const onChangeSearch = (evt) => {
    setSearch(evt.target.value);
    if (tempAng) {
      if (evt.target.value === '') {
        setAnggota(tempAng);
      } else {
        setAnggota(
          tempAng.filter((i) => {
            return (
              i.name.toLowerCase().includes(evt.target.value.toLowerCase()) ||
              i.status.toLowerCase().includes(evt.target.value.toLowerCase()) ||
              i.keterangan
                ?.toLowerCase()
                .includes(evt.target.value.toLowerCase())
            );
          })
        );
      }
    }
  };

  const handleCancel = () => {
    setModal(false);
    setModalFoto(false);
  };

  const onFinish = (values) => {
    setLoading(true);
    if (values.keterangan === undefined) values.keterangan = '-';
    let newObj = {
      id: idEdit,
      status: values.status_kehadiran,
      note: values.keterangan,
    };

    myAxios
      .put(`meeting-member`, newObj, {
        headers: {
          Authorization: 'Bearer ' + user.token,
        },
      })
      .then((res) => {
        message.success(res.data.msg);
        setLoading(false);
        setModal(false);
        form.resetFields();
        getRapat();
      })
      .catch((err) => {
        if (err.response.data.message === 'unauthorized') {
          message.error('Sesi anda telah habis!');
          localStorage.removeItem('user');
          setUser(null);
        } else if (err.response.data.msg === 'Unauthorization Role') {
          message.error(err.response.data.msg);
          history.goBack();
        } else {
          message.error(err.response.data.msg);
        }
        setLoading(false);
      });
  };
  const onFinishUpload = (values) => {
    setLoadingPresensi(true);
    const formData = new FormData();
    formData.append('file', imageUrl);
    formData.append('meeting_id', idRapat);

    myAxios
      .post(`meeting/upload-image`, formData, {
        headers: {
          Authorization: 'Bearer ' + user.token,
        },
      })
      .then((res) => {
        message.success(res.data.msg);
        setLoadingPresensi(false);
        setModalFoto(false);
        form.resetFields();
        getRapat();
      })
      .catch((err) => {
        if (err.response.data.message === 'unauthorized') {
          message.error('Sesi anda telah habis!');
          localStorage.removeItem('user');
          setUser(null);
        } else if (err.response.data.msg === 'Unauthorization Role') {
          message.error(err.response.data.msg);
          history.goBack();
        } else {
          message.error(err.response.data.msg);
        }
        setLoadingPresensi(false);
      });
  };

  const onFinishFailed = (errorInfo) => {};

  const onEditModal = (param) => {
    setModal(true);
    setStatus(param.status);
    setIdEdit(param.id);
    var keterangan = param.note;
    if (param.note === '-') keterangan = '';
    form.setFieldsValue({
      keterangan,
      status_kehadiran: param.status,
    });
  };

  const onChangeStatus = (evt) => {
    setStatus(evt);
  };

  const columns = [
    {
      title: 'Nama',
      dataIndex: 'name',
      key: 'name',
      sorter: (a, b) => a.name.length - b.name.length,
      ellipsis: true,
    },
    {
      title: 'Status Kehadiran',
      dataIndex: 'status',
      key: 'status',
      sorter: (a, b) => a.status.length - b.status.length,
      ellipsis: true,
      render: (text) =>
        text !== 'Hadir' ? (
          <Tag color='red'>{text}</Tag>
        ) : (
          <Tag color='green'>{text}</Tag>
        ),
    },
    {
      title: 'Keterangan',
      dataIndex: 'note',
      key: 'note',
      sorter: (a, b) => a.note.length - b.note.length,
      ellipsis: true,
    },

    {
      key: 'action',
      dataIndex: 'id',
      align: 'center',
      render: (dataIndex, row) => (
        <div>
          {detail && (
            <>
              {(detail.chairman_id === user.id ||
                detail.secretary_id == user.id) && (
                <Space size='middle'>
                  <Tooltip
                    placement='bottom'
                    title='Ubah Kehadiran'
                    color='#1f1f1f'
                    key='white'>
                    <Button
                      className='aksiDetail'
                      size='mini'
                      color='facebook'
                      onClick={() => onEditModal(row)}
                      icon={<ArrowLeftRight />}
                    />
                  </Tooltip>
                </Space>
              )}
            </>
          )}
        </div>
      ),
    },
  ];

  const onUnduhNotulen = (id) => {
    setLoadingUnduh(true);
    myAxios
      .get(`meeting/download-minutes/${idRapat}`, {
        headers: {
          Authorization: 'Bearer ' + user.token,
        },
        responseType: 'blob',
      })
      .then((res) => {
        const url = window.URL.createObjectURL(res.data);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `Notulen_${detail.name}.pdf`); //or any other extension
        link.target = '_blank';
        document.body.appendChild(link);
        link.click();
        setLoadingUnduh(false);
        message.success('Unduh Notulen Rapat Berhasil!');
      })
      .catch((err) => {
        if (err.response.data.message === 'unauthorized') {
          message.error('Sesi anda telah habis!');
          localStorage.removeItem('user');
          setUser(null);
        } else if (err.response.data.msg === 'Unauthorization Role') {
          message.error(err.response.data.msg);
          history.goBack();
        } else {
          message.error(err.response.data.msg);
        }
        setLoadingUnduh(false);
      });
  };

  const onUnduhPresensi = (id) => {
    setLoadingPresensi(true);
    myAxios
      .get(`meeting-member/download/${idRapat}`, {
        headers: {
          Authorization: 'Bearer ' + user.token,
        },
        responseType: 'blob',
      })
      .then((res) => {
        const url = window.URL.createObjectURL(res.data);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `Presensi_${detail.name}.pdf`); //or any other extension
        link.target = '_blank';
        document.body.appendChild(link);
        link.click();
        setLoadingPresensi(false);
        message.success('Unduh Presensi Berhasil!');
      })
      .catch((err) => {
        if (err.response.data.message === 'unauthorized') {
          message.error('Sesi anda telah habis!');
          localStorage.removeItem('user');
          setUser(null);
        } else if (err.response.data.msg === 'Unauthorization Role') {
          message.error(err.response.data.msg);
          history.goBack();
        } else {
          message.error(err.response.data.msg);
        }
        setLoadingPresensi(false);
      });
  };

  return (
    <>
      <Modal
        title='Unggah Foto Rapat'
        visible={modalFoto}
        onCancel={handleCancel}
        footer={[]}>
        <Form
          layout='vertical'
          form={form}
          name='basic'
          initialValues={{ remember: false }}
          onFinish={onFinishUpload}
          onFinishFailed={onFinishFailed}>
          <Form.Item name='photo' labelAlign='left'>
            <Row justify='space-between'>
              <Col md={10} style={{ display: 'flex', alignItems: 'center' }}>
                <input
                  style={{ marginBottom: '10px' }}
                  type='file'
                  name='myImage'
                  onChange={onImageChange}
                />
              </Col>
              <Col>
                <Image
                  name='photo'
                  // fallback={NoImg}
                  style={{
                    width: '250px',
                    height: '250px',
                    objectFit: 'cover',
                  }}
                  src={prevImg}
                />
              </Col>
            </Row>
          </Form.Item>
          <Form.Item>
            <Button
              color='green'
              type='submit'
              loading={loadingPresensi}
              style={{ width: '100%' }}>
              Unggah
            </Button>
          </Form.Item>
        </Form>
      </Modal>
      <Modal
        title='Ubah Kehadiran'
        visible={modalVisible}
        onCancel={handleCancel}
        footer={[]}>
        <Form
          layout='vertical'
          form={form}
          name='basic'
          initialValues={{ remember: false }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}>
          <Form.Item
            labelAlign='left'
            label='Status Kehadiran'
            name='status_kehadiran'>
            <Select onChange={onChangeStatus}>
              <Select.Option value='Hadir'>Hadir</Select.Option>
              <Select.Option value='Tidak Hadir'>Tidak Hadir</Select.Option>
            </Select>
          </Form.Item>

          {status === 'Tidak Hadir' && (
            <Form.Item labelAlign='left' label='Keterangan' name='keterangan'>
              <TextArea placeholder='Keterangan tidak hadir' rows={7} />
            </Form.Item>
          )}
          <Form.Item>
            <Button
              style={{ width: '100%' }}
              type='primary'
              color='green'
              htmlType='submit'
              loading={loading}>
              Simpan
            </Button>
          </Form.Item>
        </Form>
      </Modal>
      <div className='scrollable'>
        <Row justify='space-between'>
          <Col md={9} sm={24}>
            <div className=''>
              {!detail && (
                <>
                  <h1 className='loading-data'>
                    <Spin indicator={antIcon} />
                    <p>Mengambil data ...</p>
                  </h1>
                </>
              )}
              {detail && (
                <div className='data-rapat'>
                  <div className='component-data'>
                    <label>Nama Rapat</label>
                    <Input value={detail.name} disabled />
                  </div>
                  <div className='component-data'>
                    <label>Ketua</label>
                    <Input value={detail.ketua} disabled />
                  </div>
                  <div className='component-data'>
                    <label>Notulen</label>
                    <Input value={detail.notulen} disabled />
                  </div>
                  <div className='component-data'>
                    <label>Tanggal Rapat</label>
                    <Input value={detail.date} disabled />
                  </div>
                  <div className='component-data'>
                    <label>Ruang Rapat</label>
                    <Input value={detail.room} disabled />
                  </div>
                  <div className='component-data'>
                    <label>Status Rapat</label>
                    <Input value={detail.status} disabled />
                  </div>
                  <Row justify='space-between'>
                    <Col md={11} xs={11}>
                      <div className='component-data'>
                        <label>Jam Mulai</label>
                        <Input value={detail.start_time} disabled />
                      </div>
                    </Col>
                    <Col md={11} xs={11}>
                      <div className='component-data'>
                        <label>Jam Selesai</label>
                        <Input value={detail.end_time} disabled />
                      </div>
                    </Col>
                    {detail.link && (
                      <>
                        <Col md={24}>
                          <div className='component-data'>
                            <label>Link Rapat :</label>
                            <TextArea disabled rows={5} value={detail.link} />
                          </div>
                        </Col>
                        <Col md={24}>
                          <div className='component-data'>
                            <Button
                              loading={loadingUnduh}
                              onClick={() => {
                                navigator.clipboard.writeText(detail.link);
                                message.success('Berhasil menyalin link');
                              }}
                              color='green'>
                              Salin Link
                            </Button>
                            <Button loading={loadingUnduh} color='green'>
                              <a
                                href={detail.link}
                                target='_blank'
                                style={{
                                  textDecoration: 'none',
                                  color: 'white',
                                }}>
                                Kunjungi Link
                              </a>
                            </Button>
                          </div>
                        </Col>
                      </>
                    )}
                    <Col md={24}>
                      <div className='component-data'>
                        <label>Foto Rapat :</label>
                        <br />
                        <Image
                          name='photo'
                          // fallback={NoImg}
                          style={{
                            width: '150px',
                            height: '150px',
                            objectFit: 'cover',
                          }}
                          src={`https://rapat-ars-api.uajy.ac.id/file/${detail.image}`}
                        />
                      </div>
                    </Col>
                    {detail.status === 'Selesai' && (
                      <Col md={24}>
                        <div className='component-data'>
                          <Button
                            style={{ width: '100%' }}
                            loading={loadingUnduh}
                            onClick={() => onUnduhNotulen(detail.id)}
                            color='green'>
                            Unduh Notulen
                          </Button>
                        </div>
                      </Col>
                    )}
                  </Row>
                </div>
              )}
            </div>
          </Col>
          <Col md={14} sm={24}>
            <div className='' style={{ minHeight: '521.75px' }}>
              <Row justify='space-between' style={{ marginBottom: '20px' }}>
                <Col md={12} sm={24}>
                  {detail && (
                    <Space>
                      {/* <Button
                      secondary
                      className='btn-hapus-filter'
                      type='danger'
                      onClick={clearFilters}>
                      Hapus Filter
                    </Button> */}
                      {detail.status === 'Selesai' && (
                        <Button
                          color='green'
                          loading={loadingPresensi}
                          onClick={() => onUnduhPresensi(detail.id)}>
                          Presensi
                        </Button>
                      )}
                      <Button
                        color='green'
                        loading={loadingPresensi}
                        onClick={() => setModalFoto(true)}>
                        Unggah Foto
                      </Button>
                    </Space>
                  )}
                </Col>
                <Col
                  md={12}
                  sm={24}
                  style={{ width: '100%' }}
                  className='mt-mobile'>
                  <Input
                    value={searchText}
                    style={{ width: '100%' }}
                    placeholder='Cari data absensi disini'
                    onChange={onChangeSearch}
                  />
                </Col>
              </Row>

              <Table
                editable={true}
                loading={loadingTab}
                loadingIndicator={antIcon}
                columns={columns}
                dataSource={anggota}
                scroll={{ x: 400, y: 367 }}
                size='small'
              />
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default DetailRapat;
