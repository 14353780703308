import React, { useContext } from 'react';
import myAxios from '../../../myAxios';
import {
  Table,
  Space,
  Spin,
  Row,
  Col,
  message,
  Input,
  Tooltip,
  Popconfirm,
  Form,
  Upload,
  Modal,
  Button as BA,
} from 'antd';
import { Button } from 'semantic-ui-react';
import { LoadingOutlined } from '@ant-design/icons';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useState, useEffect } from 'react';
import { UserContext } from '../../../context/UserContext';
import { useHistory, useParams } from 'react-router-dom';

import { CloudArrowUp } from 'react-bootstrap-icons';
import { TimContext } from '../../../context/TimVerifikasiContext';

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
const tableLoading = {
  indicator: <Spin className='loading-data-table' indicator={antIcon} />,
};

const Dokumen = () => {
  const [user, setUser] = useContext(UserContext);
  const [tim, updateTim] = useContext(TimContext);

  const [dokumen, setDokumen] = useState(null);
  const [tempData, setData] = useState(null);

  const [searchText, setSearch] = useState(null);

  const [loading, setLoading] = useState(false);
  const [loadingAct, setLoadingAct] = useState(false);
  const [loadingMinute, setLoadingM] = useState(false);

  const [modalVisible, setModal] = useState(false);

  const [form] = Form.useForm();
  let { history } = useHistory;
  const { idTim } = useParams();

  const optionsDate = {
    weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  };

  const optionTime = {
    hour: '2-digit',
    minute: '2-digit',
  };

  useEffect(() => {
    if (!dokumen) {
      getDokumen();
    }
  }, [dokumen]);

  const getDokumen = () => {
    setLoading(tableLoading);
    myAxios
      .get(`/team-decree/${idTim}`, {
        headers: {
          Authorization: 'Bearer ' + user.token,
        },
      })
      .then((res) => {
        const data = res.data.data.data;
        data.map((e) => {
          e.jam_upload = new Date(e.created_at).toLocaleTimeString(
            'id-ID',
            optionTime
          );
          e.created_at = new Date(e.created_at).toLocaleDateString(
            'id-ID',
            optionsDate
          );
        });
        setLoading(false);
        setDokumen(data);
        setData(data);
      })
      .catch((err) => {
        if (err.response.data.message === 'unauthorized') {
          message.error('Sesi anda telah habis!');
          localStorage.removeItem('user');
          setUser(null);
        } else if (err.response.data.msg === 'Unauthorization Role') {
          message.error(err.response.data.msg);
          history.goBack();
        } else {
          message.error(err.response.data.msg);
        }
        setDokumen([]);
        setLoading(false);
      });
  };

  const onHapus = (param) => {
    setLoadingAct(true);

    myAxios
      .delete(`team-decree/${param}`, {
        headers: {
          Authorization: 'Bearer ' + user.token,
        },
      })
      .then((res) => {
        getDokumen();
        message.success(res.data.msg);
        setLoadingAct(false);
      })
      .catch((err) => {
        if (err.response.data.message === 'unauthorized') {
          message.error('Sesi anda telah habis!');
          localStorage.removeItem('user');
          setUser(null);
        } else if (err.response.data.msg === 'Unauthorization Role') {
          message.error(err.response.data.msg);
          history.goBack();
        } else {
          message.error(err.response.data.msg);
        }
        setLoading(false);
      });
  };

  const clearFilters = () => {
    setSearch(null);
  };

  const onChangeSearch = (evt) => {
    setSearch(evt.target.value);
    if (tempData) {
      if (evt.target.value === '') {
        setDokumen(tempData);
      } else {
        setDokumen(
          tempData.filter((i) => {
            return (
              i.nama_asli
                .toLowerCase()
                .includes(evt.target.value.toLowerCase()) ||
              i.name.toLowerCase().includes(evt.target.value.toLowerCase()) ||
              i.created_at
                .toLowerCase()
                .includes(evt.target.value.toLowerCase())
            );
          })
        );
      }
    }
  };

  const handleCancel = () => {
    setModal(false);
  };

  const onFinish = (values) => {
    setLoadingM(true);

    const formData = new FormData();

    let tempFile = values.dokumen_rapat.fileList;
    tempFile.forEach((e) => {
      formData.append('filelist[]', e.originFileObj);
    });
    formData.append('team_id', idTim);

    myAxios
      .post(`team-decree`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: 'Bearer ' + user.token,
        },
      })
      .then((res) => {
        updateTim();
        message.success(res.data.msg);
        setLoadingM(false);
        setModal(false);
        form.resetFields();
        getDokumen();
      })
      .catch((err) => {
        if (err.response.data.message === 'unauthorized') {
          message.error('Sesi anda telah habis!');
          localStorage.removeItem('user');
          setUser(null);
        } else if (err.response.data.msg === 'Unauthorization Role') {
          message.error(err.response.data.msg);
          history.goBack();
        } else {
          message.error(err.response.data.msg);
        }
        setLoadingM(false);
      });
  };

  const onUnduhDokumen = (param) => {
    setLoadingAct(true);

    myAxios
      .get(`download-team-decree/${param.id}`, {
        headers: {
          Authorization: 'Bearer ' + user.token,
        },
        responseType: 'blob',
      })
      .then((res) => {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', param.filename);
        // link.target = "_blank";
        document.body.appendChild(link);
        link.click();
        setLoadingAct(false);
      })
      .catch((err) => {
        if (err.response.data.message === 'unauthorized') {
          message.error('Sesi anda telah habis!');
          localStorage.removeItem('user');
          setUser(null);
        } else if (err.response.data.msg === 'Unauthorization Role') {
          message.error(err.response.data.msg);
          history.goBack();
        } else {
          message.error(err.response.data.msg);
        }
        setLoadingAct(false);
      });
  };

  const columns = [
    {
      title: 'Nama Dokumen',
      dataIndex: 'filename',
      key: 'filename',
      sorter: (a, b) => a.filename.length - b.filename.length,
      ellipsis: true,
    },
    {
      title: 'Tanggal Upload',
      dataIndex: 'created_at',
      key: 'created_at',
      sorter: (a, b) => a.created_at.length - b.created_at.length,
      ellipsis: true,
    },
    {
      title: 'Jam Upload',
      dataIndex: 'jam_upload',
      key: 'jam_upload',
      sorter: (a, b) => a.jam_upload.length - b.jam_upload.length,
      ellipsis: true,
    },
    {
      key: 'action',
      dataIndex: 'id',
      align: 'center',
      render: (dataIndex, text) => (
        <div>
          {!loadingAct && (
            <Space size='middle'>
              {(user.role_kantor === 'admin' ||
                user.role_unit === 'kepala_tu') && (
                <>
                  <Tooltip
                    placement='bottom'
                    title='Unduh Dokumen'
                    color='#1f1f1f'
                    key='white'>
                    <Button
                      color='facebook'
                      className='aksiEdit'
                      size='mini'
                      onClick={() => onUnduhDokumen(text)}
                      icon='download'
                    />
                  </Tooltip>

                  <Tooltip
                    placement='bottom'
                    title='Hapus Dokumen'
                    color='#1f1f1f'
                    key='white'>
                    <Popconfirm
                      placement='bottomRight'
                      title='Apakah yakin ingin menghapus dokumen ?'
                      onConfirm={() => onHapus(dataIndex)}
                      okText='Hapus'
                      cancelText='Batal'>
                      <Button
                        negative
                        className='aksiHapus'
                        size='mini'
                        icon='trash'
                      />
                    </Popconfirm>
                  </Tooltip>
                </>
              )}
            </Space>
          )}
          {loadingAct && (
            <Spin className='loading-data-table' indicator={antIcon} />
          )}
        </div>
      ),
    },
  ];

  const onChangeDokumen = ({ file, fileList }) => {
    if (file.catatan !== 'uploading') {
      //
    }
  };

  return (
    <>
      <Row justify='space-between'>
        <Col md={9} sm={24} className='width-100'>
          <div className=''>
            {(user.role_kantor === 'admin' ||
              user.role_unit === 'kepala_tu') && (
              <Form
                style={{ width: '100%' }}
                form={form}
                layout='vertical'
                onFinish={onFinish}
                autoComplete='off'>
                <Form.Item name='dokumen_rapat'>
                  <Upload
                    maxCount={1}
                    onChange={onChangeDokumen}
                    beforeUpload={(file) => {
                      const reader = new FileReader();

                      reader.onload = (e) => {
                        //
                      };
                      reader.readAsText(file);

                      return false;
                    }}>
                    <BA className='tambah-dokumen' type='dashed'>
                      <div className='logo-upload'>
                        <CloudArrowUp />
                      </div>
                      <p>Seret dan lepas untuk mengunggah dokumen</p>
                      <p>atau</p>
                      <p>
                        <b> Klik untuk pilih dokumen</b>
                      </p>
                    </BA>
                  </Upload>
                </Form.Item>

                <Form.Item>
                  <Button
                    floated='right'
                    size='small'
                    color='green'
                    type='submit'
                    loading={loadingMinute}>
                    Unggah
                  </Button>
                  <Button
                    floated='right'
                    size='small'
                    negative
                    type='button'
                    loading={loadingMinute}>
                    Reset
                  </Button>
                </Form.Item>
              </Form>
            )}
          </div>
        </Col>
        <Col
          md={
            user.role_kantor === 'admin' || user.role_unit === 'kepala_tu'
              ? 14
              : 24
          }
          sm={24}>
          <div className='' style={{ minHeight: '500px' }}>
            <Row justify='space-between' style={{ marginBottom: '20px' }}>
              <Col md={12} sm={24}>
                <Space>
                  <Button
                    secondary
                    className='btn-hapus-filter'
                    type='danger'
                    onClick={clearFilters}>
                    Hapus Filter
                  </Button>
                </Space>
              </Col>
              <Col md={12} sm={24} style={{ width: '100%' }}>
                <Input
                  value={searchText}
                  style={{ width: '100%' }}
                  placeholder='Cari dokumen SK disini'
                  onChange={onChangeSearch}
                />
              </Col>
            </Row>

            <Table
              loading={loading}
              loadingIndicator={antIcon}
              columns={columns}
              dataSource={dokumen}
              scroll={{ x: 200, y: 575 }}
              size='small'
            />
          </div>
        </Col>
      </Row>
      <Modal
        title='Ubah Minute Meeting'
        visible={modalVisible}
        onCancel={handleCancel}
        footer={[]}></Modal>
    </>
  );
};

export default Dokumen;
