import React, { useState, createContext, useContext } from 'react';
import myAxios from '../myAxios';
import { message } from 'antd';
import { UserContext } from './UserContext';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
export const TimContext = createContext();

export const TimProvider = (props) => {
  const [user, setUser] = useContext(UserContext);
  const [tim, setTim] = useState(0);
  let { history } = useHistory;
  useEffect(() => {
    if (!tim) getCountTim();
  }, [user]);

  const updateTim = async () => {
    await getCountTim();
    return tim;
  };

  const getCountTim = async () => {
    setTim(0);
    if (user) {
      if (user.role_unit === 'kepala_tu' || user.role_kantor === 'admin') {
        await myAxios
          .get(`get-count-new-team`, {
            headers: {
              Authorization: 'Bearer ' + user.token,
            },
          })
          .then((res) => {
            if (res.data.data != 0) {
              setTim(Number(res.data.data));
            } else {
              setTim(0);
            }
          })
          .catch((err) => {
            if (err.response.data.message === 'unauthorized') {
              message.error('Sesi anda telah habis!');
              localStorage.removeItem('user');
              setUser(null);
            } else if (err.response.data.msg === 'Unauthorization Role') {
              message.error(err.response.data.msg);
              history.goBack();
            } else {
              console.log(err.response.data.message);
            }
          });
      }
    }
  };

  return (
    <TimContext.Provider value={[tim, updateTim]}>
      {props.children}
    </TimContext.Provider>
  );
};
