import React, { useState, useContext, useEffect } from 'react';
import './MenuCustom.css';
import { Menu, message } from 'antd';
import { Link, useLocation } from 'react-router-dom';
import { UserContext } from '../context/UserContext';
import myAxios from '../myAxios';

import {
  PersonFill,
  PeopleFill,
  Diagram3Fill,
  ClipboardMinus,
  BoxArrowLeft,
  JournalText,
  Stack,
  PersonLinesFill,
  Search,
  Building,
  GeoAlt,
  Wrench,
  BriefcaseFill,
  InfoSquare,
  ListTask,
} from 'react-bootstrap-icons';

import ComponentNew from './ComponentNew';
import { PenugasanContext } from '../context/PenugasanContext';
import { TimContext } from '../context/TimVerifikasiContext';

const MenuCustom = () => {
  const [cekLogout, setLogout] = useState(false);
  const [penugasan] = useContext(PenugasanContext);
  const [defKey, setKey] = useState(null);
  const [user, setUser] = useContext(UserContext);
  const [tim] = useContext(TimContext);
  let Loc = useLocation();

  useEffect(() => {
    if (user) {
      let location = Loc.pathname;
      let tempPath = location.split('/', 2);
      let pathname = tempPath[1];
      setKey(pathname ? pathname : '/');
    }
  }, [Loc.pathname]);

  const Logout = () => {
    if (cekLogout === false) {
      message.success('Berhasil keluar!');
      localStorage.removeItem('user');
      localStorage.removeItem('tab');
      setUser(null);
      setLogout(false);
    }
  };

  return (
    <div>
      {/* MENU SUPERADMIN  */}
      {user.role === 'superadmin' && (
        <>
          {defKey && (
            <Menu theme='dark' mode='inline' defaultSelectedKeys={[defKey]}>
              <Menu.Item key='/' icon={<PersonFill />} style={{ margin: 0 }}>
                <Link className='link' to='/'>
                  Profil
                </Link>
              </Menu.Item>

              <Menu.Item key='kantor' icon={<Building />}>
                <Link className='link' to='/kantor'>
                  Kantor
                </Link>
              </Menu.Item>

              <Menu.Item key='unit' icon={<Building />}>
                <Link className='link' to='/unit'>
                  Unit
                </Link>
              </Menu.Item>

              <Menu.Item key='lokasi' icon={<GeoAlt />}>
                <Link className='link' to='/lokasi'>
                  Lokasi
                </Link>
              </Menu.Item>

              <Menu.Item key='kelolaRuang' icon={<Diagram3Fill />}>
                <Link className='link' to='/kelolaRuang'>
                  Ruang
                </Link>
              </Menu.Item>
            </Menu>
          )}
        </>
      )}

      {/* MENU !SUPERADMIN */}
      {defKey && (
        <>
          {user.role !== 'superadmin' && (
            <Menu theme='dark' mode='inline' defaultSelectedKeys={[defKey]}>
              <Menu.Item
                key='profilUser'
                icon={<PersonFill />}
                style={{ margin: 0 }}>
                <Link className='link' to='/profilUser'>
                  Profil
                </Link>
              </Menu.Item>
              {user.role_unit === 'kadep' ||
              user.role_unit === 'dekan' ||
              user.role_unit === 'kepala_tu' ||
              user.role_kantor === 'kepalakantor' ? (
                <Menu.Item key='tamu' icon={<PersonLinesFill />}>
                  <Link className='link' to='/tamu'>
                    Tamu
                  </Link>
                </Menu.Item>
              ) : (
                <Menu.Item key='tamuSaran' icon={<PersonLinesFill />}>
                  <Link className='link' to='/tamuSaran'>
                    Tamu
                  </Link>
                </Menu.Item>
              )}
              {(user.role_kantor === 'admin' ||
                user.role_unit === 'kepala_tu') && (
                <Menu.Item key='karyawan' icon={<PeopleFill />}>
                  <Link className='link' to='/karyawan'>
                    Karyawan
                  </Link>
                </Menu.Item>
              )}
              {user.role_kantor === 'admin' && (
                <Menu.Item key='jabatan' icon={<Diagram3Fill />}>
                  <Link className='link' to='/jabatan'>
                    Jabatan
                  </Link>
                </Menu.Item>
              )}
              <Menu.Item key='tim' icon={<ClipboardMinus />}>
                <Link className='link' to='/tim'>
                  Tim
                  {tim > 0 && <ComponentNew status='Tim' />}
                </Link>
              </Menu.Item>
              <Menu.Item key='tugas' icon={<JournalText />}>
                <Link className='link' to='/tugas'>
                  Tugas
                  {penugasan > 0 && <ComponentNew status='Tugas' />}
                </Link>
              </Menu.Item>
              <Menu.Item key='rapatTerdekat' icon={<Stack />}>
                <Link className='link' to='/rapatTerdekat'>
                  Rapat
                </Link>
              </Menu.Item>
              <Menu.Item key='infoRuang' icon={<InfoSquare />}>
                <Link className='link' to='/infoRuang'>
                  Info Ruang
                </Link>
              </Menu.Item>
              {user.role_unit === 'kadep' && (
                <Menu.Item key='infoRapat' icon={<ListTask />}>
                  <Link className='link' to='/infoRapat'>
                    Info Rapat
                  </Link>
                </Menu.Item>
              )}
              <Menu.Item key='pencarian' icon={<Search />}>
                <Link className='link' to='/pencarian'>
                  Pencarian
                </Link>
              </Menu.Item>
            </Menu>
          )}
        </>
      )}
      {defKey && (
        <Menu theme='dark' mode='inline' defaultSelectedKeys={[defKey]}>
          <Menu.Item key='6' icon={<BoxArrowLeft />} style={{ margin: 0 }}>
            <Link className='link' onClick={Logout}>
              Keluar
            </Link>
          </Menu.Item>
        </Menu>
      )}
    </div>
  );
};

export default MenuCustom;
