import React, { useState, createContext, useContext } from 'react';
import myAxios from '../myAxios';
import { message } from 'antd';
import { UserContext } from './UserContext';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
export const TamuContext = createContext();

export const TamuProvider = (props) => {
  const [user, setUser] = useContext(UserContext);
  const [tamu, setTamu] = useState(null);
  const [instance, setInstance] = useState(null);
  const [idInstance, setIdInstance] = useState(null);
  let { history } = useHistory;
  // useEffect(() => {
  //   // if (!tamu) {
  //   // getInstance(idIns);
  //   const timeOutId = setTimeout(() => getTamu(), 500);
  //   return () => clearTimeout(timeOutId);
  //   // }
  // }, [idInstance]);

  const updateTamu = async (idIns) => {
    await getTamu(undefined, '1', undefined, 'ASC', idIns);
    return tamu;
  };

  const getTamu = async (
    search = undefined,
    page = '1',
    sortBy = undefined,
    sorting = 'ASC',
    idIns
  ) => {
    myAxios
      .get(`guests/${idIns}`, {
        params: {
          type: 'verified',
          search: search,
          show: '10',
          sortBy: sortBy,
          sorting: sorting,
        },
        headers: {
          Authorization: 'Bearer ' + user.token,
        },
      })
      .then((res) => {
        const data = res.data.data.data;
        setTamu(data);
      })
      .catch((err) => {
        if (err.response.data.message === 'unauthorized') {
          message.error('Sesi anda telah habis!');
          localStorage.removeItem('user');
          setUser(null);
        } else if (err.response.data.msg === 'Unauthorization Role') {
          message.error(err.response.data.msg);
          history.goBack();
        } else {
          message.error(err.response.data.msg);
        }
      });
  };

  const getInstance = async () => {
    await myAxios
      .get(`guests-instance`, {
        headers: {
          Authorization: 'Bearer ' + user.token,
        },
      })
      .then((res) => {
        const data = res.data.data;

        if (data.length > 0) {
          if (!idInstance) setIdInstance(data[0].id);
          setInstance(data);
        } else {
          setInstance([]);
        }

        // setLoadingAdmin(false);
      })
      .catch((err) => {
        if (err.response.data.message === 'unauthorized') {
          message.error('Sesi anda telah habis!');
          localStorage.removeItem('user');
        } else if (err.response.data.msg === 'Unauthorization Role') {
          message.error(err.response.data.msg);
          history.goBack();
        } else {
          message.error(err.response.data.msg);
        }
        // setLoadingAdmin(false);
      });
  };

  return (
    <TamuContext.Provider value={[tamu, updateTamu, setTamu]}>
      {props.children}
    </TamuContext.Provider>
  );
};
