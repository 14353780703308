import React from 'react';
import { Icon } from 'semantic-ui-react';
import { motion } from 'framer-motion';
const InfoBanner = () => {
  const container = {
    hidden: { opacity: 0 },
    show: {
      opacity: 1,
      transition: {
        delayChildren: 0.5,
        staggerDirection: -10,
      },
    },
  };
  return (
    <motion.div
      variants={container}
      initial='hidden'
      animate='show'
      className='container-info'>
      <Icon name='attention' className='attention-icon' />
      Silahkan arahkan kursor pada kartu tim untuk masuk ke sub menu.
    </motion.div>
  );
};

export default InfoBanner;
