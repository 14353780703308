import React, { useContext } from 'react';
import myAxios from '../../../myAxios';
import {
  Table,
  Space,
  Spin,
  Row,
  Col,
  message,
  Tooltip,
  Popconfirm,
  Form,
  Modal,
  Tag,
  Pagination,
  Dropdown,
  Select,
  Input,
  Menu,
} from 'antd';
import { Button, Icon } from 'semantic-ui-react';
import { LoadingOutlined } from '@ant-design/icons';

import { useState, useEffect } from 'react';
import { UserContext } from '../../../context/UserContext';
import { TamuContext } from '../../../context/TamuContext';
import { useHistory } from 'react-router-dom';

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
const tableLoading = {
  indicator: <Spin className='loading-data-table' indicator={antIcon} />,
};
const { Option } = Select;
const TamuSaran = () => {
  const [user, setUser] = useContext(UserContext);
  const [tamu, updateTamu] = useContext(TamuContext);
  const [saranTamu, setSaranTamu] = useState(null);
  const [modalCon, setModalCon] = useState(null);
  const [searchText, setSearch] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loadingT, setLoadingT] = useState(false);
  const [loadingAct, setLoadingAct] = useState(false);
  const [modalVisible, setModal] = useState(false);
  const [pagination, setPagination] = useState(null);
  const [instance, setInstance] = useState(null);
  const [idInstance, setIdInstance] = useState(null);
  const [page, setPage] = useState('10');
  let { history } = useHistory;
  const [form] = Form.useForm();

  useEffect(() => {
    setLoadingT(tableLoading);
    const timeOutId = setTimeout(
      () => getTamu(searchText, null, null, null, page),
      500
    );
    return () => clearTimeout(timeOutId);
  }, [searchText, idInstance]);

  const getTamu = (
    search = undefined,
    page = '1',
    sortBy = undefined,
    sorting = 'ASC',
    show = '10'
  ) => {
    setLoading(tableLoading);
    myAxios
      .get(`guests-suggestions`, {
        params: {
          page: Number(page),
          search: search,
          show: show,
          sortBy: sortBy,
          sorting: sorting,
        },
        headers: {
          Authorization: 'Bearer ' + user.token,
        },
      })
      .then((res) => {
        const data = res.data.data.data;
        setLoading(false);
        setSaranTamu(data);
        setPagination({
          total: res.data.data.total,
          current: res.data.data.current_page,
          perPage: res.data.data.per_page,
        });
        setLoadingT(false);
      })
      .catch((err) => {
        if (err.response.data.message === 'unauthorized') {
          message.error('Sesi anda telah habis!');
          localStorage.removeItem('user');
          setUser(null);
        } else if (err.response.data.msg === 'Unauthorization Role') {
          message.error(err.response.data.msg);
          history.goBack();
        } else {
          message.error(err.response.data.msg);
        }
        setLoading(false);
      });
  };

  const clearFilters = () => {
    setSearch('');
  };

  const onChange = () => {};
  const onFocus = () => {};
  const onBlur = () => {};
  const onSearch = () => {};

  const onChangeSearch = (evt) => {
    setSearch(evt.target.value);
  };
  const onAccept = (param) => {
    setLoadingAct(true);
    myAxios
      .put(`accept-guest/${param}`, null, {
        headers: {
          Authorization: 'Bearer ' + user.token,
        },
      })
      .then((res) => {
        getTamu();
        updateTamu(saranTamu[0].guestable_id);
        message.success(res.data.msg);
        setLoadingAct(false);
      })
      .catch((err) => {
        if (err.response.data.message === 'unauthorized') {
          message.error('Sesi anda telah habis!');
          localStorage.removeItem('user');
          setUser(null);
        } else if (err.response.data.msg === 'Unauthorization Role') {
          message.error(err.response.data.msg);
          history.goBack();
        } else {
          message.error(err.response.data.msg);
        }
        setLoadingAct(false);
      });
  };

  const onDecline = (param) => {
    setLoadingAct(true);
    myAxios
      .put(`decline-guest/${param}`, null, {
        headers: {
          Authorization: 'Bearer ' + user.token,
        },
      })
      .then((res) => {
        getTamu();
        message.success(res.data.msg);
        setLoadingAct(false);
      })
      .catch((err) => {
        if (err.response.data.message === 'unauthorized') {
          message.error('Sesi anda telah habis!');
          localStorage.removeItem('user');
          setUser(null);
        } else if (err.response.data.msg === 'Unauthorization Role') {
          message.error(err.response.data.msg);
          history.goBack();
        } else {
          message.error(err.response.data.msg);
        }
        setLoadingAct(false);
      });
  };

  const onFinish = (values) => {
    setLoading(true);
    if (modalCon.judul === 'Tambah Tamu') {
      if (values.nip === undefined || values.nip === '') values.nip = '-';
      if (values.institusi === undefined || values.institusi === '')
        values.institusi = '-';

      let newObj = {
        name: values.nama,
        email: values.email,
        nip: values.nip,
        type_id: instance.filter((e) => {
          return e.type === values.instance;
        })[0].id,
        instansi: values.instansi,
      };

      myAxios
        .post(`guest`, newObj, {
          headers: {
            Authorization: 'Bearer ' + user.token,
          },
        })
        .then((res) => {
          message.success(res.data.msg);
          setLoading(false);
          setModal(false);
          form.resetFields();
          getTamu();
        })
        .catch((err) => {
          if (err.response.data.message === 'unauthorized') {
            message.error('Sesi anda telah habis!');
            localStorage.removeItem('user');
            setUser(null);
          } else if (err.response.data.msg === 'Unauthorization Role') {
            message.error(err.response.data.msg);
            history.goBack();
          } else {
            message.error(err.response.data.msg);
          }
          setLoading(false);
        });
    } else {
      if (values.nip === undefined || values.nip === '') values.nip = '-';

      let newObj = {
        name: values.nama,
        email: values.email,
        nip: values.nip,
      };

      myAxios
        .put(`guest/${modalCon.id}`, newObj, {
          headers: {
            Authorization: 'Bearer ' + user.token,
          },
        })
        .then((res) => {
          message.success(res.data.msg);
          setLoading(false);
          setModal(false);
          form.resetFields();
          getTamu();
        })
        .catch((err) => {
          if (err.response.data.message === 'unauthorized') {
            message.error('Sesi anda telah habis!');
            localStorage.removeItem('user');
            setUser(null);
          } else if (err.response.data.msg === 'Unauthorization Role') {
            message.error(err.response.data.msg);
            history.goBack();
          } else {
            message.error(err.response.data.msg);
          }
          setLoading(false);
        });
    }
  };

  const onEditModal = (param) => {
    setModal(true);
    setModalCon({
      judul: 'Ubah Tamu',
      id: param.id,
    });

    form.setFieldsValue({
      nama: param.name,
      email: param.email,
      nip: param.nip,
    });
  };

  const onUbahStatusTamu = (param) => {
    setLoadingAct(true);
    myAxios
      .delete(`guest/${param}`, {
        headers: {
          Authorization: 'Bearer ' + user.token,
        },
      })
      .then((res) => {
        message.success(res.data.msg);
        setLoadingAct(false);
        setModal(false);
        form.resetFields();
        getTamu();
      })
      .catch((err) => {
        if (err.response.data.message === 'unauthorized') {
          message.error('Sesi anda telah habis!');
          localStorage.removeItem('user');
          setUser(null);
        } else if (err.response.data.msg === 'Unauthorization Role') {
          message.error(err.response.data.msg);
          history.goBack();
        } else {
          message.error(err.response.data.msg);
        }
        setLoadingAct(false);
      });
  };

  const onChangePagination = (evt, show) => {
    setPage(show);
    getTamu('', evt, null, null, show);
  };

  const columns = [
    {
      title: 'Nama',
      dataIndex: 'name',
      key: 'name',
      sorter: (a, b) => a.name.length - b.name.length,
      ellipsis: true,
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      // sorter: (a, b) => a.email.length - b.email.length,
      ellipsis: true,
    },
    {
      title: 'Institusi',
      dataIndex: 'institution',
      key: 'institution',
      sorter: (a, b) => a.institution.length - b.institution.length,
      ellipsis: true,
    },
    {
      title: 'Nomor Induk Pegawai',
      dataIndex: 'nip',
      key: 'nip',
      sorter: (a, b) => a.nip.length - b.nip.length,
      ellipsis: true,
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      sorter: (a, b) => a.status.length - b.status.length,
      render: (dataIndex) => (
        <Tag color={dataIndex === 'Terverifikasi' ? 'green' : 'red'}>
          {dataIndex}
        </Tag>
      ),
    },

    {
      key: 'action',
      dataIndex: 'id',
      align: 'center',
      render: (dataIndex, row) => (
        <div>
          <>
            {!loadingAct && (
              <Space size='middle'>
                <Tooltip
                  placement='bottom'
                  title='Verifikasi Tamu'
                  color='#1f1f1f'
                  key='white'>
                  <Popconfirm
                    placement='bottomRight'
                    title='Apakah yakin ingin memverivikasi tamu ?'
                    onConfirm={() => onAccept(dataIndex)}
                    okText='Verifikasi'
                    cancelText='Batal'>
                    <Button
                      className='aksiEdit'
                      color='facebook'
                      size='mini'
                      icon='check'></Button>
                  </Popconfirm>
                </Tooltip>
                <Tooltip
                  placement='bottom'
                  title='Tolak Tamu'
                  color='#1f1f1f'
                  key='white'>
                  <Popconfirm
                    placement='bottomRight'
                    title='Apakah yakin ingin menolak tamu ?'
                    onConfirm={() => onDecline(dataIndex)}
                    okText='Tolak'
                    cancelText='Batal'>
                    <Button
                      className='aksiHapus'
                      negative
                      icon='ban'
                      size='mini'></Button>
                  </Popconfirm>
                </Tooltip>
              </Space>
            )}
            {loadingAct && (
              <Spin className='loading-data-table' indicator={antIcon} />
            )}
          </>
        </div>
      ),
    },
  ];

  const columnsAnggota = [
    {
      title: 'Nama',
      dataIndex: 'name',
      key: 'name',
      sorter: (a, b) => a.name.length - b.name.length,
      ellipsis: true,
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      sorter: (a, b) => a.email.length - b.email.length,
      ellipsis: true,
    },
    {
      title: 'Nomor Induk Pegawai',
      dataIndex: 'nip',
      key: 'nip',
      sorter: (a, b) => a.nip.length - b.nip.length,
      ellipsis: true,
    },

    {
      title: 'Status',
      dataIndex: 'role',
      key: 'role',
      sorter: (a, b) => a.role.length - b.role.length,
      render: (dataIndex) =>
        dataIndex === 'Tamu' ? (
          <Tag color='green'>Terverifikasi</Tag>
        ) : (
          <Tag color='red'>Belum Terverifikasi</Tag>
        ),
    },

    {
      key: 'action',
      dataIndex: 'id',
      align: 'center',
      render: (dataIndex, row) => (
        <div>
          {user.role === 'Admin' && (
            <>
              {!loadingAct && (
                <Space size='middle'>
                  <Tooltip
                    placement='bottom'
                    title='Ubah Data Tamu'
                    color='#1f1f1f'
                    key='white'>
                    <Button
                      className='aksiEdit'
                      color='facebook'
                      size='mini'
                      icon='pencil alternate'
                      onClick={() => onEditModal(row)}></Button>
                  </Tooltip>
                </Space>
              )}
              {loadingAct && (
                <Spin className='loading-data-table' indicator={antIcon} />
              )}
            </>
          )}
        </div>
      ),
    },
  ];

  const handleCancel = () => {
    setModal(false);
    form.resetFields();
  };

  const onFinishFailed = (errorInfo) => {};

  const handleReset = () => {
    if (modalCon.id === null) form.resetFields();
    else {
      let filter = saranTamu.filter((e) => {
        return e.id === modalCon.id;
      });
      form.setFieldsValue({
        nama: filter[0].name,
        email: filter[0].email,
        nip: filter[0].nip,
      });
    }
  };

  const onChangeTable = (pagination, filters, sorter, extra) => {
    const order = sorter.order === 'descend' ? 'DESC' : 'ASC';
    const field = sorter.order === undefined ? undefined : sorter.field;

    getTamu('', '1', field, order);
  };

  return (
    <div className=''>
      <Row justify='space-between' style={{ marginBottom: '20px' }}>
        <Col md={12} sm={24}>
          <Space></Space>
        </Col>
        <Col md={12} sm={24} style={{ width: '100%' }} className='mt-mobile'>
          <Input
            value={searchText}
            style={{ width: '100%' }}
            placeholder='Cari data tamu disini'
            onChange={onChangeSearch}
          />
        </Col>
      </Row>
      <Table
        onChange={onChangeTable}
        pagination={false}
        loading={loadingT}
        loadingIndicator={antIcon}
        columns={columns}
        dataSource={saranTamu}
        scroll={{ x: 600, y: 1500 }}
        size='small'
      />
      {pagination && (
        <div className='pagination'>
          <Pagination
            total={pagination.total}
            showTotal={(total, range) =>
              `Menampilkan ${range[0]}-${range[1]} dari ${total} data`
            }
            defaultPageSize={Number(pagination.perPage)}
            current={pagination.current}
            onChange={onChangePagination}
          />
        </div>
      )}
      {modalCon && (
        <Modal
          centered
          visible={modalVisible}
          onCancel={handleCancel}
          footer={[]}
          title={modalCon.judul}>
          <Form
            layout='vertical'
            form={form}
            name='basic'
            initialValues={{ remember: false }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}>
            <Form.Item
              labelAlign='left'
              label='Nama'
              name='nama'
              rules={[
                {
                  required: true,
                  message: 'Bagian ini wajib diisi',
                },
              ]}>
              <Input placeholder='Masukan nama tamu' />
            </Form.Item>

            <Form.Item
              labelAlign='left'
              label='Email'
              name='email'
              rules={[
                {
                  type: 'email',
                  message: 'Bagian ini wajib diisi!',
                  required: true,
                },
              ]}>
              <Input placeholder='Masukan email tamu' />
            </Form.Item>

            <Form.Item labelAlign='left' label='Nomor Induk Pegawai' name='nip'>
              <Input placeholder='Masukan NIP tamu' />
            </Form.Item>

            <Form.Item
              labelAlign='left'
              label='Institusi Asal'
              name='institusi'>
              <Input placeholder='Masukan institusi asal tamu' />
            </Form.Item>

            {instance && !modalCon.id && (
              <Form.Item
                labelAlign='left'
                label='Kantor/Unit/SubUnit'
                name='instance'
                rules={[
                  {
                    required: true,
                    message: 'Bagian ini wajib diisi',
                  },
                ]}>
                <Select
                  style={{ width: '100%' }}
                  autoComplete='off'
                  showSearch
                  placeholder='Pilih Kantor/Unit/SubUnit'
                  optionFilterProp='label'
                  onChange={onChange}
                  onFocus={onFocus}
                  onBlur={onBlur}
                  onSearch={onSearch}
                  // filterOption={(input, option) =>
                  //   option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  //   0
                  // }
                >
                  {instance.map((val, item) => (
                    <Option key={val.type} value={val.type} label={val.name}>
                      {val.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            )}

            <Form.Item>
              <Button
                floated='right'
                type='submit'
                loading={loading}
                color='green'>
                Simpan
              </Button>

              <Button
                type='button'
                floated='right'
                onClick={handleReset}
                loading={loading}
                negative>
                Reset
              </Button>
            </Form.Item>
          </Form>
        </Modal>
      )}
    </div>
  );
};

export default TamuSaran;
