import { UserProvider } from './context/UserContext';
import LayoutCustom from './layout/LayoutCustom';
import './App.css';
import React from 'react';
import { PenugasanProvider } from './context/PenugasanContext';
import { TamuProvider } from './context/TamuContext';
import { TimProvider } from './context/TimVerifikasiContext';
import { RapatProvider } from './context/DataRapatContext';

function App() {
  return (
    <div className='myContent'>
      <UserProvider>
        <PenugasanProvider>
          <TamuProvider>
            <TimProvider>
              <RapatProvider>
                <LayoutCustom />
              </RapatProvider>
            </TimProvider>
          </TamuProvider>
        </PenugasanProvider>
      </UserProvider>
    </div>
  );
}

export default App;
