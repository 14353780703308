import React, { useContext } from 'react';
import myAxios from '../../../myAxios';
import { message, Form, Switch, Input } from 'antd';
import { Button } from 'semantic-ui-react';

import 'bootstrap/dist/css/bootstrap.min.css';
import { useState, useEffect } from 'react';
import { UserContext } from '../../../context/UserContext';
import CryptoJS from 'crypto-js';
import { useHistory } from 'react-router-dom';
import bimAxios from '../../../bimAxios';

const KataSandi = () => {
  const [user, setUser] = useContext(UserContext);
  const [condition, setCon] = useState(false);

  const [loading, setLoading] = useState(false);

  const [changePass, setChangePass] = useState(false);
  const [passwordTemp, setPasswordTemp] = useState(null);
  let { history } = useHistory;
  const [form] = Form.useForm();

  useEffect(() => {}, []);

  const onChangePassword = (evt) => {
    setPasswordTemp((prev) => {
      return {
        ...prev,
        password_baru: evt.target.value,
      };
    });
  };

  const onChangeOldPassword = (evt) => {
    setPasswordTemp((prev) => {
      return {
        ...prev,
        password_lama: evt.target.value,
      };
    });
  };

  const confirmPassword = async (rule, value, callback) => {
    if (value === '' || value === undefined) {
      rule.message = 'Bagian ini wajib diisi';
      form.setFields({
        password_baru_konfirmasi: {
          value: value,
          errors: [new Error('forbid ha')],
        },
      });
    } else if (value !== passwordTemp.password_baru) {
      rule.message = 'Kata sandi tidak cocok!';
      form.setFields({
        password_baru_konfirmasi: {
          value: value,
          errors: [new Error('forbid ha')],
        },
      });
    } else {
      await callback();
    }
  };

  const passworValidator = async (rule, value, callback) => {
    if (value === '' || value === undefined) {
      rule.message = 'Bagian ini wajib diisi';
      form.setFields({
        password_baru_konfirmasi: {
          value: value,
          errors: [new Error('forbid ha')],
        },
      });
    } else if (value === passwordTemp.password_lama) {
      rule.message = 'Kata sandi lama dan baru tidak boleh sama!';
      form.setFields({
        password_baru_konfirmasi: {
          value: value,
          errors: [new Error('forbid ha')],
        },
      });
    } else {
      await callback();
    }
  };

  const onFinish = (values) => {
    setLoading(true);

    let newObj = {
      id: Number('-1'),
      confirm_password: values.password_baru_konfirmasi,
      new_password: values.password_baru,
      old_password: values.password_lama,
    };
    bimAxios
      .post(`user/changepassword`, newObj, {
        headers: {
          Authorization: 'Bearer ' + user.token,
        },
      })
      .then((res) => {
        setLoading(false);
        message.success('Berhasil mengubah kata sandi');
        form.resetFields();
      })
      .catch((err) => {
        if (err.response.data.message === 'unauthorized') {
          message.error('Sesi anda telah habis!');
          localStorage.removeItem('user');
          setUser(null);
        } else if (err.response.data.msg === 'Unauthorization Role') {
          message.error(err.response.data.msg);
          history.goBack();
        } else {
          message.error(err.response.data.message);
        }
        setLoading(false);
      });
  };

  const onFinishFailed = (errorInfo) => {};

  const handleReset = () => {
    setLoading(false);
    form.resetFields();
  };

  return (
    <div className=''>
      <div className=''>
        {/* <h1 className='table-title'>PROFIL USER</h1> */}
        {/* {profil && ( */}
        <Form
          layout='vertical'
          form={form}
          name='basic'
          initialValues={{ remember: false }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}>
          <Form.Item
            labelAlign='left'
            label='Kata Sandi Saat Ini'
            name='password_lama'
            rules={[
              {
                required: true,
                message: 'Kata sandi minimal terdiri dari 6 karakter!',
                min: 6,
              },
            ]}>
            <Input.Password
              fluid
              size='small'
              type='password'
              onChange={onChangeOldPassword}
            />
          </Form.Item>
          <Form.Item
            justify='center'
            labelAlign='left'
            label='Kata Sandi Baru'
            name='password_baru'
            rules={[
              {
                message: 'Kata sandi minimal terdiri dari 6 karakter!',
                validator: passworValidator,
                required: true,
              },
            ]}>
            <Input.Password
              fluid
              size='small'
              type='password'
              onChange={onChangePassword}
            />
          </Form.Item>

          <Form.Item
            labelAlign='left'
            label='Konfirmasi Kata Sandi'
            name='password_baru_konfirmasi'
            rules={[
              {
                validator: confirmPassword,
                required: true,
              },
            ]}>
            <Input.Password fluid size='small' type='password' />
          </Form.Item>

          <Form.Item>
            <Button
              floated='right'
              type='submit'
              color='green'
              loading={loading}>
              Simpan
            </Button>
            <Button
              floated='right'
              type='button'
              negative
              onClick={handleReset}
              loading={loading}>
              Reset
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default KataSandi;
