import React, { useContext } from 'react';
import myAxios from '../../myAxios';
import {
  Space,
  Spin,
  Row,
  Col,
  message,
  Modal,
  Form,
  Empty,
  Select,
  Menu,
  Dropdown,
  Input,
  Steps,
  Table,
  DatePicker,
  Pagination,
} from 'antd';
import moment from 'moment';
import { Button, Icon } from 'semantic-ui-react';
import { LoadingOutlined } from '@ant-design/icons';
import { RibbonContainer, RightCornerRibbon } from 'react-ribbons';

import { useState, useEffect } from 'react';
import { UserContext } from '../../context/UserContext';
import { useHistory } from 'react-router-dom';

import './Team.css';
import { motion } from 'framer-motion';
import { TimContext } from '../../context/TimVerifikasiContext';
import InfoBanner from './InfoBanner';

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
const tableLoading = {
  indicator: <Spin className='loading-data-table' indicator={antIcon} />,
};
const { Option } = Select;
const { RangePicker } = DatePicker;
const steps = [
  {
    title: 'First',
    content: 'Tim',
  },
  {
    title: 'Second',
    content: 'Anggota',
  },
];
const { Step } = Steps;
const Tim = () => {
  const [user, setUser] = useContext(UserContext);
  const [timContext, updateTim] = useContext(TimContext);
  const [tim, setTim] = useState(null);

  const [calonAnggota, setCalonAnggota] = useState({ anggota: [] });

  const [detailPengurus, setDetailPengurus] = useState({
    karyawanKetua: null,
    karyawanSekre: null,
    tempData: null,
    anggota: [],
    id_ketua: null,
    id_sekretaris: null,
    nama_tim: '',
    team_type: null,
  });

  const [modalCon, setModalCon] = useState(null);
  const [modalHapus, setModalHapus] = useState(null);
  const [tempData, setData] = useState(false);

  const [searchText, setSearch] = useState(null);
  const [loading, setLoading] = useState(false);

  const [modalVisible, setModal] = useState(false);
  const [idHapus, setIdHapus] = useState(null);
  const [instance, setInstance] = useState(null);
  const [currInstance, setCurrInstance] = useState(null);
  const [tipeTim, setTipeTim] = useState('Reguler');
  const [tanggal, setTanggal] = useState(false);
  const [authId, setAuthId] = useState(null);
  const [filter, setFilter] = useState(null);
  const [form] = Form.useForm();
  const [pagination, setPagination] = useState(null);
  const [page, setPage] = useState('10');

  const [matches, setMatches] = useState(
    window.matchMedia('(min-width: 768px)').matches
  );
  const [current, setCurrent] = useState(0);
  let history = useHistory();

  useEffect(() => {
    setLoading(true);
    setTim(null);
    getInstance();

    function handleResize() {
      setMatches(window.matchMedia('(min-width: 768px)').matches);
    }
    window.addEventListener('resize', handleResize);
    setLoading(tableLoading);
    if (currInstance) {
      const timeOutId = setTimeout(() => getTim(searchText), 500);
      return () => clearTimeout(timeOutId);
    }
  }, [currInstance]);

  const getTim = () => {
    setLoading(tableLoading);
    myAxios
      .get(`teams/${currInstance.id}/${currInstance.type}`, {
        headers: {
          Authorization: 'Bearer ' + user.token,
        },
      })
      .then((res) => {
        const data = res.data.data.data.data;
        if (data !== null) {
          setTim(data);
          setData(data);

          // if (user.role !== 'Karyawan') {
          const dataKaryawan = res.data.data.karyawan;
          setPagination({
            total: res.data.data.data.total,
            current: res.data.data.data.current_page,
            perPage: res.data.data.data.per_page,
          });
          setDetailPengurus((prev) => {
            return {
              ...prev,
              karyawanKetua: dataKaryawan,
              karyawanSekre: dataKaryawan,
              tempData: dataKaryawan,
            };
          });
          // }
        } else {
          setTim([]);
        }

        setLoading(false);
      })
      .catch((err) => {
        if (err.response.data.message === 'unauthorized') {
          message.error('Sesi anda telah habis!');
          localStorage.removeItem('user');
        } else if (err.response.data.msg === 'Unauthorization Role') {
          message.error(err.response.data.msg);
          history.goBack();
        } else {
          message.error(err.response.data.msg);
        }
        setLoading(false);
      });
  };

  const getInstance = async () => {
    await myAxios
      .get(`guests-instance`, {
        headers: {
          Authorization: 'Bearer ' + user.token,
        },
      })
      .then((res) => {
        const data = res.data.data;
        const temp = data[0];
        if (data.length > 0) {
          if (!currInstance) {
            setCurrInstance(data[0]);
            setAuthId((prev) => {
              return {
                ...prev,
                admin_id: data[0].admin_id,
                chairman_id: data[0].chairman_id,
              };
            });
          }
          setInstance(data);
        } else {
          setInstance([]);
        }

        // setLoadingAdmin(false);
      })
      .catch((err) => {
        if (err.response.data.message === 'unauthorized') {
          message.error('Sesi anda telah habis!');
          localStorage.removeItem('user');
        } else if (err.response.data.msg === 'Unauthorization Role') {
          message.error(err.response.data.msg);
          history.goBack();
        } else {
          message.error(err.response.data.msg);
        }
        // setLoadingAdmin(false);
      });
  };

  const onClickTim = (param) => {
    history.push(`/tim/ubahStatusTim/${param.uuid}`);
  };
  const onHapus = () => {
    setLoading(true);
    myAxios
      .delete(`team/${idHapus}`, {
        headers: {
          Authorization: 'Bearer ' + user.token,
        },
      })
      .then((res) => {
        getTim();
        message.success(res.data.msg);
        setLoading(false);
        setModalHapus(false);
        updateTim();
      })
      .catch((err) => {
        if (err.response.data.message === 'unauthorized') {
          message.error('Sesi anda telah habis!');
          localStorage.removeItem('user');
          setUser(null);
        } else if (err.response.data.msg === 'Unauthorization Role') {
          message.error(err.response.data.msg);
          history.goBack();
        } else {
          message.error(err.response.data.msg);
        }
        setLoading(false);
      });
  };

  const onLihatRapat = (param) => {
    history.push(`tim/rapat/${param.id}`);
  };

  const clearFilters = () => {
    setTim(tempData);
    setSearch(null);
  };

  const onChangeSearch = (evt) => {
    setSearch(evt.target.value);

    if (tempData) {
      if (evt.target.value === '') {
        setTim(tempData);
      } else {
        setTim(
          tempData.filter((i) => {
            return (
              i.name.toLowerCase().includes(evt.target.value.toLowerCase()) ||
              i.chairman_name
                .toLowerCase()
                .includes(evt.target.value.toLowerCase()) ||
              i.total_member == evt.target.value
            );
          })
        );
      }
    }
  };

  const handleCancel = () => {
    setModal(false);
  };

  const onChangePagination = (evt, show) => {
    setPage(show);
    getTim('', evt, null, null, show);
    setSearch('');
  };

  const onChange = ({ status, value }) => {
    if (status === 'Ketua') {
      let filterKetua = [];
      filterKetua = detailPengurus.tempData.filter((element) => {
        return element.id != value;
      });
      setDetailPengurus((prev) => {
        return {
          ...prev,
          karyawanSekre: filterKetua,
          id_ketua: value,
        };
      });
      setDetailPengurus((prev) => {
        return {
          ...prev,
          id_ketua: value,
        };
      });
    } else {
      let filterSekre = [];
      filterSekre = detailPengurus.tempData.filter((element) => {
        return element.id != value;
      });
      setDetailPengurus((prev) => {
        return {
          ...prev,
          karyawanKetua: filterSekre,
          id_sekretaris: value,
        };
      });
      setDetailPengurus((prev) => {
        return {
          ...prev,
          id_sekretaris: value,
        };
      });
    }
  };

  const onFocus = () => {};
  const onBlur = () => {};
  const onSearch = () => {};

  const onFinishFailed = (errorInfo) => {};

  const onTambahModal = () => {
    setModal(true);
    setModalCon({
      judul: 'Tambah Tim',
    });
    form.setFieldsValue({
      tipe_tim: 'Reguler',
    });
  };

  const onChangeHapus = (val) => {
    setIdHapus(val.id);
    setModalHapus(true);
  };

  const onDownloadSK = (param) => {
    myAxios
      .get(`download-team-decree-member/${param.id}`, {
        headers: {
          Authorization: 'Bearer ' + user.token,
        },
        responseType: 'blob',
      })
      .then((res) => {
        let disposition = res.request.getResponseHeader('content-disposition');
        var filename = '';
        if (disposition && disposition.indexOf('attachment') !== -1) {
          var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
          var matches = filenameRegex.exec(disposition);
          if (matches != null && matches[1]) {
            filename = matches[1].replace(/['"]/g, '');
          }
        }

        if (res.data !== null) {
          const url = window.URL.createObjectURL(new Blob([res.data]));

          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', filename);

          document.body.appendChild(link);
          link.click();
        }
      })
      .catch((err) => {
        if (err.response.data.message === 'unauthorized') {
          message.error('Sesi anda telah habis!');
          localStorage.removeItem('user');
          setUser(null);
        } else if (err.response.data.msg === 'Unauthorization Role') {
          message.error(err.response.data.msg);
          history.goBack();
        } else {
          message.error('Dokumen SK tidak ditemukan!');
        }
      });
  };

  const onUnduhLaporanTim = (param) => {
    myAxios
      .get(`team/generate-report/${param.id}`, {
        headers: {
          Authorization: 'Bearer ' + user.token,
        },
        responseType: 'arraybuffer',
      })
      .then((res) => {
        // response.data.pipe(fs.createWriteStream(filename));
        if (res.data !== null) {
          const url = window.URL.createObjectURL(new Blob([res.data]));

          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', param.name + '.pdf');
          // link.target = "_blank";
          document.body.appendChild(link);
          link.click();
        }
      })
      .catch((err) => {
        if (err.response.data.message === 'unauthorized') {
          message.error('Sesi anda telah habis!');
          localStorage.removeItem('user');
          setUser(null);
        } else if (err.response.data.msg === 'Unauthorization Role') {
          message.error(err.response.data.msg);
          history.goBack();
        } else {
          message.error(err.response.msg);
        }
      });
  };

  const onUbahStatus = (val) => {
    history.push(`/tim/ubahStatusTim/${val.id}`);
  };
  const onShowTim = (val) => {
    history.push(`/tim/lihatTim/${val.id}`);
  };
  const onUbahTim = (val) => {
    history.push(`/tim/editTim/${val.id}`);
  };
  const onKelolaSK = (val) => {
    history.push(`/tim/kelolaSK/${val.id}`);
  };

  const onKembali = () => {
    setCalonAnggota((prev) => {
      return {
        ...prev,
        anggota: detailPengurus.tempData.filter((element) => {
          return (
            element.id == detailPengurus.id_ketua ||
            element.id == detailPengurus.id_sekretaris
          );
        }),
      };
    });
    setCurrent(current - 1);
  };

  const onFinishTim = (values) => {
    setCurrent(current + 1);
    var start_date = null;
    var end_date = null;

    if (values.durasi !== undefined) {
      start_date = moment(values.durasi[0]).format('l');
      end_date = moment(values.durasi[1]).format('l');
    } else {
      start_date = moment(new Date()).format('l');
      end_date = moment(new Date()).format('l');
    }

    if (detailPengurus.id_sekretaris === null) {
      setDetailPengurus((prev) => {
        return {
          ...prev,
          start_date,
          end_date,
          team_type: values.tipe_tim,
          nama_tim: values.nama_tim,
          anggota: detailPengurus.tempData.filter((element) => {
            return element.id != detailPengurus.id_ketua;
          }),
        };
      });
    } else {
      setDetailPengurus((prev) => {
        return {
          ...prev,
          start_date,
          end_date,
          team_type: values.tipe_tim,
          nama_tim: values.nama_tim,
          anggota: detailPengurus.tempData.filter((element) => {
            return (
              element.id != detailPengurus.id_ketua &&
              element.id != detailPengurus.id_sekretaris
            );
          }),
        };
      });
    }

    const ketua = detailPengurus.tempData.find(
      (x) => x.id === detailPengurus.id_ketua
    );

    if (
      detailPengurus.id_sekretaris === null ||
      detailPengurus.id_sekretaris === undefined
    ) {
      setCalonAnggota((prev) => {
        return {
          ...prev,
          anggota: [ketua],
        };
      });
    } else {
      const sekretaris = detailPengurus.tempData.find(
        (x) => x.id === detailPengurus.id_sekretaris
      );

      setCalonAnggota((prev) => {
        return {
          ...prev,
          anggota: [ketua, sekretaris],
        };
      });
    }

    if (values.durasi) {
      setTanggal((prev) => {
        return {
          ...prev,
          tanggal_mulai: values.durasi[0]._d,
          tanggal_selesai: values.durasi[1]._d,
        };
      });
    }

    form.setFieldsValue({
      anggota: [],
    });
  };

  const onFinishTambahAnggota = (values) => {
    setLoading(true);

    if (
      detailPengurus.id_sekretaris === '' ||
      detailPengurus.id_sekretaris === null ||
      detailPengurus.id_sekretaris === undefined
    ) {
      detailPengurus.id_sekretaris = '-';
    }

    let newObj = {
      name: detailPengurus.nama_tim,
      chairman_id: detailPengurus.id_ketua,
      secretary_id: detailPengurus.id_sekretaris,
      team_type: detailPengurus.team_type,
      teamable_id: currInstance.id,
      member: values.anggota,
      start_date: detailPengurus.start_date,
      end_date: detailPengurus.end_date,
      type: currInstance.type,
    };
    myAxios
      .post(`team`, newObj, {
        headers: {
          Authorization: 'Bearer ' + user.token,
        },
      })
      .then((res) => {
        message.success(res.data.msg);
        setLoading(false);
        setModal(false);
        form.resetFields();
        getTim();
        updateTim();
        setCurrent(0);
        setCalonAnggota((prev) => {
          return {
            ...prev,
            anggota: [],
          };
        });
        setTipeTim('');
      })
      .catch((err) => {
        if (err.response.data.message === 'unauthorized') {
          message.error('Sesi anda telah habis!');
          localStorage.removeItem('user');
          setUser(null);
        } else if (err.response.data.msg === 'Unauthorization Role') {
          message.error(err.response.data.msg);
          history.goBack();
        } else {
          message.error(err.response.data.msg);
        }
        setLoading(false);
      });
  };

  const onChangeAnggota = (values) => {
    let filter = detailPengurus.tempData.filter((element) => {
      return values.includes(element.id);
    });

    const ketua = detailPengurus.tempData.find(
      (x) => x.id === detailPengurus.id_ketua
    );

    var newArray = [];

    if (
      detailPengurus.id_sekretaris === null ||
      detailPengurus.id_sekretaris === undefined
    ) {
      newArray = newArray.concat(ketua, filter);
    } else {
      const sekretaris = detailPengurus.tempData.find(
        (x) => x.id === detailPengurus.id_sekretaris
      );
      newArray = newArray.concat(ketua, sekretaris, filter);
    }
    setCalonAnggota((prev) => {
      return {
        ...prev,
        anggota: newArray,
      };
    });
  };

  const filterTim = (param) => {
    if (tim) {
      if (param === 'Semua') {
        setTim(tempData);
        setFilter(null);
      } else {
        const filter = tempData.filter((element) => {
          return element.status === param;
        });
        setTim(filter);
        setFilter(param);
      }
    }
  };

  const menuTim = (
    <Menu>
      {instance &&
        instance.map((val, item) => (
          <Menu.Item>
            <a
              target='_blank'
              rel='noopener noreferrer'
              onClick={() => onChangeFilter(val)}>
              {val.name}
            </a>
          </Menu.Item>
        ))}
    </Menu>
  );

  const onChangeFilter = (val) => {
    setCurrInstance(val);
    setAuthId((prev) => {
      return {
        ...prev,
        admin_id: val.admin_id,
        chairman_id: val.chairman_id,
      };
    });
  };

  const menu = (
    <Menu>
      <Menu.Item>
        <a
          target='_blank'
          rel='noopener noreferrer'
          onClick={() => filterTim('Semua')}>
          Semua
        </a>
      </Menu.Item>
      <Menu.Item>
        <a
          target='_blank'
          rel='noopener noreferrer'
          onClick={() => filterTim('Baru')}>
          Baru
        </a>
      </Menu.Item>
      <Menu.Item>
        <a
          target='_blank'
          rel='noopener noreferrer'
          onClick={() => filterTim('Aktif')}>
          Aktif
        </a>
      </Menu.Item>
      <Menu.Item>
        <a
          target='_blank'
          rel='noopener noreferrer'
          onClick={() => filterTim('Selesai')}>
          Selesai
        </a>
      </Menu.Item>
      <Menu.Item>
        <a
          target='_blank'
          rel='noopener noreferrer'
          onClick={() => filterTim('Verifikasi')}>
          Menunggu Verifikasi
        </a>
      </Menu.Item>
    </Menu>
  );

  const columnsAnggota = [
    {
      title: 'Nama',
      dataIndex: 'name',
      key: 'name',
      sorter: (a, b) => a.name.length - b.name.length,
      ellipsis: true,
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      sorter: (a, b) => a.email.length - b.email.length,
      ellipsis: true,
    },
    {
      title: 'NIP',
      dataIndex: 'username',
      key: 'username',
      sorter: (a, b) => a.username.length - b.username.length,
      ellipsis: true,
    },
    {
      title: 'Jabatan',
      dataIndex: 'jabatan',
      key: 'jabatan',
      ellipsis: true,
      render: (text, row) => (
        <>
          {row.id == detailPengurus.id_ketua
            ? 'Ketua'
            : row.id == detailPengurus.id_sekretaris &&
              detailPengurus.id_sekretaris !== null
            ? 'Sekretaris'
            : 'Anggota'}
        </>
      ),
    },
    // {
    //   key: "action",
    //   dataIndex: "id",
    //   align: "center",
    //   render: (dataIndex, row) => (
    //     <div>
    //       <Space size="middle">
    //         <Tooltip
    //           placement="bottom"
    //           title="Hapus Anggota"
    //           color="#1f1f1f"
    //           key="white"
    //         >
    //           <Popconfirm
    //             placement="bottomRight"
    //             title="Apakah yakin ingin mengeluarkan anggota dari tim ?"
    //             onConfirm={() => onHapus(dataIndex)}
    //             okText="Hapus"
    //             cancelText="Batal"
    //           >
    //             <Button
    //               negative
    //               className="aksiHapus"
    //               size="mini"
    //               icon="trash"
    //             ></Button>
    //           </Popconfirm>
    //         </Tooltip>
    //       </Space>
    //     </div>
    //   ),
    // },
  ];
  return (
    <div className='container-content tim'>
      <div className='container-table'>
        <h1 className='table-title'>DATA TIM</h1>

        {currInstance && instance && (
          <Row justify='space-between' style={{ marginBottom: '20px' }}>
            <Col md={12} sm={24}>
              <Space wrap={true} style={{ marginBottom: '3px' }}>
                {(user.role_kantor === 'kepalakantor' &&
                  currInstance.type === 'Kantor') ||
                (user.role_unit === 'dekan' && currInstance.type === 'Unit') ||
                (user.role_unit === 'kadep' &&
                  currInstance.type === 'Sub Unit') ||
                (user.role_unit === 'kepala_tu' &&
                  currInstance.type === 'Unit') ||
                (user.role_unit === 'kepala_tu' &&
                  currInstance.type === 'TU') ? (
                  <Button
                    color='green'
                    className='btn-hapus-filter'
                    onClick={onTambahModal}
                    icon
                    labelPosition='left'>
                    <Icon name='users' />
                    Tambah Tim
                  </Button>
                ) : null}
                {instance && (
                  <Dropdown
                    overlay={menuTim}
                    placement='bottomLeft'
                    trigger={['click']}>
                    <Button
                      secondary
                      className='btn-hapus-filter'
                      icon='angle down'
                      labelPosition='right'
                      content={
                        !currInstance
                          ? 'Filter Instansi'
                          : instance[
                              instance.findIndex(
                                (x) => x.type === currInstance.type
                              )
                            ].name
                      }
                    />
                  </Dropdown>
                )}
                <Dropdown
                  overlay={menu}
                  placement='bottomLeft'
                  trigger={['click']}>
                  <Button
                    className='btn-hapus-filter'
                    secondary
                    icon='angle down'
                    labelPosition='right'
                    content={filter ? filter : 'Filter Tim'}
                  />
                </Dropdown>
              </Space>
            </Col>
            <Col
              md={12}
              sm={24}
              style={{ width: '100%' }}
              className='mt-mobile'>
              <Input
                value={searchText}
                style={{ width: '100%' }}
                placeholder='Cari nama tim atau ketua disini'
                onChange={onChangeSearch}
              />
            </Col>
          </Row>
        )}
        {tim?.length > 0 && currInstance && <InfoBanner />}
        {loading && !tim && (
          <h1 className='loading-data'>
            <Spin indicator={antIcon} />
            <p>Mengambil data..</p>
          </h1>
        )}
        {!tim && !loading && <Empty />}
        {tim && currInstance && (
          <div className='scrollable-data-tim'>
            <Row justify={matches ? 'start' : 'center'}>
              {tim.length === 0 && (
                <div className='no-data'>
                  <Empty />
                </div>
              )}
              {tim.map((val, idx) => {
                return (
                  <Col md='3' style={{ padding: '12px 30px 10px 0' }}>
                    <Dropdown
                      overlay={
                        <Menu>
                          <Menu.Item
                            style={{ fontWeight: 'bold', color: 'black' }}
                            disabled>
                            {val.name}
                          </Menu.Item>
                          <hr style={{ margin: '0' }} />
                          <Menu.Item>
                            <a
                              rel='noopener noreferrer'
                              onClick={() => onLihatRapat(val)}>
                              Lihat Rapat
                            </a>
                          </Menu.Item>

                          {(val.status === 'Aktif' ||
                            val.status === 'Baru') && (
                            <>
                              {user.id === val.chairman_id ||
                              (currInstance.type === 'TU' &&
                                user.role_unit === 'kepala_tu') ||
                              (currInstance.type === 'Unit' &&
                                user.role_unit === 'kepala_tu') ||
                              (currInstance.type === 'Sub Unit' &&
                                user.role_unit === 'kadep') ||
                              (currInstance.type === 'Unit' &&
                                user.role_unit === 'dekan') ||
                              (currInstance.type === 'Kantor' &&
                                user.role_unit === 'kepalakantor') ? (
                                <>
                                  <Menu.Item>
                                    <a
                                      rel='noopener noreferrer'
                                      onClick={() => onUbahTim(val)}>
                                      Ubah Data Tim
                                    </a>
                                  </Menu.Item>
                                </>
                              ) : null}
                            </>
                          )}

                          <Menu.Item>
                            <a
                              rel='noopener noreferrer'
                              onClick={() => onShowTim(val)}>
                              Lihat Tim
                            </a>
                          </Menu.Item>
                          {val.status !== 'Baru' && (
                            <>
                              <Menu.Item>
                                <a
                                  rel='noopener noreferrer'
                                  onClick={() => onDownloadSK(val)}>
                                  Unduh SK
                                </a>
                              </Menu.Item>
                            </>
                          )}

                          {val.status === 'Baru' && (
                            <>
                              {((currInstance.type === 'Sub Unit' ||
                                currInstance.type === 'Unit' ||
                                currInstance.type === 'TU') &&
                                user.role_unit === 'kepala_tu') ||
                              (currInstance.type === 'Kantor' &&
                                user.role_kantor === 'admin') ? (
                                <Menu.Item>
                                  <a
                                    rel='noopener noreferrer'
                                    onClick={() => onKelolaSK(val)}>
                                    Kelola SK
                                  </a>
                                </Menu.Item>
                              ) : null}
                            </>
                          )}
                          {val.status !== 'Selesai' && (
                            <>
                              {(currInstance.type === 'Sub Unit' &&
                                user.role_unit === 'kadep') ||
                              (currInstance.type === 'Unit' &&
                                user.role_unit === 'dekan') ||
                              (currInstance.type === 'TU' &&
                                user.role_unit === 'kepala_tu') ||
                              (currInstance.type === 'Unit' &&
                                user.role_unit === 'kepala_tu') ||
                              (currInstance.type === 'Kantor' &&
                                user.role_unit === 'admin') ? (
                                <Menu.Item>
                                  <a
                                    rel='noopener noreferrer'
                                    onClick={() => onChangeHapus(val)}>
                                    Hapus Tim
                                  </a>
                                </Menu.Item>
                              ) : null}
                            </>
                          )}

                          {(val.status === 'Aktif' ||
                            val.status === 'Menunggu Verifikasi') && (
                            <>
                              {user.id === val.chairman_id ||
                              (currInstance.type === 'Unit' &&
                                user.role_unit === 'dekan') ||
                              (currInstance.type === 'Sub Unit' &&
                                user.role_unit === 'kadep') ||
                              (currInstance.type === 'Kantor' &&
                                user.role_kantor === 'kepalakantor') ||
                              (currInstance.type === 'TU' &&
                                user.role_unit === 'kepala_tu') ? (
                                <Menu.Item>
                                  <a
                                    rel='noopener noreferrer'
                                    onClick={() => onUbahStatus(val)}>
                                    {user.id === val.chairman_id
                                      ? 'Ubah Status'
                                      : 'Detail Tim'}
                                  </a>
                                </Menu.Item>
                              ) : null}
                            </>
                          )}

                          {/* {val.status === 'Menunggu Verifikasi' && (
                          <>
                            {user.id === authId.chairman_id ? (
                              <Menu.Item>
                                <a
                                  rel='noopener noreferrer'
                                  onClick={() => onUbahStatus(val)}>
                                  Detail Tim
                                </a>
                              </Menu.Item>
                            ) : null}
                          </>
                        )} */}

                          {val.status === 'Selesai' && (
                            <>
                              <Menu.Item>
                                <a
                                  rel='noopener noreferrer'
                                  onClick={() => onUnduhLaporanTim(val)}>
                                  Unduh Laporan Tim
                                </a>
                              </Menu.Item>
                              <Menu.Item>
                                <a
                                  target='_blank'
                                  href={val.report_link}
                                  rel='noopener noreferrer'>
                                  Kunjungi Link
                                </a>
                              </Menu.Item>
                            </>
                          )}
                        </Menu>
                      }
                      placement='bottomLeft'
                      arrow>
                      <motion.div
                        className='onHover'
                        initial={{ opacity: 0, y: -10 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.5, delay: idx / 20 }}>
                        <RibbonContainer className='custom-class'>
                          {val.status === 'Menunggu Verifikasi' && (
                            <RightCornerRibbon
                              backgroundColor='#474747'
                              color='#fff'
                              fontFamily='Lato'>
                              Verifikasi
                            </RightCornerRibbon>
                          )}

                          {val.status === 'Baru' && (
                            <RightCornerRibbon
                              backgroundColor='#474747'
                              color='#fff'
                              fontFamily='Lato'>
                              Baru
                            </RightCornerRibbon>
                          )}
                          <div
                            className={
                              val.status === 'Selesai'
                                ? 'container-card-finish'
                                : 'container-card-active'
                            }>
                            <div className='container-jumlah'>
                              <h1 className='jumlah-anggota'>
                                {val.total_member}
                              </h1>
                            </div>
                            <div className='container-content-card'>
                              <h2>{val.name}</h2>
                              <p>{val.chairman_name}</p>
                            </div>
                          </div>
                        </RibbonContainer>
                      </motion.div>
                    </Dropdown>
                  </Col>
                );
              })}
            </Row>
            {pagination && (
              <div className='pagination'>
                <Pagination
                  showSizeChanger={true}
                  pageSize={page}
                  total={pagination.total}
                  showTotal={(total, range) =>
                    `Menampilkan ${range[0]}-${range[1]} dari ${total} data`
                  }
                  defaultPageSize={Number(pagination.perPage)}
                  current={pagination.current}
                  onChange={onChangePagination}
                />
              </div>
            )}
          </div>
        )}
        <Modal
          title='Konfirmasi hapus tim'
          visible={modalHapus}
          onCancel={() => setModalHapus(false)}
          footer={[]}>
          <p style={{ paddingBottom: '10px' }}>
            Apakah anda yakin ingin menghapus tim ?
          </p>
          <Row justify='end' style={{ marginBottom: '20px' }}>
            <Col md='12' style={{ marginRight: '10px' }}>
              <Button
                color='green'
                onClick={() => setModalHapus(false)}
                loading={loading}>
                Batal
              </Button>
            </Col>
            <Col md='12'>
              <Button negative onClick={onHapus} loading={loading}>
                Hapus
              </Button>
            </Col>
          </Row>
        </Modal>
        {modalCon && detailPengurus && (
          // detailPengurus.tempData &&
          <Modal
            title={modalCon.judul}
            visible={modalVisible}
            onCancel={handleCancel}
            footer={[]}
            width={1000}>
            <Steps current={current}>
              {steps.map((item) => (
                <Step key={item.title} title={item.content} />
              ))}
            </Steps>

            <div className='steps-content'></div>
            <div className='steps-action' style={{ padding: '30px 15px' }}>
              {current < steps.length - 1 && (
                <>
                  <Form
                    layout='vertical'
                    form={form}
                    name='basic'
                    initialValues={{ remember: false }}
                    onFinish={onFinishTim}
                    onFinishFailed={onFinishFailed}>
                    <Form.Item
                      labelAlign='left'
                      label='Nama Tim'
                      name='nama_tim'
                      rules={[
                        {
                          required: true,
                          message: 'Bagian ini wajib diisi',
                        },
                      ]}>
                      <Input
                        fluid
                        size='small'
                        placeholder='Masukan nama tim'
                      />
                    </Form.Item>

                    {detailPengurus.karyawanKetua && (
                      <>
                        <Form.Item
                          labelAlign='left'
                          label='Nama Ketua'
                          name='id_ketua'
                          rules={[
                            {
                              required: true,
                              message: 'Bagian ini wajib diisi',
                            },
                          ]}>
                          <Select
                            style={{ width: '100%' }}
                            autoComplete='off'
                            showSearch
                            placeholder='Pilih Ketua Tim'
                            onChange={(value) =>
                              onChange({ status: 'Ketua', value })
                            }
                            onFocus={onFocus}
                            onBlur={onBlur}
                            onSearch={onSearch}
                            optionFilterProp='label'
                            // filterOption={(input, option) =>
                            //   option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                            //   0
                            // }
                          >
                            {detailPengurus.karyawanKetua.map((val, item) => (
                              <Option
                                key={val.id}
                                value={val.id}
                                label={val.name}>
                                {val.name}
                              </Option>
                            ))}
                          </Select>
                        </Form.Item>

                        <Form.Item
                          labelAlign='left'
                          label='Sekretaris'
                          name='id_sekretaris'>
                          <Select
                            allowClear
                            style={{ width: '100%' }}
                            autoComplete='off'
                            showSearch
                            placeholder='Pilih Sekretaris Tim'
                            optionFilterProp='children'
                            onChange={(value) =>
                              onChange({ status: 'Sekretaris', value })
                            }
                            onFocus={onFocus}
                            onBlur={onBlur}
                            onSearch={onSearch}
                            // filterOption={(input, option) =>
                            //   option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                            //   0
                            // }
                          >
                            {detailPengurus.karyawanSekre.map((val, item) => (
                              <Option key={val.id} value={val.id}>
                                {val.name}
                              </Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </>
                    )}

                    <Form.Item
                      labelAlign='left'
                      label='Tipe Tim'
                      name='tipe_tim'
                      rules={[
                        {
                          required: true,
                          message: 'Bagian ini wajib diisi',
                        },
                      ]}>
                      <Select
                        placeholder='Masukan tipe tim'
                        onChange={(evt) => setTipeTim(evt)}>
                        <Select.Option value='Reguler'>Reguler</Select.Option>
                        <Select.Option value='Ad-Hoc'>Ad-Hoc</Select.Option>
                      </Select>
                    </Form.Item>

                    {tipeTim === 'Ad-Hoc' && (
                      <Form.Item
                        labelAlign='left'
                        label='Durasi kerja'
                        name='durasi'
                        rules={[
                          {
                            required: true,
                            message: 'Bagian ini wajib diisi',
                          },
                        ]}>
                        <RangePicker
                          placeholder={['Tanggal Mulai', 'Tanggal Selesai']}
                          // disabledDate={(current) => {
                          //   return current < moment().subtract(1, 'day');
                          // }}
                        />
                      </Form.Item>
                    )}

                    <Form.Item>
                      <Button
                        floated='right'
                        color='green'
                        type='submit'
                        htmlType='submit'
                        loading={loading}>
                        Pilih Anggota
                      </Button>
                      <Button
                        negative
                        floated='right'
                        type='button'
                        onClick={() => form.resetFields()}
                        loading={loading}>
                        Reset
                      </Button>
                    </Form.Item>
                  </Form>
                </>
              )}
              {current === steps.length - 1 && detailPengurus.anggota && (
                <>
                  <Form
                    layout='vertical'
                    form={form}
                    name='basic'
                    initialValues={{ remember: false }}
                    onFinish={onFinishTambahAnggota}
                    onFinishFailed={onFinishFailed}>
                    <Form.Item
                      labelAlign='left'
                      label='Anggota'
                      name='anggota'
                      rules={[
                        {
                          required: true,
                          message: 'Bagian ini wajib diisi',
                        },
                      ]}>
                      <Select
                        mode='multiple'
                        placeholder='Silahkan pilih anggota'
                        onChange={onChangeAnggota}
                        optionFilterProp='label'
                        style={{ width: '100%' }}>
                        {detailPengurus.anggota.map((val, item) => (
                          <Option
                            style={{ height: '50px' }}
                            key={val.id}
                            value={val.id}
                            label={val.name}>
                            <p>
                              <b>{val.name}</b>
                            </p>
                            <p
                              style={{
                                lineHeight: '0',
                                fontWeight: 'normal',
                              }}>
                              {val.email}
                            </p>
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                    <Table
                      columns={columnsAnggota}
                      dataSource={calonAnggota.anggota}
                      scroll={{ x: 750, y: 1500 }}
                      size='small'
                    />
                    <Form.Item>
                      <Button
                        floated='right'
                        type='submit'
                        color='green'
                        htmlType='submit'
                        loading={loading}>
                        Simpan
                      </Button>
                      <Button
                        negative
                        floated='right'
                        type='button'
                        onClick={onKembali}
                        loading={loading}>
                        Kembali
                      </Button>
                    </Form.Item>
                  </Form>
                </>
              )}
            </div>
          </Modal>
        )}
      </div>
    </div>
  );
};

export default Tim;
