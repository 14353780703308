import React from 'react';
import { useContext } from 'react';
import 'antd/dist/antd.css';
import { UserContext } from '../context/UserContext';

const FooterCustom = () => {
  const [user, setUser] = useContext(UserContext);
  return (
    <div className={user !== null ? 'footer-custom' : 'footer-custom-login'}>
      <p>Sistem Manajemen Rapat © 2021 by Universitas Atma Jaya Yogyakarta</p>
    </div>
  );
};

export default FooterCustom;
