import React, { useContext } from 'react';
import myAxios from '../../myAxios';
import {
  Table,
  Space,
  Spin,
  Row,
  Col,
  message,
  Tooltip,
  Popconfirm,
  Form,
  Modal,
  Select,
  Input,
  Pagination,
  Tag,
  Switch,
} from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

import { useState, useEffect } from 'react';
import { UserContext } from '../../context/UserContext';
import { useHistory } from 'react-router-dom';

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
const tableLoading = {
  indicator: <Spin className='loading-data-table' indicator={antIcon} />,
};
const Statistik = () => {
  const [user, setUser] = useContext(UserContext);
  const [statistik, setStatistik] = useState(null);
  const [searchText, setSearch] = useState(null);
  const [loadingT, setLoadingT] = useState(false);
  const [pagination, setPagination] = useState(null);
  const [infoPage, setInfoPage] = useState({
    searchText: null,
    page: 1,
    sortBy: undefined,
    sorting: 'ASC',
    show: 10,
  });
  let history = useHistory();
  const [matches, setMatches] = useState(
    window.matchMedia('(max-width: 1914px)').matches
  );

  useEffect(() => {
    // function handleResize() {
    //   setMatches(window.matchMedia('(min-width: 1517px)').matches);
    // }
    // window.addEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    getStatistik();
  }, [searchText]);

  const getStatistik = (
    search = infoPage.searchText,
    page = infoPage.page,
    sortBy = infoPage.sortBy,
    sorting = infoPage.sorting,
    show = infoPage.show
  ) => {
    setLoadingT(tableLoading);
    myAxios
      .get('user-capacity', {
        params: {
          page: Number(page),
          search: search,
          show: show,
          sortBy: sortBy,
          sorting: sorting,
        },
        headers: {
          Authorization: 'Bearer ' + user.token,
        },
      })
      .then((res) => {
        const data = res.data.data.data;
        setLoadingT(false);
        setStatistik(data);
        setPagination({
          total: res.data.data.total,
          current: res.data.data.current_page,
          perPage: res.data.data.per_page,
        });
      })
      .catch((err) => {
        if (err.response.data.message === 'unauthorized') {
          message.error('Sesi anda telah habis!');
          localStorage.removeItem('user');
          setUser(null);
        } else if (err.response.data.msg === 'Unauthorization Role') {
          message.error(err.response.data.msg);
          history.goBack();
        } else {
          message.error(err.response.data.msg);
        }
        setLoadingT(false);
      });
  };

  const onChangePagination = (page, show) => {
    setInfoPage((prev) => {
      return {
        ...prev,
        page,
        show,
      };
    });
    getStatistik(
      infoPage.searchText,
      page,
      infoPage.sortBy,
      infoPage.sorting,
      show
    );
  };

  const onChangeSearch = (evt) => {
    setLoadingT(tableLoading);
    setInfoPage((prev) => {
      return {
        ...prev,
        searchText: evt.target.value,
      };
    });

    const timeOutId = setTimeout(
      () =>
        getStatistik(
          evt.target.value,
          null,
          infoPage.sortBy,
          infoPage.sorting,
          infoPage.show
        ),
      500
    );
    return () => clearTimeout(timeOutId);
  };

  const onChangeTable = (pagination, filters, sorter, extra) => {
    const order = sorter.order === 'descend' ? 'DESC' : 'ASC';
    const field = sorter.order === undefined ? undefined : sorter.field;
    setInfoPage((prev) => {
      return {
        ...prev,
        sortBy: field,
        sorting: order,
      };
    });
    getStatistik(infoPage.searchText, infoPage.page, field, order);
  };

  const columns = [
    {
      title: 'Nama',
      dataIndex: 'name',
      key: 'name',
      sorter: (a, b) => a.name.length - b.name.length,
      ellipsis: true,
    },
    {
      title: 'Tugas Terbuka',
      dataIndex: 'open_work',
      key: 'open_work',
      // sorter: (a, b) => a.open_work.length - b.open_work.length,
      ellipsis: true,
    },
    {
      title: 'Jumlah Tim Reguler',
      dataIndex: 'regular_team',
      key: 'regular_team',
      // sorter: (a, b) => a.regular_team.length - b.regular_team.length,
      ellipsis: true,
    },
    {
      title: 'Jumlah Tim Ad Hoc',
      dataIndex: 'adHoc_team',
      key: 'adHoc_team',
      // sorter: (a, b) => a.adHoc_team.length - b.adHoc_team.length,
      ellipsis: true,
    },
  ];

  return (
    <div className=''>
      <div className=''>
        <Row justify='space-between' style={{ marginBottom: '20px' }}>
          <Col md={12} sm={24}>
            <Space></Space>
          </Col>
          <Col md={12} sm={24} style={{ width: '100%' }} className='mt-mobile'>
            <Input
              value={infoPage.searchText}
              style={{ width: '100%' }}
              placeholder='Cari data statistik disini'
              onChange={onChangeSearch}
            />
          </Col>
        </Row>
        <Table
          onChange={onChangeTable}
          loading={loadingT}
          pagination={false}
          loadingIndicator={antIcon}
          columns={columns}
          dataSource={statistik}
          scroll={{ x: 900, y: 340 }}
          size='small'
        />
        {pagination && (
          <div className='pagination'>
            <Pagination
              showSizeChanger={true}
              pageSize={infoPage.show}
              total={pagination.total}
              showTotal={(total, range) =>
                `Menampilkan ${range[0]}-${range[1]} dari ${total} data`
              }
              defaultPageSize={Number(pagination.perPage)}
              current={pagination.current}
              onChange={onChangePagination}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default Statistik;
