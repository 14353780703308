import React, { useContext } from 'react';
import myAxios from '../../../myAxios';
import {
  Table,
  Space,
  Spin,
  Row,
  Col,
  message,
  Input,
  Tooltip,
  Popconfirm,
  Form,
  Tag,
  Modal,
  DatePicker,
  // TimePicker,
  Button as BA,
  Select,
  Steps,
  Pagination,
} from 'antd';
import {
  LoadingOutlined,
  MinusCircleOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import { Button } from 'semantic-ui-react';
import moment from 'moment';
import { useState, useEffect } from 'react';
import { UserContext } from '../../../context/UserContext';
import { useHistory, useParams } from 'react-router-dom';
import { ArrowUpRight } from 'react-bootstrap-icons';
import { RapatContext } from '../../../context/DataRapatContext';
import TimeRangePicker from '@wojtekmaj/react-timerange-picker';
const { TextArea } = Input;
const { Option } = Select;
const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
const tableLoading = {
  indicator: <Spin className='loading-data-table' indicator={antIcon} />,
};
const optionsDate = {
  weekday: 'long',
  year: 'numeric',
  month: 'long',
  day: 'numeric',
};

const steps = [
  {
    title: 'First',
    content: 'Tanggal dan Waktu',
  },
  {
    title: 'Second',
    content: 'Detail Rapat',
  },
  {
    title: 'Third',
    content: 'Agenda',
  },
];
const { Step } = Steps;
const Rapat = () => {
  const [user, setUser] = useContext(UserContext);
  const [
    tempDataRapat,
    setDataRapat,
    detail,
    setDetail,
    anggota,
    setAnggota,
    tempAng,
    setTempAng,
  ] = useContext(RapatContext);
  const [rapat, setRapat] = useState(null);
  const [ruang, setRuang] = useState(null);
  const [notulen, setNotulen] = useState(null);
  const [anggotaLuar, setAnggotaLuar] = useState(null);
  const [tamu, setTamu] = useState(null);
  const [modalCon, setModalCon] = useState(null);
  const [tempData, setData] = useState(null);
  const [searchText, setSearch] = useState(null);
  const [idKetua, setIdKetua] = useState(null);
  const [statusTim, setStatusTim] = useState(null);
  const [idEdit, setIdEdit] = useState(null);
  const [location, setLocation] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loadingAct, setLoadingAct] = useState(false);
  const [modalVisible, setModal] = useState(false);
  const [current, setCurrent] = useState(0);
  const [detailRapat, setDetailRapat] = useState({});
  const [pagination, setPagination] = useState(null);
  const [isOnline, setIsOnline] = useState(null);
  const [jam, setJam] = useState({ jam_mulai: null, jam_selesai: null });
  const [form] = Form.useForm();
  const [page, setPage] = useState('10');
  const [formRuang] = Form.useForm();
  const [formAgenda] = Form.useForm();
  const { idRapat } = useParams();

  const [dataWaktu, setWaktu] = useState(null);

  let history = useHistory();
  useEffect(() => {
    getLocation();
    getDetailTeam();
    const timeOutId = setTimeout(
      () => getRapat(searchText, null, null, null, page),
      500
    );
    return () => clearTimeout(timeOutId);
  }, [searchText]);

  const onChangePagination = (evt, show) => {
    setPage(show);
    getRapat('', evt, null, null, show);
  };

  const getRapat = (
    search = undefined,
    page = '1',
    sortBy = undefined,
    sorting = 'ASC',
    show = '10'
  ) => {
    setLoading(tableLoading);
    myAxios
      .get(`meeting-by-team/${idRapat}`, {
        params: {
          page: Number(page),
          search: search,
          show: show,
          sortBy: sortBy,
          sorting: sorting,
        },
        headers: {
          Authorization: 'Bearer ' + user.token,
        },
      })
      .then((res) => {
        const data = res.data.data.data;
        if (data.length > 0) {
          data.map((e) => {
            e.tanggal_full = new Date(`${e.date} ${e.start_time}`);
            e.date = new Date(e.date).toLocaleDateString('id-ID', optionsDate);
          });
          setLoading(false);
          setData(data);
          setRapat(data);
        } else {
          setLoading(false);
          setData([]);
          setRapat([]);
        }
        setPagination({
          total: res.data.data.total,
          current: res.data.data.current_page,
          perPage: res.data.data.per_page,
        });
      })
      .catch((err) => {
        if (err.response.data.message === 'unauthorized') {
          message.error('Sesi anda telah habis!');
          localStorage.removeItem('user');
          setUser(null);
        } else if (err.response.data.message === 'Data Tim Tidak Ditemukan') {
          message.error('ERROR ' + err.response.data.message);
        } else if (err.response.data.msg === 'Unauthorization Role') {
          message.error(err.response.data.msg);
          history.goBack();
        } else {
          message.error(err.response.data.msg);
        }

        setLoading(false);
      });
  };

  const getDetailTeam = () => {
    setLoading(tableLoading);
    myAxios
      .get(`team/${idRapat}`, {
        headers: {
          Authorization: 'Bearer ' + user.token,
        },
      })
      .then((res) => {
        const data = res.data.data.data;
        setIdKetua(data.chairman_id);
        setStatusTim(data.status);
      })
      .catch((err) => {
        if (err.response.data.message === 'unauthorized') {
          message.error('Sesi anda telah habis!');
          localStorage.removeItem('user');
          setUser(null);
        } else if (err.response.data.message === 'Data Tim Tidak Ditemukan') {
          message.error('ERROR ' + err.response.data.message);
        } else if (err.response.data.msg === 'Unauthorization Role') {
          message.error(err.response.data.msg);
          history.goBack();
        } else {
          message.error(err.response.data.msg);
        }

        setLoading(false);
      });
  };

  const getAgenda = async () => {
    await myAxios
      .get(`agendas/${modalCon.id}`, {
        headers: {
          Authorization: 'Bearer ' + user.token,
        },
      })
      .then((res) => {
        const data = res.data.data.data;

        const users = data.map((value) => {
          return {
            id: value.id,
            agenda: value.agenda,
          };
        });
        const tempUser = { users };
        formAgenda.setFieldsValue(tempUser);
      })
      .catch((err) => {
        if (err.response.data.message === 'unauthorized') {
          message.error('Sesi anda telah habis!');
          localStorage.removeItem('user');
          setUser(null);
        } else if (err.response.data.message === 'Data Tim Tidak Ditemukan') {
          message.error('ERROR ' + err.response.data.message);
        } else if (err.response.data.msg === 'Unauthorization Role') {
          message.error(err.response.data.msg);
          history.goBack();
        } else {
          message.error(err.response.data.msg);
        }

        setLoading(false);
      });
  };
  const getLocation = () => {
    setLoading(tableLoading);
    myAxios
      .get(`locations`, {
        headers: {
          Authorization: 'Bearer ' + user.token,
        },
      })
      .then((res) => {
        const data = res.data.data.data;
        setLocation(data);
      })
      .catch((err) => {
        if (err.response.data.message === 'unauthorized') {
          message.error('Sesi anda telah habis!');
          localStorage.removeItem('user');
          setUser(null);
        } else if (err.response.data.message === 'Data Tim Tidak Ditemukan') {
          message.error('ERROR ' + err.response.data.message);
        } else if (err.response.data.msg === 'Unauthorization Role') {
          message.error(err.response.data.msg);
          history.goBack();
        } else {
          message.error(err.response.data.msg);
        }

        setLoading(false);
      });
  };

  const onChange = (param) => {
    setIdEdit({ ...idEdit, room_id: param });
    setIsOnline(
      location.filter((e) => {
        return e.id === param;
      })[0].name
    );
  };
  const onChangeNotulen = (param) => {};
  const onChangeRuangRapat = () => {};
  const onFocus = () => {};
  const onBlur = () => {};
  const onSearch = () => {};

  const onHapus = (param) => {
    setLoadingAct(true);
    myAxios
      .delete(`meeting/${param}`, {
        headers: {
          Authorization: 'Bearer ' + user.token,
        },
      })
      .then((res) => {
        getRapat();
        message.success(res.data.msg);
        setLoadingAct(false);
      })
      .catch((err) => {
        if (err.response.data.message === 'unauthorized') {
          message.error('Sesi anda telah habis!');
          localStorage.removeItem('user');
          setUser(null);
        } else if (err.response.data.msg === 'Unauthorization Role') {
          message.error(err.response.data.msg);
          history.goBack();
        } else {
          message.error(err.response.data.msg);
        }
        setLoadingAct(false);
      });
  };

  const clearFilters = () => {
    setRapat(tempData);
    setSearch(null);
  };

  const onChangeSearch = (evt) => {
    setSearch(evt.target.value);
    if (tempData) {
      if (evt.target.value === '') {
        setRapat(tempData);
      } else {
        setRapat(
          tempData.filter((i) => {
            return (
              i.nama_rapat
                .toLowerCase()
                .includes(evt.target.value.toLowerCase()) ||
              i.nama_ruang
                .toLowerCase()
                .includes(evt.target.value.toLowerCase()) ||
              i.meeting_date
                .toLowerCase()
                .includes(evt.target.value.toLowerCase()) ||
              i.start_time
                .toLowerCase()
                .includes(evt.target.value.toLowerCase()) ||
              i.status.toLowerCase().includes(evt.target.value.toLowerCase())
            );
          })
        );
      }
    }
  };

  const handleCancel = () => {
    setModal(false);
    setCurrent(0);
    form.resetFields();
    formRuang.resetFields();
  };

  const onFinish = (values) => {
    setLoading(true);

    var start_time;
    var end_time;
    if (modalCon.judul === 'Tambah Rapat') {
      // start_time = moment(values.jam[0]).format('LTS');
      // end_time = moment(values.jam[1]).format('LTS');

      let newObj = {
        team_id: idRapat,
        start_time: values.jam_mulai,
        end_time: values.jam_selesai,
        date: moment(values.tanggal).format('l'),
        location_id: values.location,
        link: values.link_rapat,
      };

      myAxios
        .post(`show-room-for-store`, newObj, {
          headers: {
            Authorization: 'Bearer ' + user.token,
          },
        })
        .then((res) => {
          const data = res.data.data;

          if (data.rooms.length === 0) {
            message.info('Tidak Ada Ruangan yang Tersedia');
          } else {
            setWaktu(newObj);
            setCurrent(current + 1);
            setRuang(data.rooms);
            setNotulen(data.members);
            setAnggotaLuar(data.outsideMembers);
            setTamu(data.guests);
            formRuang.setFieldsValue({ notulen: data.secretary_id });
          }
          setLoading(false);
        })
        .catch((err) => {
          if (err.response.data.message === 'unauthorized') {
            message.error('Sesi anda telah habis!');
            localStorage.removeItem('user');
            setUser(null);
          } else if (err.response.data.msg === 'Unauthorization Role') {
            message.error(err.response.data.msg);
            history.goBack();
          } else {
            message.error(err.response.data.msg);
          }
          setLoading(false);
        });
    } else {
      //versi antd timepicker
      // start_time = moment(values.jam_mulai).format('LTS');
      // end_time = moment(values.jam_selesai).format('LTS');

      start_time = values.jam_mulai;
      end_time = values.jam_selesai;
      let newObj = {
        id: idRapat,
        meeting_id: modalCon.id,
        start_time,
        end_time,
        date: moment(values.tanggal).format('l'),
        location_id: values.location,
      };
      myAxios
        .post(`show-room-for-edit`, newObj, {
          headers: {
            Authorization: 'Bearer ' + user.token,
          },
        })
        .then((res) => {
          const data = res.data.data;

          if (data.rooms.length === 0) {
            message.info('Tidak Ada Ruangan yang Tersedia');
          } else {
            setWaktu(newObj);
            setCurrent(current + 1);
            setRuang(data.rooms);
            setNotulen(data.members);
            setAnggotaLuar(data.outsideMembers);
            setTamu(data.guestAll);
          }
          setLoading(false);

          formRuang.setFieldsValue({
            room: data.room_id,
            notulen: data.secretary_id,
            outsideMembers: data.outsideMembersNow,
            tamu: data.guest,
          });
        })
        .catch((err) => {
          if (err.response.data.message === 'unauthorized') {
            message.error('Sesi anda telah habis!');
            localStorage.removeItem('user');
            setUser(null);
          } else if (err.response.data.msg === 'Unauthorization Role') {
            message.error(err.response.data.msg);
            history.goBack();
          } else {
            message.error(err.response.data.msg);
          }
          setLoading(false);
        });
    }
  };
  const onFinishRuang = async (values) => {
    setLoading(true);
    await getAgenda();
    setCurrent(2);
    setDetailRapat(values);
    setLoading(false);
  };

  const onFinishAgenda = async (values) => {
    if (values.users.length === 0) {
      message.error('Silahkan masukan agenda rapat minimal 1!');
    } else {
      setLoading(true);
      if (modalCon.judul === 'Tambah Rapat') {
        if (detailRapat.description === undefined) detailRapat.description = '';
        if (detailRapat.outsideMembers === undefined)
          detailRapat.outsideMembers = [];
        if (detailRapat.tamu === undefined) detailRapat.tamu = [];
        let newObj = {
          team_id: idRapat,
          name: detailRapat.nama_rapat,
          date: moment(dataWaktu.date).format('l'),
          start_time: dataWaktu.start_time,
          end_time: dataWaktu.end_time,
          room_id: detailRapat.room,
          description: detailRapat.description,
          secretary_id: detailRapat.notulen,
          outsideMembers: detailRapat.outsideMembers,
          guests: detailRapat.tamu,
          link: dataWaktu.link,
          agenda: values.users,
        };
        myAxios
          .post(`meeting`, newObj, {
            headers: {
              Authorization: 'Bearer ' + user.token,
            },
          })
          .then((res) => {
            setWaktu(null);
            setLoading(false);
            setModal(false);
            setCurrent(0);
            form.resetFields();
            formRuang.resetFields();
            getRapat();
          })
          .catch((err) => {
            if (err.response.data.message === 'unauthorized') {
              message.error('Sesi anda telah habis!');
              localStorage.removeItem('user');
              setUser(null);
            } else if (err.response.data.msg === 'Unauthorization Role') {
              message.error(err.response.data.msg);
              history.goBack();
            } else {
              message.error(err.response.data.msg);
            }
            setLoading(false);
          });
      } else {
        let newObj = {
          name: detailRapat.nama_rapat,
          date: moment(dataWaktu.date).format('l'),
          start_time: dataWaktu.start_time,
          end_time: dataWaktu.end_time,
          room_id: detailRapat.room,
          description: detailRapat.description,
          secretary_id: detailRapat.notulen,
          outsideMembers: detailRapat.outsideMembers,
          guests: detailRapat.tamu,
          agenda: values.users,
        };
        myAxios
          .put(`meeting/${idEdit.id}`, newObj, {
            headers: {
              Authorization: 'Bearer ' + user.token,
            },
          })
          .then((res) => {
            setWaktu(null);
            setModal(false);
            setRuang(null);
            setCurrent(0);
            setAnggotaLuar(null);
            form.resetFields();
            formRuang.resetFields();
            getRapat();
            message.success(res.data.msg);
          })
          .catch((err) => {
            if (err.response.data.message === 'unauthorized') {
              message.error('Sesi anda telah habis!');
              localStorage.removeItem('user');
              setUser(null);
            } else if (err.response.data.msg === 'Unauthorization Role') {
              message.error(err.response.data.msg);
              history.goBack();
            } else {
              message.error(err.response.data.msg);
            }
            setLoading(false);
          });
      }
    }
  };

  function handleChange(value) {}

  const onFinishFailed = (errorInfo) => {};

  const onTambahModal = () => {
    setModal(true);
    setModalCon({
      judul: 'Tambah Rapat',
      id: null,
    });
  };
  const onEditModal = (param) => {
    setModal(true);
    setModalCon({
      judul: 'Ubah Rapat',
      id: param.id,
    });

    // var temp1 = new Date('2020-02-02 ' + param.start_time);
    // var temp2 = new Date('2020-02-02 ' + param.end_time);
    // var start_time = moment(temp1);
    // var end_time = moment(temp2);
    // var timer = [start_time, end_time];

    form.setFieldsValue({
      tanggal: moment(param.tanggal_full),
      jam_mulai: param.start_time,
      jam_selesai: param.end_time,
      location: param.location_id,
    });

    formRuang.setFieldsValue({
      nama_rapat: param.name,
      description: param.description,
    });
    setIdEdit({ id: param.id, room_id: param.room_id });
  };

  const prev = () => {
    setCurrent(current - 1);
  };

  const onDetail = (row) => {
    setDetail(null);
    setAnggota(null);
    history.push(`/tim/rapat/detailRapat/${row.id}`);
  };

  const onChangeTable = (pagination, filters, sorter, extra) => {
    const order = sorter.order === 'descend' ? 'DESC' : 'ASC';
    const field = sorter.order === undefined ? undefined : sorter.field;
    getRapat('', '1', field, order);
  };

  const onChangeJam = (evt) => {
    if (evt.target.id === 'jam_mulai') {
      setJam((prev) => {
        return {
          ...prev,
          jam_mulai: evt.target.value,
        };
      });
    } else {
      setJam((prev) => {
        return {
          ...prev,
          jam_selesai: evt.target.value,
        };
      });
    }
  };

  const jamMulaiValidator = async (rule, value, callback) => {
    var jam_mulai = new Date('2000-02-02 ' + value);
    var jam_selesai = new Date('2000-02-02 ' + jam.jam_selesai);

    if (value === '' || value === undefined) {
      rule.message = 'Bagian ini wajib diisi';
      form.setFields({
        jam_mulai: {
          value: value,
          errors: [new Error('forbid ha')],
        },
      });
    } else if (jam_selesai < jam_mulai) {
      rule.message = 'Jam mulai tidak boleh lebih dari jam selesai';
      form.setFields({
        jam_mulai: {
          value: value,
          errors: [new Error('forbid ha')],
        },
      });
    } else {
      await callback();
    }
  };
  const jamSelesaiValidator = async (rule, value, callback) => {
    var jam_mulai = new Date('2000-02-02 ' + jam.jam_mulai);
    var jam_selesai = new Date('2000-02-02 ' + value);

    if (value === '' || value === undefined) {
      rule.message = 'Bagian ini wajib diisi';
      form.setFields({
        jam_selesai: {
          value: value,
          errors: [new Error('forbid ha')],
        },
      });
    } else if (jam_selesai < jam_mulai) {
      rule.message = 'Jam selesai tidak boleh kurang dari jam mulai';
      form.setFields({
        jam_selesai: {
          value: value,
          errors: [new Error('forbid ha')],
        },
      });
    } else {
      await callback();
    }
  };

  const columns = [
    {
      title: 'Nama',
      dataIndex: 'name',
      key: 'name',
      sorter: (a, b) => a.name.length - b.name.length,
      ellipsis: true,
    },
    {
      title: 'Tanggal',
      dataIndex: 'date',
      key: 'date',
      sorter: (a, b) => a.date.length - b.date.length,
      ellipsis: true,
    },
    {
      title: 'Jam Mulai',
      dataIndex: 'start_time',
      key: 'start_time',
      sorter: (a, b) => a.start_time.length - b.start_time.length,
      ellipsis: true,
    },
    {
      title: 'Jam Selesai',
      dataIndex: 'end_time',
      key: 'end_time',
      sorter: (a, b) => a.end_time.length - b.end_time.length,
      ellipsis: true,
    },
    {
      title: 'Ruang',
      dataIndex: 'room',
      key: 'room',
      sorter: (a, b) => a.room.length - b.room.length,
      ellipsis: true,
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      sorter: (a, b) => a.status.length - b.status.length,
      ellipsis: true,
      width: '9%',
      render: (text) =>
        text === 'Pending' ? (
          <Tag color='red'>{text}</Tag>
        ) : (
          <Tag color='green'>{text}</Tag>
        ),
    },
    {
      key: 'action',
      dataIndex: 'id',
      align: 'center',
      render: (dataIndex, row) => (
        <div>
          {idKetua && statusTim && (
            <>
              {!loadingAct && (
                <Space size='middle'>
                  <Tooltip
                    placement='bottom'
                    title='Lihat Detail'
                    color='#1f1f1f'
                    key='white'>
                    <Button
                      color='orange'
                      className='aksiDetail'
                      size='mini'
                      onClick={() => onDetail(row)}
                      icon={<ArrowUpRight />}
                    />
                  </Tooltip>
                  {
                    // user.id === idKetua  &&
                    statusTim !== 'Selesai' && (
                      <>
                        <Tooltip
                          placement='bottom'
                          title='Ubah Rapat'
                          color='#1f1f1f'
                          key='white'>
                          <Button
                            disabled={row.status === 'Selesai' ? true : false}
                            className='aksiEdit'
                            size='mini'
                            onClick={() => onEditModal(row)}
                            icon='pencil alternate'
                            color='facebook'
                          />
                        </Tooltip>
                        <Tooltip
                          placement='bottom'
                          title='Batalkan Rapat'
                          color='#1f1f1f'
                          key='white'>
                          <Popconfirm
                            placement='bottomRight'
                            title='Apakah yakin ingin membatalkan rapat ?'
                            onConfirm={() => onHapus(dataIndex)}
                            okText='Hapus'
                            cancelText='Batal'>
                            <Button
                              disabled={
                                row.status === 'Selesai' ||
                                new Date() >= row.tanggal_full
                                  ? true
                                  : false
                              }
                              className='aksiHapus'
                              size='mini'
                              icon='trash'
                              negative
                            />
                          </Popconfirm>
                        </Tooltip>
                      </>
                    )
                  }
                </Space>
              )}
              {loadingAct && (
                <Spin className='loading-data-table' indicator={antIcon} />
              )}
            </>
          )}
        </div>
      ),
    },
  ];

  return (
    <div className='container-content rapat'>
      <div className='container-table'>
        <h1 className='table-title'>DATA RAPAT</h1>
        <Row justify='space-between' style={{ marginBottom: '20px' }}>
          <Col md={12} sm={24}>
            {rapat && (
              <Space wrap={true} style={{ marginBottom: '2px' }}>
                {(idKetua === user.id ||
                  user.role_kantor === 'admin' ||
                  user.role_kantor === 'kepalakantor' ||
                  user.role_unit === 'kepala_tu' ||
                  user.role_unit === 'dekan' ||
                  user.role_unit === 'kadep') &&
                  statusTim !== 'Selesai' && (
                    <Button
                      color='green'
                      className='btn-hapus-filter'
                      onClick={onTambahModal}>
                      Tambah Rapat
                    </Button>
                  )}
                <Button
                  secondary
                  className='btn-hapus-filter'
                  onClick={clearFilters}>
                  Hapus Filter
                </Button>
              </Space>
            )}
          </Col>
          <Col md={12} sm={24} style={{ width: '100%' }} className='mt-mobile'>
            <Input
              value={searchText}
              style={{ width: '100%' }}
              placeholder='Cari data rapat disini'
              onChange={(evt) => setSearch(evt.target.value)}
            />
          </Col>
        </Row>
        {/* {rapat && ( */}
        <Table
          onChange={onChangeTable}
          pagination={false}
          loading={loading}
          loadingIndicator={antIcon}
          columns={columns}
          dataSource={rapat}
          scroll={{ x: 900, y: 1500 }}
          size='small'
        />
        {pagination && (
          <div className='pagination'>
            <Pagination
              total={pagination.total}
              showTotal={(total, range) =>
                `Menampilkan ${range[0]}-${range[1]} dari ${total} data`
              }
              defaultPageSize={Number(pagination.perPage)}
              current={pagination.current}
              onChange={onChangePagination}
            />
          </div>
        )}
        {modalCon && (
          <Modal
            title={modalCon.judul}
            visible={modalVisible}
            onCancel={handleCancel}
            footer={[]}
            width={1000}>
            <Steps current={current}>
              {steps.map((item) => (
                <Step key={item.title} title={item.content} />
              ))}
            </Steps>

            <div className='steps-content'></div>
            <div className='steps-action' style={{ padding: '30px 15px' }}>
              {current === 0 && (
                //step1
                <>
                  <Form
                    layout='vertical'
                    form={form}
                    name='basic'
                    initialValues={{ remember: false }}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}>
                    <Form.Item
                      labelAlign='left'
                      label='Tanggal'
                      name='tanggal'
                      rules={[
                        {
                          required: true,
                          message: 'Bagian ini wajib diisi',
                        },
                      ]}>
                      <DatePicker
                        placeholder='Masukan Tanggal Rapat'
                        disabledDate={(current) => {
                          return current < moment().subtract(1, 'day');
                        }}
                      />
                    </Form.Item>

                    <Row justify='space-between'>
                      <Col md={11}>
                        <Form.Item
                          labelAlign='left'
                          label='Jam Mulai Rapat'
                          name='jam_mulai'
                          rules={[
                            {
                              required: true,
                              validator: jamMulaiValidator,
                            },
                          ]}>
                          <input
                            onChange={onChangeJam}
                            type='time'
                            id='jam_mulai'
                          />
                        </Form.Item>
                      </Col>
                      <Col md={11}>
                        <Form.Item
                          labelAlign='left'
                          label='Jam Selesai Rapat'
                          name='jam_selesai'
                          rules={[
                            {
                              required: true,
                              validator: jamSelesaiValidator,
                            },
                          ]}>
                          <input
                            onChange={onChangeJam}
                            type='time'
                            id='jam_selesai'
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                    {/* <Form.Item
                      labelAlign='left'
                      label='Jam Rapat'
                      name='jam'
                      rules={[
                        {
                          required: true,
                          message: 'Bagian ini wajib diisi',
                        },
                      ]}>
                      <TimePicker.RangePicker
                        onChange={onChangeTimePicker}
                        format='HH:mm'
                        placeholder={['Jam Mulai', 'Jam Selesai']}
                      />
                    </Form.Item> */}

                    {location && (
                      <Form.Item
                        labelAlign='left'
                        label='Lokasi'
                        name='location'
                        rules={[
                          {
                            required: true,
                            message: 'Bagian ini wajib diisi',
                          },
                        ]}>
                        <Select
                          style={{ width: '100%' }}
                          autoComplete='off'
                          showSearch
                          placeholder='Cari Lokasi Rapat'
                          optionFilterProp='children'
                          onChange={onChange}
                          onFocus={onFocus}
                          onBlur={onBlur}
                          onSearch={onSearch}
                          // filterOption={(input, option) =>
                          //   option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                          //   0
                          // }
                        >
                          {location.map((val, item) => (
                            <Option
                              key={val.id}
                              value={val.id}
                              label={val.name}>
                              {val.name}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                    )}

                    {isOnline === 'Online' && (
                      <Form.Item
                        labelAlign='left'
                        label='Link Rapat'
                        name='link_rapat'>
                        <TextArea placeholder='Masukan Link Rapat' rows={4} />
                      </Form.Item>
                    )}

                    <Form.Item>
                      <Button
                        floated='right'
                        color='green'
                        type='submit'
                        loading={loading}>
                        Lanjut
                      </Button>
                    </Form.Item>
                  </Form>
                </>
              )}
              {current === 1 && ruang && (
                //step2
                <>
                  <Form
                    layout='vertical'
                    form={formRuang}
                    name='basic'
                    initialValues={{ remember: false }}
                    onFinish={onFinishRuang}
                    onFinishFailed={onFinishFailed}>
                    <Form.Item
                      labelAlign='left'
                      label='Nama Rapat'
                      name='nama_rapat'
                      rules={[
                        {
                          required: true,
                          message: 'Bagian ini wajib diisi',
                        },
                      ]}>
                      <Input placeholder='Masukan Nama Rapat' />
                    </Form.Item>

                    {notulen && (
                      <Form.Item
                        labelAlign='left'
                        label='Notulen'
                        name='notulen'
                        rules={[
                          {
                            required: true,
                            message: 'Bagian ini wajib diisi',
                          },
                        ]}>
                        <Select
                          style={{ width: '100%' }}
                          autoComplete='off'
                          showSearch
                          placeholder='Cari Notulen Rapat'
                          optionFilterProp='children'
                          onChange={onChangeNotulen}
                          onFocus={onFocus}
                          onBlur={onBlur}
                          onSearch={onSearch}
                          // filterOption={(input, option) =>
                          //   option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                          //   0
                          // }
                        >
                          {notulen.map((val, item) => (
                            <Option
                              key={val.id}
                              value={val.id}
                              label={val.name}>
                              {val.name}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                    )}
                    {anggotaLuar && (
                      <Form.Item
                        labelAlign='left'
                        label='Tambah Karyawan Luar Tim'
                        name='outsideMembers'>
                        <Select
                          mode='multiple'
                          placeholder='Tambah Karyawan Luar Tim'
                          onChange={handleChange}
                          optionFilterProp='label'
                          style={{ width: '100%' }}>
                          {anggotaLuar.map((val, item) => (
                            <Option
                              key={val.id}
                              value={val.id}
                              label={val.name}>
                              {val.name}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                    )}

                    {tamu && (
                      <Form.Item
                        labelAlign='left'
                        label='Tambah Tamu'
                        name='tamu'>
                        <Select
                          mode='multiple'
                          placeholder='Tambah Tamu'
                          onChange={handleChange}
                          optionFilterProp='label'
                          style={{ width: '100%' }}>
                          {tamu.map((val, item) => (
                            <Option
                              key={val.id}
                              value={val.id}
                              label={val.name}>
                              {val.name}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                    )}
                    <Form.Item
                      labelAlign='left'
                      label='Ruang Rapat'
                      name='room'
                      rules={[
                        { required: true, message: 'Bagian ini wajib diisi' },
                      ]}>
                      <Select
                        style={{ width: '100%' }}
                        autoComplete='off'
                        showSearch
                        placeholder='Cari Ruang Rapat'
                        optionFilterProp='label'
                        onChange={onChangeRuangRapat}
                        onFocus={onFocus}
                        onBlur={onBlur}
                        onSearch={onSearch}
                        // filterOption={(input, option) =>
                        //   option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                        //   0
                        // }
                      >
                        {ruang.map((val, item) => (
                          <Option key={val.id} value={val.id} label={val.name}>
                            {val.name}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                    <Form.Item
                      labelAlign='left'
                      label='Keterangan'
                      name='description'>
                      <TextArea
                        placeholder='Masukan Keterangan Rapat'
                        rows={5}
                      />
                    </Form.Item>
                    <Form.Item>
                      <Button
                        color='green'
                        floated='right'
                        size='small'
                        type='submit'
                        loading={loading}>
                        Lanjut
                      </Button>

                      <Button
                        negative
                        type='button'
                        floated='right'
                        size='small'
                        loading={loading}
                        onClick={() => prev()}>
                        Kembali
                      </Button>
                    </Form.Item>
                  </Form>
                </>
              )}
              {current === 2 && ruang && (
                //step3
                <>
                  <Form
                    form={formAgenda}
                    layout='vertical'
                    name='dynamic_form_nest_item'
                    onFinish={onFinishAgenda}
                    autoComplete='off'
                    initialValues={{ users: ['', '', ''] }}>
                    <Form.List name='users'>
                      {(fields, { add, remove }) => (
                        <>
                          {fields.map(
                            ({ key, name, fieldKey, ...restField }) => (
                              <>
                                <Form.Item
                                  {...restField}
                                  name={[name, 'agenda']}
                                  labelAlign='left'
                                  label='Agenda'
                                  fieldKey={[fieldKey, 'agenda']}
                                  rules={[
                                    {
                                      required: true,
                                      message: 'Bagian ini wajib diisi',
                                    },
                                  ]}>
                                  <Input placeholder='Masukan Agenda' />
                                </Form.Item>
                                {fields.length !== 1 && (
                                  <MinusCircleOutlined
                                    onClick={() => remove(name)}
                                  />
                                )}
                              </>
                            )
                          )}

                          <Form.Item style={{ marginTop: '20px' }}>
                            <BA
                              type='dashed'
                              onClick={() => add()}
                              block
                              icon={<PlusOutlined />}>
                              Tambah Agenda
                            </BA>
                          </Form.Item>
                        </>
                      )}
                    </Form.List>
                    <Form.Item>
                      <Button
                        size='small'
                        color='green'
                        floated='right'
                        type='submit'
                        loading={loading}>
                        {modalCon.judul}
                      </Button>
                      <Button
                        negative
                        type='button'
                        floated='right'
                        size='small'
                        loading={loading}
                        onClick={() => prev()}>
                        Kembali
                      </Button>
                    </Form.Item>
                  </Form>
                </>
              )}
            </div>
          </Modal>
        )}
      </div>
    </div>
  );
};

export default Rapat;
