import React from "react";

import { Tabs } from "antd";

import "bootstrap/dist/css/bootstrap.min.css";

import DataRapat from "./ComponentDetail/DataRapat";
import DataDetail from "./ComponentDetail/DataDetail";
import Dokumen from "./ComponentDetail/Dokumen";
import Kesimpulan from "./ComponentDetail/Kesimpulan";

const DetailRapat = () => {
  const { TabPane } = Tabs;

  function callback(key) {
    localStorage.setItem("tab", key);
  }
  return (
    <div className="container-content">
      <div className="container-detail" style={{ paddingTop: "5px" }}>
        <Tabs
          defaultActiveKey={
            !localStorage.getItem("tab") ? "1" : localStorage.getItem("tab")
          }
          onChange={callback}
        >
          <TabPane tab="Rapat" key="1">
            <h1 className="table-title">DETAIL RAPAT</h1>
            <DataRapat />
          </TabPane>
          <TabPane tab="Minute Meeting" key="2">
            <h1 className="table-title">MINUTE MEETING</h1>
            <DataDetail />
          </TabPane>
          <TabPane tab="Dokumen" key="3">
            <h1 className="table-title">DOKUMEN RAPAT</h1>
            <Dokumen />
          </TabPane>
          <TabPane tab="Kesimpulan" key="4">
            <h1 className="table-title">KESIMPULAN</h1>
            <Kesimpulan />
          </TabPane>
        </Tabs>
      </div>
    </div>
  );
};

export default DetailRapat;
