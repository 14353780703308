import React, { useContext } from 'react';
import myAxios from '../../../../myAxios';
import {
  Table,
  Space,
  Spin,
  Row,
  Col,
  message,
  Input,
  Tooltip,
  Popconfirm,
  Form,
  Select,
  Modal,
  Button as BA,
  Pagination,
} from 'antd';
import { Button } from 'semantic-ui-react';
import {
  LoadingOutlined,
  MinusCircleOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useState, useEffect } from 'react';
import { UserContext } from '../../../../context/UserContext';
import { useHistory, useParams } from 'react-router-dom';

const { TextArea } = Input;
const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
const tableLoading = {
  indicator: <Spin className='loading-data-table' indicator={antIcon} />,
};
const { Option } = Select;

const DetailRapat = () => {
  const [user, setUser] = useContext(UserContext);

  const [minuteM, setMM] = useState(null);
  const [tempData, setData] = useState(null);
  const [idEdit, setIdEdit] = useState(null);
  const [agendaDD, setAgendaDD] = useState([]);

  const [anggota, setAnggota] = useState(null);

  const [searchText, setSearch] = useState(null);
  const [auth, setAuth] = useState(null);

  const [loading, setLoading] = useState(false);
  const [loadingAct, setLoadingAct] = useState(false);
  const [loadingMinute, setLoadingM] = useState(false);

  const [modalVisible, setModal] = useState(false);
  const [pagination, setPagination] = useState(null);

  const [form] = Form.useForm();
  const [formEdit] = Form.useForm();
  const { idRapat } = useParams();
  let { history } = useHistory;

  useEffect(() => {
    const timeOutId = setTimeout(() => getMM(searchText), 500);
    return () => clearTimeout(timeOutId);
  }, []);

  const getMM = (
    search = undefined,
    page = '1',
    sortBy = undefined,
    sorting = 'ASC'
  ) => {
    setLoading(tableLoading);
    myAxios
      .get(`minute-meeting/${idRapat}`, {
        params: {
          search: search,
          show: '10',
          sortBy: sortBy,
          sorting: sorting,
        },
        headers: {
          Authorization: 'Bearer ' + user.token,
        },
      })
      .then((res) => {
        const data = res.data.data;
        setAnggota(data.anggota);
        setMM(data.data);
        setData(data.data);
        setAuth(data.auth);
        setAgendaDD(data.agenda);
        // setPagination({
        //   total: data.data.total,
        //   current: data.data.current_page,
        //   perPage: data.data.per_page,
        // });
        setLoading(false);
      })
      .catch((err) => {
        if (err.response.data.message === 'unauthorized') {
          message.error('Sesi anda telah habis!');
          localStorage.removeItem('user');
          setUser(null);
        } else if (err.response.data.msg === 'Unauthorization Role') {
          message.error(err.response.data.msg);
          history.goBack();
        } else {
          message.error(err.response.data.msg);
        }
        setLoading(false);
      });
  };

  const onHapus = (param) => {
    setLoadingAct(true);
    myAxios
      .delete(`minute-meeting/${param}`, {
        headers: {
          Authorization: 'Bearer ' + user.token,
        },
      })
      .then((res) => {
        getMM();
        message.success(res.data.msg);
        setLoadingAct(false);
      })
      .catch((err) => {
        if (err.response.data.message === 'unauthorized') {
          message.error('Sesi anda telah habis!');
          localStorage.removeItem('user');
          setUser(null);
        } else if (err.response.data.msg === 'Unauthorization Role') {
          message.error(err.response.data.msg);
          history.goBack();
        } else {
          message.error(err.response.data.msg);
        }
        setLoading(false);
      });
  };

  const clearFilters = () => {
    setMM(tempData);
    setSearch(null);
  };

  const onChangeSearch = (evt) => {
    setSearch(evt.target.value);
    if (tempData) {
      if (evt.target.value === '') {
        setMM(tempData);
      } else {
        setMM(
          tempData.filter((i) => {
            return (
              i.name.toLowerCase().includes(evt.target.value.toLowerCase()) ||
              i.notes.toLowerCase().includes(evt.target.value.toLowerCase())
            );
          })
        );
      }
    }
  };

  const handleCancel = () => {
    setModal(false);
  };

  const onFinish = (values) => {
    if (idEdit === null) {
      setLoadingM(true);
      let newObj = {
        meeting_id: idRapat,
        data: values.users,
      };
      myAxios
        .post(`minute-meeting`, newObj, {
          headers: {
            Authorization: 'Bearer ' + user.token,
          },
        })
        .then((res) => {
          setLoadingM(false);
          setModal(false);
          form.resetFields();
          message.success(res.data.msg);
          getMM();
        })
        .catch((err) => {
          if (err.response.data.message === 'unauthorized') {
            message.error('Sesi anda telah habis!');
            localStorage.removeItem('user');
            setUser(null);
          } else if (err.response.data.msg === 'Unauthorization Role') {
            message.error(err.response.data.msg);
            history.goBack();
          } else {
            message.error(err.response.data.msg);
          }
          setLoading(false);
        });
    } else {
      setLoading(tableLoading);
      let newObj = {
        agenda_id: values.agenda,
        user_id: values.anggota,
        notes: values.keterangan,
      };
      myAxios
        .put(`minute-meeting/${idEdit.id}`, newObj, {
          headers: {
            Authorization: 'Bearer ' + user.token,
          },
        })
        .then((res) => {
          message.success(res.data.msg);
          setLoading(false);
          setModal(false);
          setIdEdit(null);
          form.resetFields();
          getMM();
        })
        .catch((err) => {
          if (err.response.data.message === 'unauthorized') {
            message.error('Sesi anda telah habis!');
            localStorage.removeItem('user');
            setUser(null);
          } else {
            message.error(err.response.data.msg);
          }
          setLoading(false);
        });
    }
  };

  const onFinishFailed = (errorInfo) => {};

  const onEditModal = (param) => {
    setModal(true);
    formEdit.setFieldsValue({
      agenda: param.agenda_id,
      anggota: param.user_id,
      keterangan: param.notes,
    });
    setIdEdit(param);
  };

  const onReset = () => {
    formEdit.setFieldsValue({
      anggota: idEdit.user_id,
      keterangan: idEdit.notes,
    });
  };

  const onChange = () => {};
  const onFocus = () => {};
  const onBlur = () => {};
  const onSearch = () => {};

  const onChangePagination = (evt) => {
    // setPage(show);
    getMM('', evt);
  };

  const onChangeTable = (pagination, filters, sorter, extra) => {
    const order = sorter.order === 'descend' ? 'DESC' : 'ASC';
    const field = sorter.order === undefined ? undefined : sorter.field;

    getMM('', '1', field, order);
  };
  const columns = [
    {
      title: 'Nama Anggota',
      dataIndex: 'name',
      key: 'name',
      sorter: (a, b) => a.name.length - b.name.length,
      ellipsis: true,
    },
    {
      title: 'Agenda',
      dataIndex: 'agenda',
      key: 'agenda',
      sorter: (a, b) => a.agenda.length - b.agenda.length,
      ellipsis: true,
    },
    {
      title: 'Catatan',
      dataIndex: 'notes',
      key: 'notes',
      sorter: (a, b) => a.notes.length - b.notes.length,
      ellipsis: true,
    },

    {
      key: 'action',
      dataIndex: 'id',
      align: 'center',
      render: (dataIndex, row) => (
        <div>
          {!loadingAct && (
            <Space size='middle'>
              {(auth.chairman_id === user.id ||
                auth.secretary_id === user.id) &&
                auth.status !== 'Selesai' && (
                  <>
                    <Tooltip
                      placement='bottom'
                      title='Ubah Minute Meeting'
                      color='#1f1f1f'
                      key='white'>
                      <Button
                        color='facebook'
                        className='aksiEdit'
                        size='mini'
                        onClick={() => onEditModal(row)}
                        icon='pencil alternate'
                      />
                    </Tooltip>
                    <Tooltip
                      placement='bottom'
                      title='Hapus Minute Meeting'
                      color='#1f1f1f'
                      key='white'>
                      <Popconfirm
                        placement='bottomRight'
                        title='Apakah yakin ingin menghapus minute meeting ?'
                        onConfirm={() => onHapus(dataIndex)}
                        okText='Hapus'
                        cancelText='Batal'>
                        <Button
                          negative
                          className='aksiHapus'
                          size='mini'
                          icon='trash'
                        />
                      </Popconfirm>
                    </Tooltip>
                  </>
                )}
            </Space>
          )}
          {loadingAct && (
            <Spin className='loading-data-table' indicator={antIcon} />
          )}
        </div>
      ),
    },
  ];

  return (
    <>
      {!auth && (
        <>
          <h1 className='loading-data'>
            <Spin indicator={antIcon} />
            <p>Mengambil data ...</p>
          </h1>
        </>
      )}
      {auth && (
        <div className='scrollable'>
          <Row justify='space-between'>
            {(auth.chairman_id == user.id || auth.secretary_id == user.id) &&
              auth.status !== 'Selesai' && (
                <Col md={9} sm={24} className='width-100'>
                  <div className=''>
                    <Form
                      form={form}
                      layout='vertical'
                      name='dynamic_form_nest_item'
                      onFinish={onFinish}
                      autoComplete='off'>
                      <Form.List name='users'>
                        {(fields, { add, remove }) => (
                          <>
                            {fields.map(
                              ({ key, name, fieldKey, ...restField }) => (
                                <>
                                  {agendaDD && (
                                    <Form.Item
                                      {...restField}
                                      name={[name, 'agenda_id']}
                                      labelAlign='left'
                                      label='Pilih Agenda'
                                      fieldKey={[fieldKey, 'agenda_id']}
                                      rules={[
                                        {
                                          required: true,
                                          message: 'Bagian ini wajib diisi',
                                        },
                                      ]}>
                                      <Select
                                        style={{ width: '100%' }}
                                        autoComplete='off'
                                        showSearch
                                        placeholder='Pilih agenda'
                                        optionFilterProp='label'
                                        onChange={onChange}
                                        onFocus={onFocus}
                                        onBlur={onBlur}
                                        onSearch={onSearch}>
                                        {agendaDD.map((val, item) => (
                                          <Option
                                            key={val.id}
                                            value={val.id}
                                            label={val.agenda}>
                                            {val.agenda}
                                          </Option>
                                        ))}
                                      </Select>
                                    </Form.Item>
                                  )}
                                  {anggota && (
                                    <Form.Item
                                      {...restField}
                                      name={[name, 'user_id']}
                                      labelAlign='left'
                                      label='Pilih Anggota'
                                      fieldKey={[fieldKey, 'user_id']}
                                      rules={[
                                        {
                                          required: true,
                                          message: 'Bagian ini wajib diisi',
                                        },
                                      ]}>
                                      <Select
                                        style={{ width: '100%' }}
                                        autoComplete='off'
                                        showSearch
                                        placeholder='Pilih anggota'
                                        optionFilterProp='label'
                                        onChange={onChange}
                                        onFocus={onFocus}
                                        onBlur={onBlur}
                                        onSearch={onSearch}>
                                        {anggota.map((val, item) => (
                                          <Option
                                            key={val.id}
                                            value={val.id}
                                            label={val.name}>
                                            {val.name}
                                          </Option>
                                        ))}
                                      </Select>
                                    </Form.Item>
                                  )}
                                  <Form.Item
                                    {...restField}
                                    label='Minute Meeting'
                                    name={[name, 'notes']}
                                    fieldKey={[fieldKey, 'notes']}
                                    rules={[
                                      {
                                        required: true,
                                        message: 'Bagian ini wajib diisi',
                                      },
                                    ]}>
                                    <TextArea rows={7} />
                                  </Form.Item>

                                  <MinusCircleOutlined
                                    onClick={() => remove(name)}
                                  />
                                </>
                              )
                            )}

                            <Form.Item style={{ marginTop: '20px' }}>
                              <BA
                                type='dashed'
                                onClick={() => add()}
                                block
                                icon={<PlusOutlined />}>
                                Tambah Minute Meeting
                              </BA>
                            </Form.Item>
                          </>
                        )}
                      </Form.List>
                      <Form.Item>
                        <Button
                          size='small'
                          color='green'
                          type='submit'
                          loading={loadingMinute}>
                          Submit
                        </Button>
                      </Form.Item>
                    </Form>
                  </div>
                </Col>
              )}

            <Col
              md={
                (auth.chairman_id == user.id || auth.secretary_id == user.id) &&
                auth.status !== 'Selesai'
                  ? 14
                  : 24
              }
              sm={24}>
              <div className='' style={{ minHeight: '500px' }}>
                <Row justify='space-between' style={{ marginBottom: '20px' }}>
                  <Col md={12} sm={24}>
                    <Space>
                      <Button
                        secondary
                        className='btn-hapus-filter'
                        type='danger'
                        onClick={clearFilters}>
                        Hapus Filter
                      </Button>
                    </Space>
                  </Col>
                  <Col
                    md={12}
                    sm={24}
                    style={{ width: '100%' }}
                    className='mt-mobile'>
                    <Input
                      value={searchText}
                      style={{ width: '100%' }}
                      placeholder='Cari minute meeting disini'
                      onChange={onChangeSearch}
                    />
                  </Col>
                </Row>

                <Table
                  // onChange={onChangeTable}
                  // pagination={false}
                  loading={loading}
                  loadingIndicator={antIcon}
                  columns={columns}
                  dataSource={minuteM}
                  scroll={{ x: 200, y: 575 }}
                  size='small'
                />
                {pagination && (
                  <div className='pagination'>
                    <Pagination
                      total={pagination.total}
                      showTotal={(total, range) =>
                        `Menampilkan ${range[0]}-${range[1]} dari ${total} data`
                      }
                      defaultPageSize={Number(pagination.perPage)}
                      current={pagination.current}
                      onChange={onChangePagination}
                    />
                  </div>
                )}
              </div>
            </Col>
          </Row>
        </div>
      )}
      <Modal
        title='Ubah Minute Meeting'
        visible={modalVisible}
        onCancel={handleCancel}
        footer={[]}>
        <Form
          layout='vertical'
          form={formEdit}
          name='basic'
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}>
          {agendaDD && (
            <Form.Item
              labelAlign='left'
              label='Pilih Agenda'
              name='agenda'
              rules={[
                {
                  required: true,
                  message: 'Bagian ini wajib diisi',
                },
              ]}>
              <Select
                style={{ width: '100%' }}
                autoComplete='off'
                showSearch
                placeholder='Pilih agenda'
                optionFilterProp='label'
                onChange={onChange}
                onFocus={onFocus}
                onBlur={onBlur}
                onSearch={onSearch}>
                {agendaDD.map((val, item) => (
                  <Option key={val.id} value={val.id} label={val.agenda}>
                    {val.agenda}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          )}
          {anggota && (
            <Form.Item labelAlign='left' label='Pilih Anggota' name='anggota'>
              <Select
                style={{ width: '100%' }}
                autoComplete='off'
                showSearch
                placeholder='Pilih Anggota'
                optionFilterProp='label'
                onChange={onChange}
                onFocus={onFocus}
                onBlur={onBlur}
                onSearch={onSearch}>
                {anggota.map((val, item) => (
                  <Option key={val.id} value={val.id} label={val.name}>
                    {val.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          )}
          <Form.Item labelAlign='left' label='Keterangan' name='keterangan'>
            <TextArea placeholder='Masukan Keterangan Rapat' rows={6} />
          </Form.Item>
          <Form.Item>
            <Button
              color='green'
              size='small'
              floated='right'
              type='submit'
              htmlType='submit'
              loading={loading}>
              Ubah
            </Button>
            <Button
              negative
              type='button'
              size='small'
              floated='right'
              loading={loading}
              onClick={onReset}>
              Reset
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default DetailRapat;
