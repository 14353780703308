import React, { useState, useContext, useEffect } from 'react';

import { Layout } from 'antd';

import { UserContext } from '../context/UserContext';
import MenuCustom from './MenuCustom.jsx';

import UserMale from '../assets/img/male_avatar.svg';
import UserFemale from '../assets/img/female_avatar.svg';

import './Layout.css';

const { Sider } = Layout;

const MySidebar = ({ sendDataToParent }) => {
  const [user] = useContext(UserContext);
  const [userName, setUserName] = useState(null);
  const [roleOffice, setRoleOffice] = useState('');
  const [roleUnit, setRoleUnit] = useState('');
  const [collapsed, setCollapsed] = useState(false);

  useEffect(() => {
    if (user) {
      getRoleUnit();
      getRoleOffice();
      let name = user.nama;
      let userLength = name.length;
      if (collapsed === false) {
        if (userLength > 18) {
          const nameSplit = name.split(' ');
          var temp = '';
          for (var i = 0; i < nameSplit.length; i++) {
            if (temp.length < 18) {
              temp += ' ' + nameSplit[i];
            }
          }
          setUserName(temp);
        } else {
          setUserName(name);
        }
      } else {
        if (userLength > 5) {
          const nameSplit = name.split(' ');
          setUserName(`${nameSplit[0]}`);
        } else {
          setUserName(name);
        }
      }
    }
  }, [collapsed, user]);

  const getRoleUnit = () => {
    var department;
    if (user.department) {
      department = user.department.replace('Departemen', '');
    }

    const role =
      user.role_unit === 'kepala_tu'
        ? `Kepala TU -  ${user.unit}`
        : user.role_unit === 'karyawan'
        ? `Karyawan -  ${user.unit}`
        : user.role_unit === 'dekan'
        ? `Dekan -  ${user.unit}`
        : user.role_unit === 'kadep'
        ? `Kadep -  ${user.unit} - ${department}`
        : user.role_unit === 'sekprodi'
        ? `Sekprodi -  ${user.unit} - ${department}`
        : user.role_unit === 'kalab'
        ? `Kalab -  ${user.unit} - ${department}`
        : user.role_unit === 'laboran'
        ? `Laboran -  ${user.unit} - ${department}`
        : user.role_unit === 'wadek'
        ? `Wadek -  ${user.unit} - ${department}`
        : user.role_unit === 'kaprodi'
        ? `Kaprodi -  ${user.unit} - ${department}`
        : user.role_unit === 'dosen'
        ? `Dosen -  ${user.unit} - ${department}`
        : ``;
    setRoleUnit(role);
  };
  const getRoleOffice = () => {
    const role =
      user.role_kantor === 'admin'
        ? `Admin -  ${user.kantor}`
        : user.role_kantor === 'karyawan'
        ? `Karyawan -  ${user.kantor}`
        : user.role_kantor === 'kepalakantor'
        ? `Kepala Kantor -  ${user.kantor}`
        : ``;
    setRoleOffice(role);
  };

  const onCollapse = () => {
    setCollapsed(!collapsed);
    sendDataToParent(!collapsed);
  };

  return (
    <>
      {user && (
        <>
          {userName && (
            <Sider
              collapsible
              onCollapse={onCollapse}
              theme='dark'
              className='sider-menu'
              collapsed={collapsed}
              width='220px'>
              <div className='userId'>
                <div className={collapsed ? 'imgProfilCollapsed' : 'imgProfil'}>
                  {user && (
                    <>
                      {user.gender !== 'Laki-laki' ? (
                        <img src={UserFemale} alt='user.png' />
                      ) : (
                        <img src={UserMale} alt='user.png' />
                      )}
                    </>
                  )}
                </div>
                <div>
                  <p
                    style={{
                      marginTop: collapsed ? '20px' : 0,
                      marginBottom: collapsed ? 0 : '15px',
                      fontWeight: 'bold',
                      lineHeight: collapsed ? '16px' : '0px',
                      fontSize: collapsed ? '12px' : '15px',
                      width: '100%',
                      overflow: 'visible',
                      textOverflow: 'ellipsis',
                    }}>
                    {userName}
                  </p>
                  {!collapsed && (
                    <>
                      <div>
                        {user.kantor && roleOffice && (
                          <p
                            style={{
                              lineHeight: '4px',
                              fontSize: '12px',
                              marginBottom: '12px',
                            }}
                            className='subTitle'>
                            {roleOffice}
                          </p>
                        )}
                      </div>
                      <div>
                        {user.role === 'superadmin' && (
                          <p
                            style={{
                              lineHeight: '4px',
                              fontSize: '12px',
                              marginBottom: '12px',
                            }}
                            className='subTitle'>
                            Superadmin
                          </p>
                        )}
                      </div>

                      <div>
                        {user.unit && roleUnit && (
                          <p
                            style={{
                              lineHeight: '4px',
                              fontSize: '12px',
                            }}
                            className='subTitle'>
                            {roleUnit}
                          </p>
                        )}
                      </div>
                    </>
                  )}
                </div>
              </div>
              <MenuCustom />
            </Sider>
          )}
        </>
      )}
    </>
  );
};

export default MySidebar;
