import React, { useContext } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { UserContext } from '../context/UserContext';
import Karyawan from '../page/Karyawan/Karyawan';
import Login from '../page/Login/Login.jsx';
import KelolaRuang from '../page/SuperAdmin/Ruang/Ruang';

import Profil from '../page/Profil/Profil';
import Tim from '../page/Team/Team';
import EditTimMain from '../page/Team/EditTimMain';

import Rapat from '../page/UserPage/Rapat/Rapat';
import DetailRapat from '../page/UserPage/Rapat/DetailRapat';
import Penugasan from '../page/UserPage/Penugasan/Penugasan';
import RapatTerdekat from '../page/UserPage/RapatTerdekat/RapatTerdekat';
import ProfilKaryawan from '../page/UserPage/Profil/ProfilMain';
import UbahStatus from '../page/Team/ComponentTim/UbahStatus';

import Pencarian from '../page/Pencarian/Pencarian';

import NotFound from '../page/404';
import Lokasi from '../page/SuperAdmin/Lokasi/Lokasi';
import Jabatan from '../page/Admin/Jabatan/Jabatan';
import LihatTamu from '../page/Admin/Tamu/Tamu';

import TamuKepala from '../page/Kepala/Tamu/Tamu';
import Kantor from '../page/SuperAdmin/KantorUnitSubUnit/Kantor';
import Unit from '../page/SuperAdmin/KantorUnitSubUnit/Unit';
import InfoRuang from '../page/InfoRuang/InfoRuang';
import DokumenSKOnly from '../page/Team/ComponentTim/DokumenSKOnly';
import ShowTim from '../page/Team/ComponentTim/ShowTim';
import InfoRapat from '../page/InfoRapat/InfoRapat';
import InfoRapatMain from '../page/InfoRapat/InfoRapatMain';

const ContentCustom = () => {
  const [user] = useContext(UserContext);
  const AuthRoute = ({ ...props }) => {
    if (user && user.role === 'superadmin') {
      return <Redirect to='/' />;
    } else if (user && user.role !== 'superadmin') {
      return <Redirect to='/profilUser' />;
    } else {
      return <Route {...props} />;
    }
  };

  const PrivateRoute = ({ ...props }) => {
    if (!user) {
      return <Redirect to='/login' />;
    } else {
      return <Route {...props} />;
    }
  };

  const SuperAdminRoute = ({ ...props }) => {
    if (!user) {
      return <Redirect to='/login' />;
    } else if (user.role === 'superadmin') {
      return <Route {...props} />;
    } else {
      return <Redirect to='/profilUser' />;
    }
  };

  const KaryawanRoute = ({ ...props }) => {
    if (!user) {
      return <Redirect to='/login' />;
    } else if (user.role !== 'superadmin') {
      return <Route {...props} />;
    } else {
      return <Redirect to='/' />;
    }
  };

  return (
    <div style={{ zIndex: -99 }}>
      <Switch>
        <AuthRoute exact path='/login' component={Login} />

        {/* Super Admin */}
        <SuperAdminRoute exact path='/' component={ProfilKaryawan} />
        <SuperAdminRoute exact path='/lokasi' component={Lokasi} />
        <SuperAdminRoute exact path='/kelolaRuang' component={KelolaRuang} />
        <SuperAdminRoute exact path='/kantor' component={Kantor} />
        <SuperAdminRoute exact path='/unit' component={Unit} />

        {/* !Superadmin */}
        <KaryawanRoute exact path='/profilUser' component={ProfilKaryawan} />
        <KaryawanRoute exact path='/karyawan' component={Karyawan} />
        <KaryawanRoute exact path='/tamu' component={TamuKepala} />
        <KaryawanRoute exact path='/jabatan' component={Jabatan} />
        <KaryawanRoute exact path='/tamuSaran' component={LihatTamu} />

        <KaryawanRoute exact path='/tim' component={Tim} />
        <KaryawanRoute exact path='/tim/rapat/:idRapat' component={Rapat} />
        <KaryawanRoute
          exact
          path='/tim/editTim/:idTim'
          component={EditTimMain}
        />
        <KaryawanRoute exact path='/tim/lihatTim/:idTim' component={ShowTim} />
        <KaryawanRoute
          exact
          path='/tim/kelolaSK/:idTim'
          component={DokumenSKOnly}
        />
        <KaryawanRoute
          exact
          path='/tim/rapat/detailRapat/:idRapat'
          component={DetailRapat}
        />
        <KaryawanRoute
          exact
          path='/tim/ubahStatusTim/:idTim'
          component={UbahStatus}
        />
        <KaryawanRoute exact path='/rapatTerdekat' component={RapatTerdekat} />
        <KaryawanRoute exact path='/infoRuang' component={InfoRuang} />
        <KaryawanRoute exact path='/infoRapat' component={InfoRapatMain} />
        <KaryawanRoute exact path='/tugas' component={Penugasan} />
        <KaryawanRoute exact path='/pencarian' component={Pencarian} />

        <Route component={NotFound} />
      </Switch>
    </div>
  );
};

export default ContentCustom;
