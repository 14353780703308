import React, { useContext } from 'react';
import myAxios from '../../../myAxios';
import { Spin, message } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

import { useState, useEffect } from 'react';
import { UserContext } from '../../../context/UserContext';

import AgendaCalender from 'react-agenda-calendar';
import 'react-agenda-calendar/dist/index.css';
import { useHistory } from 'react-router-dom';

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
const tableLoading = {
  indicator: <Spin className='loading-data-table' indicator={antIcon} />,
};

const RapatTerdekat = () => {
  const [user, setUser] = useContext(UserContext);
  const [loading, setLoading] = useState(false);

  const [agenda, setAgenda] = useState(false);
  let { history } = useHistory;
  useEffect(() => {
    if (!agenda) {
      getRapatTerdekat();
    }
  }, []);

  const getRapatTerdekat = () => {
    setLoading(true);
    myAxios
      .get(`individual-meeting`, {
        headers: {
          Authorization: 'Bearer ' + user.token,
        },
      })
      .then((res) => {
        const data = res.data;

        let myAgenda = [],
          temp;
        if (data === []) {
          setAgenda([]);
        } else {
          data.data.map((e) => {
            var today = new Date(e.date);
            var dd = String(today.getDate()).padStart(2, '0');
            var mm = String(today.getMonth() + 1).padStart(2, '0');
            var yyyy = today.getFullYear();

            temp = {
              title: e.name + ' | ' + e.time,
              startDate: {
                day: Number(dd),
                month: Number(mm) - 1,
                year: Number(yyyy),
              },
            };
            myAgenda.push(temp);
            temp = null;
          });
          setAgenda(myAgenda);
        }
        setLoading(false);
      })
      .catch((err) => {
        if (err.response.data.message === 'unauthorized') {
          message.error('Sesi anda telah habis!');
          localStorage.removeItem('user');
          setUser(null);
        } else if (err.response.data.msg === 'Unauthorization Role') {
          message.error(err.response.data.msg);
          history.goBack();
        } else {
          message.error(err.response.data.message);
        }
        setLoading(false);
      });
  };

  return (
    <div className='container-content rapatTerdekat'>
      <div className='container-table'>
        <h1 className='table-title'>RAPAT TERDEKAT</h1>
        {loading === true && (
          <h1 className='loading-data'>
            <Spin indicator={antIcon} />
            <p>Mengambil data..</p>
          </h1>
        )}
        {agenda && (
          <div className='scrollable-rapat-terdekat'>
            <AgendaCalender
              containerStyle={{ height: 'calc(100% - 51x)' }}
              agenda={agenda}
              currentDate={new Date()}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default RapatTerdekat;
