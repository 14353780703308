import React, { useContext } from 'react';
import myAxios from '../../../myAxios';
import { Table, Spin, message, Form, Input } from 'antd';
import { Button } from 'semantic-ui-react';
import { LoadingOutlined } from '@ant-design/icons';

import { useState, useEffect } from 'react';
import { UserContext } from '../../../context/UserContext';
import { useHistory, useParams } from 'react-router-dom';

// import "./Team.css";
const { TextArea } = Input;
const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
const tableLoading = {
  indicator: <Spin className='loading-data-table' indicator={antIcon} />,
};

const UbahStatus = () => {
  const [user, setUser] = useContext(UserContext);
  const [anggota, setAnggota] = useState(null);

  const [loadingTable, setLoadingTable] = useState(false);
  const [loading, setLoading] = useState(false);
  const [instance, setInstance] = useState(null);

  const [condition, setCondition] = useState(null);

  const [form] = Form.useForm();

  let history = useHistory();
  const { idTim } = useParams();

  useEffect(() => {
    if (!anggota) {
      getTim();
    }
  }, [anggota]);

  const getTim = () => {
    setLoadingTable(tableLoading);
    myAxios
      .get(`team/${idTim}`, {
        headers: {
          Authorization: 'Bearer ' + user.token,
        },
      })
      .then((res) => {
        const data = res.data.data;
        if (data !== null) {
          setInstance(data.data);
          setAnggota(data.member);
          form.setFieldsValue({
            nama_tim: data.data.name,
            nama_ketua: data.ketua.name,
            nama_sekretaris: data.sekretaris.name,
            link_berkas: data.data.report_link,
          });

          setCondition(data.data.status);
        } else {
          setAnggota([]);
        }
        setLoadingTable(false);
      })
      .catch((err) => {
        if (err.response.data.message === 'unauthorized') {
          message.error('Sesi anda telah habis!');
          localStorage.removeItem('user');
        } else if (err.response.data.message === 'Data Tim Tidak Ditemukan') {
          message.error(err.response.data.message);
          history.goBack();
        } else if (err.response.data.msg === 'Unauthorization Role') {
          message.error(err.response.data.msg);
          history.goBack();
        } else {
          message.error(err.response.data.message);
        }
        setAnggota([]);
        setLoadingTable(false);
      });
  };

  const onFinish = (values) => {
    setLoading(true);
    let newObj = {
      team_id: instance.id,
      report_link: values.link_berkas,
    };
    myAxios
      .put(`finish-team`, newObj, {
        headers: {
          Authorization: 'Bearer ' + user.token,
        },
      })
      .then((res) => {
        message.success(res.data.msg);
        setLoading(false);
        getTim();
        history.goBack();
      })
      .catch((err) => {
        if (err.response.data.message === 'unauthorized') {
          message.error('Sesi anda telah habis!');
          localStorage.removeItem('user');
          setUser(null);
        } else if (err.response.data.msg === 'Unauthorization Role') {
          message.error(err.response.data.msg);
          history.goBack();
        } else {
          message.error(err.response.data.msg);
        }
        setLoading(false);
      });
  };

  const onFinishFailed = (errorInfo) => {};

  const columns = [
    {
      title: 'Nama',
      dataIndex: 'name',
      key: 'name',
      sorter: (a, b) => a.name.length - b.name.length,
      ellipsis: true,
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      sorter: (a, b) => a.email.length - b.email.length,
      ellipsis: true,
    },
    {
      title: 'NIP',
      dataIndex: 'username',
      key: 'username',
      sorter: (a, b) => a.username.length - b.npp.length,
      ellipsis: true,
    },
  ];

  return (
    <div className='container-content'>
      <div className='container-detail'>
        <h1 className='table-title'>UBAH STATUS TIM</h1>
        {loadingTable && !anggota && (
          <h1 className='loading-data'>
            <Spin indicator={antIcon} />
            <p>Mengambil data..</p>
          </h1>
        )}
        {anggota && (
          <div className='scrollable'>
            <Table
              style={{ marginBottom: '50px' }}
              loading={loadingTable}
              loadingIndicator={antIcon}
              columns={columns}
              dataSource={anggota}
              scroll={{ x: 750, y: 2500 }}
              pagination={false}
              size='small'
            />
            <Form
              layout='vertical'
              form={form}
              name='basic'
              initialValues={{ remember: false }}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}>
              <Form.Item labelAlign='left' label='Nama Tim' name='nama_tim'>
                <Input disabled />
              </Form.Item>

              <Form.Item labelAlign='left' label='Nama Ketua' name='nama_ketua'>
                <Input disabled />
              </Form.Item>

              <Form.Item
                labelAlign='left'
                label='Nama Sekretaris'
                name='nama_sekretaris'>
                <Input disabled />
              </Form.Item>

              <Form.Item
                labelAlign='left'
                label='Link Berkas'
                name='link_berkas'
                rules={[{ required: true, message: 'Bagian ini wajib diisi' }]}>
                <TextArea placeholder='Masukan link berkas' />
              </Form.Item>

              <Form.Item>
                <Button
                  floated='right'
                  type='submit'
                  color='green'
                  htmlType='submit'
                  loading={loading}>
                  {(condition === 'Menunggu Verifikasi' &&
                    user.role_unit === 'kadep' &&
                    instance.teamable_type ===
                      'App\\Models\\SubUnit\\SubUnit') ||
                  (user.role_kantor === 'kepalakantor' &&
                    instance.teamable_type === 'App\\Models\\Office\\Office') ||
                  (user.role_unit === 'dekan' &&
                    instance.teamable_type === 'App\\Models\\Unit\\Unit') ||
                  (user.role_unit === 'kepala_tu' &&
                    instance.teamable_type === 'TU')
                    ? 'Verifikasi'
                    : 'Simpan'}
                </Button>
                {condition !== 'Menunggu Verifikasi' &&
                  user.role !== 'Kepala Departemen' && (
                    <Button
                      negative
                      floated='right'
                      type='button'
                      onClick={() => form.setFieldsValue({ link_berkas: null })}
                      loading={loading}>
                      Reset
                    </Button>
                  )}
              </Form.Item>
            </Form>
          </div>
        )}
      </div>
    </div>
  );
};

export default UbahStatus;
