import React from "react";
import Error from "../assets/img/404.svg";
import { motion } from "framer-motion";
import { useHistory } from "react-router-dom";

const NotFound = () => {
  let history = useHistory();
  return (
    <div className="container-content karyawan">
      <div className="container-table">
        <div style={{ margin: "35px" }}>
          <motion.img
            initial={{ opacity: 0, y: -100 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 1 }}
            className="imgDiv"
            style={{
              width: "100%",
              height: "250px",
              display: "flex",
              justifyContent: "center",
            }}
            src={Error}
            alt="404.svg"
          />

          <p
            style={{
              textAlign: "center",
              marginTop: "25px",
              lineHeight: "0",
            }}
          >
            <motion.h3
              initial={{ opacity: 0, x: -25 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.6, delay: 0.6 }}
            >
              404 Error: Halaman tidak ditemukan
            </motion.h3>
            <motion.p
              style={{
                lineHeight: "0",
              }}
              initial={{ opacity: 0, x: 25 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.6, delay: 0.6 }}
            >
              <a onClick={() => history.goBack()}>Kembali</a>
            </motion.p>
          </p>
        </div>
      </div>
    </div>
  );
};

export default NotFound;
