import React, { useState, createContext, useContext } from 'react';
import myAxios from '../myAxios';
import { message } from 'antd';
import { UserContext } from './UserContext';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
export const PenugasanContext = createContext();

export const PenugasanProvider = (props) => {
  const [user, setUser] = useContext(UserContext);
  const [penugasan, setPenugasan] = useState(null);
  let { history } = useHistory;
  useEffect(() => {
    getCountPenugasan();
  }, [user]);

  const updatePenugasan = async () => {
    await getCountPenugasan();
    return penugasan;
  };
  const getCountPenugasan = async () => {
    if (user) {
      if (user.role !== 'superadmin') {
        await myAxios
          .get(`count-task/${user.id}`, {
            headers: {
              Authorization: 'Bearer ' + user.token,
            },
          })
          .then((res) => {
            const data = res.data.data;
            if (data === 0) {
              setPenugasan(null);
            } else {
              setPenugasan(Number(data));
            }
          })
          .catch((err) => {
            if (err.response.data.message === 'unauthorized') {
              message.error('Sesi anda telah habis!');
              localStorage.removeItem('user');
              setUser(null);
            } else if (err.response.data.msg === 'Unauthorization Role') {
              message.error(err.response.data.msg);
              // history.goBack();
            } else {
              console.log(err);
            }
          });
      }
    }
  };

  return (
    <PenugasanContext.Provider value={[penugasan, updatePenugasan]}>
      {props.children}
    </PenugasanContext.Provider>
  );
};
