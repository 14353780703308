import React, { useState, useContext, useEffect } from 'react';
import myAxios from '../myAxios';
import { Layout, Menu, Drawer, message } from 'antd';
import { Link } from 'react-router-dom';

import {
  PersonFill,
  PeopleFill,
  Diagram3Fill,
  ClipboardMinus,
  BoxArrowLeft,
  JournalText,
  Stack,
  PersonLinesFill,
  Search,
  Building,
  GeoAlt,
  Wrench,
  BriefcaseFill,
  InfoSquare,
} from 'react-bootstrap-icons';
import { Button } from 'semantic-ui-react';
import { UserContext } from '../context/UserContext';

import './Layout.css';
import ComponentNew from './ComponentNew';
import { PenugasanContext } from '../context/PenugasanContext';
import { TimContext } from '../context/TimVerifikasiContext';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';

const { Header } = Layout;

const HeaderCustom = () => {
  const [visible, setVisible] = useState(false);
  const [user, setUser] = useContext(UserContext);
  const [userName, setUserName] = useState(null);
  const [penugasan] = useContext(PenugasanContext);
  const [defKey, setKey] = useState(null);
  const [tim] = useContext(TimContext);
  const [roleOffice, setRoleOffice] = useState('');
  const [roleUnit, setRoleUnit] = useState('');
  let Loc = useLocation();

  const Logout = () => {
    message.success('Berhasil keluar!');
    setUser(null);
    localStorage.removeItem('user');
  };

  const getRoleUnit = () => {
    var department;
    if (user.department) {
      department = user.department.split('Departemen', 2);
      department = department[1];
    }
    const role =
      user.role_unit === 'kepala_tu'
        ? `Kepala TU -  ${user.unit}`
        : user.role_unit === 'karyawan'
        ? `Karyawan -  ${user.unit}`
        : user.role_unit === 'dekan'
        ? `Dekan -  ${user.unit}`
        : user.role_unit === 'kadep'
        ? `Kadep -  ${user.unit}`
        : user.role_unit === 'kalab'
        ? `Kalab -  ${user.unit} - ${department}`
        : user.role_unit === 'laboran'
        ? `Laboran -  ${user.unit} - ${department}`
        : user.role_unit === 'wadek'
        ? `Wadek -  ${user.unit} - ${department}`
        : user.role_unit === 'kaprodi'
        ? `Kaprodi -  ${user.unit} - ${department}`
        : user.role_unit === 'dosen'
        ? `Dosen -  ${user.unit} - ${department}`
        : ``;
    setRoleUnit(role);
  };
  const getRoleOffice = () => {
    const role =
      user.role_kantor === 'admin'
        ? `Admin -  ${user.kantor}`
        : user.role_kantor === 'karyawan'
        ? `Karyawan -  ${user.kantor}`
        : user.role_kantor === 'kepalakantor'
        ? `Kepala Kantor -  ${user.kantor}`
        : ``;
    setRoleOffice(role);
  };

  useEffect(() => {
    getRoleUnit();
    getRoleOffice();
    if (user) {
      let name = user.nama;
      let userLength = name.length;
      if (userLength > 27) {
        const nameSplit = name.split(' ');
        var temp = '';
        for (var i = 0; i < nameSplit.length; i++) {
          if (temp.length < 27) {
            temp += ' ' + nameSplit[i];
          }
        }
        setUserName(temp);
      } else {
        setUserName(name);
      }
    }

    let location = Loc.pathname;
    let tempPath = location.split('/', 2);
    let pathname = tempPath[1];
    setKey(pathname ? pathname : '/');
  }, [Loc.pathname]);

  const menuDrawer = (
    <div className='drawer-custom'>
      {/* MENU SUPERADMIN  */}
      {user.role === 'superadmin' && (
        <>
          {defKey && (
            <Menu theme='dark' mode='inline' defaultSelectedKeys={[defKey]}>
              <Menu.Item key='/' icon={<PersonFill />} style={{ margin: 0 }}>
                <Link className='link' to='/'>
                  Profil
                </Link>
              </Menu.Item>

              <Menu.Item key='kantor' icon={<Building />}>
                <Link className='link' to='/kantor'>
                  Kantor
                </Link>
              </Menu.Item>

              <Menu.Item key='unit' icon={<Building />}>
                <Link className='link' to='/unit'>
                  Unit
                </Link>
              </Menu.Item>

              <Menu.Item key='lokasi' icon={<GeoAlt />}>
                <Link className='link' to='/lokasi'>
                  Lokasi
                </Link>
              </Menu.Item>

              <Menu.Item key='kelolaRuang' icon={<Diagram3Fill />}>
                <Link className='link' to='/kelolaRuang'>
                  Ruang
                </Link>
              </Menu.Item>
            </Menu>
          )}
        </>
      )}
      {/* MENU !SUPERADMIN */}
      {defKey && (
        <>
          {user.role !== 'superadmin' && (
            <Menu theme='dark' mode='inline' defaultSelectedKeys={[defKey]}>
              <Menu.Item
                key='profilUser'
                icon={<PersonFill />}
                style={{ margin: 0 }}>
                <Link className='link' to='/profilUser'>
                  Profil
                </Link>
              </Menu.Item>
              {user.role_unit === 'kadep' ||
              user.role_unit === 'dekan' ||
              user.role_unit === 'kepala_tu' ||
              user.role_kantor === 'kepalakantor' ? (
                <Menu.Item key='tamu' icon={<PersonLinesFill />}>
                  <Link className='link' to='/tamu'>
                    Tamu
                  </Link>
                </Menu.Item>
              ) : (
                <Menu.Item key='tamuSaran' icon={<PersonLinesFill />}>
                  <Link className='link' to='/tamuSaran'>
                    Tamu
                  </Link>
                </Menu.Item>
              )}
              {(user.role_kantor === 'admin' ||
                user.role_unit === 'kepala_tu') && (
                <Menu.Item key='karyawan' icon={<PeopleFill />}>
                  <Link className='link' to='/karyawan'>
                    Karyawan
                  </Link>
                </Menu.Item>
              )}
              {user.role_kantor === 'admin' && (
                <Menu.Item key='jabatan' icon={<Diagram3Fill />}>
                  <Link className='link' to='/jabatan'>
                    Jabatan
                  </Link>
                </Menu.Item>
              )}
              <Menu.Item key='tim' icon={<ClipboardMinus />}>
                <Link className='link' to='/tim'>
                  Tim
                  {tim > 0 && <ComponentNew status='Tim Mobile' />}
                </Link>
              </Menu.Item>
              <Menu.Item key='tugas' icon={<JournalText />}>
                <Link className='link' to='/tugas'>
                  Tugas
                  {penugasan > 0 && <ComponentNew status='Tugas Mobile' />}
                </Link>
              </Menu.Item>
              <Menu.Item key='rapatTerdekat' icon={<Stack />}>
                <Link className='link' to='/rapatTerdekat'>
                  Rapat
                </Link>
              </Menu.Item>
              <Menu.Item key='infoRuang' icon={<InfoSquare />}>
                <Link className='link' to='/infoRuang'>
                  Info Ruang
                </Link>
              </Menu.Item>
              <Menu.Item key='pencarian' icon={<Search />}>
                <Link className='link' to='/pencarian'>
                  Pencarian
                </Link>
              </Menu.Item>
            </Menu>
          )}
        </>
      )}
      {defKey && (
        <Menu theme='dark' mode='inline' defaultSelectedKeys={[defKey]}>
          <Menu.Item key='6' icon={<BoxArrowLeft />} style={{ margin: 0 }}>
            <Link className='link' onClick={Logout}>
              Keluar
            </Link>
          </Menu.Item>
        </Menu>
      )}
      <div className='info'>
        <p className='info-name'>{userName}</p>
        {user.role === 'superadmin' ? (
          <p className='info-role'>Superadmin</p>
        ) : (
          <>
            <p className='info-role'>{roleOffice}</p>
            <p className='info-role'>{roleUnit}</p>
          </>
        )}
      </div>
    </div>
  );
  return (
    <>
      <Header theme='light' className='site-layout-background'>
        <Button
          className='menuOutline'
          style={{
            backgroundColor: 'transparent',
            color: '#fff',
            //   marginLeft: "-20px",
          }}
          icon='list'
          onClick={() => setVisible(true)}></Button>
      </Header>
      <Drawer
        closable={false}
        title={'Sistem Manajemen Rapat'}
        placement='left'
        style={{ padding: 0 }}
        onClose={() => setVisible(false)}
        onClick={() => setVisible(false)}
        backgroundColor='#fff'
        visible={visible}>
        {menuDrawer}
      </Drawer>
    </>
  );
};

export default HeaderCustom;
