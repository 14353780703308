import React, { useContext, useState } from 'react';
import myAxios from '../../myAxios';

import { Form, Input, Row, Col, message } from 'antd';
import { Button, Container } from 'semantic-ui-react';
import { UserContext } from '../../context/UserContext';
import './Login.css';
import SvgLogin from '../../assets/img/uajy-logo.png';
import CryptoJS from 'crypto-js';
import { motion } from 'framer-motion';
import { PenugasanContext } from '../../context/PenugasanContext';
import { TimContext } from '../../context/TimVerifikasiContext';
import InfoDemo from '../../layout/InfoDemo';
import ManualBook from '../../assets/ManualBook.pdf';

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

const Login = () => {
  const [user, setUser] = useContext(UserContext);
  const [penugasan, getCountPenugasan] = useContext(PenugasanContext);
  const [tim, updateTim] = useContext(TimContext);
  const [loading, setLoading] = useState(false);

  const [form] = Form.useForm();

  const onFinish = (values) => {
    setLoading(true);
    const inputData = {
      username: values.email,
      password: values.password,
    };

    myAxios
      .post('login', inputData)
      .then((res) => {
        var user = res.data.data.user;
        var id,
          nama,
          npp,
          email,
          token,
          role_unit,
          role_kantor,
          role,
          kantor,
          unit,
          gender = 'Laki-laki',
          department;

        id = user.id;
        nama = user.name;
        token = res.data.data.token;
        role = user.role_name ? user.role_name : user.name;
        role_unit = user.role_name;
        role_kantor = user.office_role_type;
        kantor = user.office_name;
        unit = user.faculty_code;
        department = user.department_name;

        if (user.detail !== null) {
          if (role !== 'superadmin') {
            gender =
              user.detail.gender === null ? 'Laki-laki' : user.detail.gender;
          }
        }

        // email = role === 'superadmin' ? '' : user.detail.email;
        npp = user.username;
        var currentUser = {
          nama,
          role,
          role_unit,
          role_kantor,
          token,
          id,
          npp,
          email,
          kantor,
          unit,
          department,
          gender,
        };
        // enkripsi local storage
        let cipherText = CryptoJS.AES.encrypt(
          JSON.stringify(currentUser),
          'datauser'
        ).toString();
        setUser(currentUser);
        localStorage.setItem('user', cipherText);
        setLoading(false);
        message.success('Selamat Datang, ' + nama + '!');
        getCountPenugasan();
        updateTim();
      })
      .catch((err) => {
        message.error(err.response.data.msg);
        setLoading(false);
      });
  };

  const onFinishFailed = (errorInfo) => {};

  return (
    <div className='containerLogin'>
      <motion.div
        initial={{ opacity: 0, y: 50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.6 }}
        className='content'>
        <Row justify='center' className='containerRow'>
          <Col md={12} className='containerCol containerSvg'>
            <Container className='left-div' textAlign={'center'}>
              <motion.div
                initial={{ opacity: 0, x: -100 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ duration: 1, delay: 0.4 }}
                className='imgDiv'>
                <img
                  className='imgSvg'
                  src={SvgLogin}
                  alt='uajy-logo.png'></img>
                <h4 className='judul-left'>Sistem Manajemen Rapat</h4>
                <p className='content-left'>
                  Sistem ini digunakan untuk mengelola kegiatan rapat yang ada
                  di Universitas Atma Jaya Yogyakarta. Manual book dapat dilihat{' '}
                  <a
                    href={ManualBook}
                    target='_blank'
                    rel='noreferrer'
                    className='hover-download'>
                    disini
                  </a>
                </p>
              </motion.div>
            </Container>
          </Col>
          <Col md={12} sm={24} className='containerCol'>
            <div className='colDiv'>
              <motion.div
                initial={{ opacity: 0, y: -50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 1, delay: 0.4 }}
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}>
                <br />

                <h5 className='judul-login'>Login</h5>
                <h5 className='judul-login-mobile'>Sistem Rapat UAJY</h5>
              </motion.div>
              <motion.div
                initial={{ opacity: 0, y: 50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 1, delay: 0.4 }}>
                <Form
                  {...layout}
                  form={form}
                  name='basic'
                  initialValues={{ remember: false }}
                  onFinish={onFinish}
                  onFinishFailed={onFinishFailed}>
                  <Form.Item
                    justify='center'
                    name='email'
                    rules={[
                      {
                        required: true,
                        message: 'Masukan username yang valid!',
                      },
                    ]}>
                    <Input className='inputEmail' placeholder='Username' />
                  </Form.Item>
                  <Form.Item
                    justify='center'
                    name='password'
                    rules={[
                      {
                        required: true,
                        message: 'Bagian ini wajib diisi!',
                      },
                    ]}>
                    <Input.Password placeholder='Kata sandi' />
                  </Form.Item>

                  <Form.Item justify='center'>
                    <Button
                      fluid
                      style={{ marginTop: '20px' }}
                      type='primary'
                      color='green'
                      htmlType='submit'
                      loading={loading}>
                      Masuk
                    </Button>
                  </Form.Item>
                </Form>
                {/* <InfoDemo /> */}
              </motion.div>
            </div>
          </Col>
        </Row>
      </motion.div>
    </div>
  );
};

export default Login;
