import React from 'react';

import { Tabs } from 'antd';

import 'bootstrap/dist/css/bootstrap.min.css';
import ProfilKaryawan from './ProfilKaryawan';
import KataSandi from './KataSandi';

const ProfilMain = () => {
  const { TabPane } = Tabs;

  function callback(key) {
    localStorage.setItem('tab', key);
  }
  return (
    <div className='container-content'>
      <div className='container-detail' style={{ paddingTop: '5px' }}>
        <Tabs
          defaultActiveKey={
            !localStorage.getItem('tab') ? '1' : localStorage.getItem('tab')
          }
          onChange={callback}>
          <TabPane tab='Profil' key='1'>
            <h1 className='table-title'>INFORMASI AKUN</h1>
            <ProfilKaryawan />
          </TabPane>
          <TabPane tab='Kata Sandi' key='2'>
            <h1 className='table-title'>UBAH KATA SANDI</h1>
            <KataSandi />
          </TabPane>
        </Tabs>
      </div>
    </div>
  );
};

export default ProfilMain;
