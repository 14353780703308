import React, { useContext } from 'react';
import myAxios from '../../../../myAxios';
import {
  Table,
  Space,
  Spin,
  Row,
  Col,
  message,
  Input,
  Tooltip,
  Popconfirm,
  Form,
  Tag,
  Select,
  Modal,
  Button as BA,
  DatePicker,
  Pagination,
} from 'antd';
import { Button } from 'semantic-ui-react';
import {
  LoadingOutlined,
  MinusCircleOutlined,
  PlusOutlined,
} from '@ant-design/icons';

import moment from 'moment';
import { useState, useEffect } from 'react';
import { UserContext } from '../../../../context/UserContext';
import { useParams, useHistory } from 'react-router-dom';
import { ArrowUpRight } from 'react-bootstrap-icons';
import { PenugasanContext } from '../../../../context/PenugasanContext';
import { RapatContext } from '../../../../context/DataRapatContext';

const { TextArea } = Input;
const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
const tableLoading = {
  indicator: <Spin className='loading-data-table' indicator={antIcon} />,
};
const { Option } = Select;

const optionsDate = {
  weekday: 'long',
  year: 'numeric',
  month: 'long',
  day: 'numeric',
};
const Kesimpulan = () => {
  const [user, setUser] = useContext(UserContext);
  const [penugasan, updatePenugasan] = useContext(PenugasanContext);
  const [
    tempRapatData,
    setRapatData,
    detail,
    setDetail,
    anggotaRapat,
    setAnggotaRapat,
    tempAng,
    setTempAng,
    updateRapat,
  ] = useContext(RapatContext);

  const [modalCon, setModalCon] = useState(null);
  const [kesimpulan, setKesimpulan] = useState(null);
  const [tempData, setData] = useState(null);
  const [idEdit, setIdEdit] = useState(null);
  const [agendaDD, setAgendaDD] = useState([]);

  const [anggota, setAnggota] = useState(null);

  const [searchText, setSearch] = useState(null);
  const [auth, setAuth] = useState(null);

  const [loading, setLoading] = useState(false);
  const [loadingVerifikasi, setLoadingVerifikasi] = useState(false);
  const [loadingAct, setLoadingAct] = useState(false);
  const [loadingKesimpulan, setLoadingM] = useState(false);
  const [feedback, setFeedback] = useState(null);

  const [modalVisible, setModal] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const [pagination, setPagination] = useState(null);
  const [page, setPage] = useState('10');

  const [form] = Form.useForm();
  const [formEdit] = Form.useForm();
  const { idRapat } = useParams();
  let history = useHistory();
  const [matches, setMatches] = useState(
    window.matchMedia('(min-width:768px)').matches
  );
  useEffect(() => {
    function handleResize() {
      setMatches(window.matchMedia('(min-width: 768px)').matches);
    }
  }, []);
  useEffect(() => {
    if (!kesimpulan) getKesimpulan();
  }, [kesimpulan]);

  const getKesimpulan = (
    search = undefined,
    page = '1',
    sortBy = undefined,
    sorting = 'ASC',
    show = '10'
  ) => {
    setLoading(tableLoading);
    myAxios
      .get(`conclusion/${idRapat}`, {
        params: {
          page: Number(page),
          search: search,
          show: show,
          sortBy: sortBy,
          sorting: sorting,
        },
        headers: {
          Authorization: 'Bearer ' + user.token,
        },
      })
      .then((res) => {
        const data = res.data.data;

        if (data.data.data.length > 0) {
          setData(data.data.data);
          setKesimpulan(data.data.data);
        } else {
          setKesimpulan([]);
          setData([]);
          setAnggota([]);
        }
        setAgendaDD(data.agenda);
        setAnggota(data.anggota);
        setAuth(data.auth);
        setLoading(false);
        setPagination({
          total: res.data.data.data.total,
          current: res.data.data.data.current_page,
          perPage: res.data.data.data.per_page,
        });
      })
      .catch((err) => {
        if (err?.response.data.message === 'unauthorized') {
          message.error('Sesi anda telah habis!');
          localStorage.removeItem('user');
          setUser(null);
        } else if (err.response.data.message === 'Data Rapat Tidak Ditemukan') {
          history.goBack();
        } else if (err.response.data.msg === 'Unauthorization Role') {
          message.error(err.response.data.msg);
          history.goBack();
        } else {
          message.error(err.response.data.msg);
        }
        setKesimpulan([]);
        setLoading(false);
      });
  };

  const onHapus = (param) => {
    setLoadingAct(true);
    myAxios
      .delete(`conclusion/${param.id}`, {
        headers: {
          Authorization: 'Bearer ' + user.token,
        },
      })
      .then((res) => {
        getKesimpulan();
        updatePenugasan();
        message.success(res.data.msg);
        setLoadingAct(false);
      })
      .catch((err) => {
        if (err.response.data.message === 'unauthorized') {
          message.error('Sesi anda telah habis!');
          localStorage.removeItem('user');
          setUser(null);
        } else if (err.response.data.msg === 'Unauthorization Role') {
          message.error(err.response.data.msg);
          history.goBack();
        } else {
          message.error(err.response.data.msg);
        }
        setLoading(false);
      });
  };

  const clearFilters = () => {
    setSearch(null);
  };

  const onChangeSearch = (evt) => {
    setSearch(evt.target.value);
    if (tempData) {
      if (evt.target.value === '') {
        setKesimpulan(tempData);
      } else {
        setKesimpulan(
          tempData.filter((i) => {
            return (
              i.pic.toLowerCase().includes(evt.target.value.toLowerCase()) ||
              i.conclusion
                .toLowerCase()
                .includes(evt.target.value.toLowerCase()) ||
              i.status.toLowerCase().includes(evt.target.value.toLowerCase())
            );
          })
        );
      }
    }
  };

  const handleCancel = () => {
    setModal(false);
  };

  const handleCancelDetail = () => {
    setModalCon((prev) => {
      return {
        ...prev,
        visible: false,
      };
    });
  };

  const onFinish = (values) => {
    if (idEdit === null) {
      let tempCek = false;
      let myData = values.users;
      myData.map((e) => {
        if (e.user_id == user.id) tempCek = true;
      });
      setLoadingM(true);
      let users = values.users;
      users.map((e) => {
        if (e.user_id === undefined || e.user_id === '') {
          e.user_id = '-';
        }
        if (e.due_date !== undefined)
          e.due_date = moment(e.due_date).format('l');
        else e.due_date = '-';
      });

      let newObj = {
        meeting_id: idRapat,
        data: users,
      };
      myAxios
        .post(`conclusion`, newObj, {
          headers: {
            Authorization: 'Bearer ' + user.token,
          },
        })
        .then((res) => {
          setIsActive(false);
          setLoadingM(false);
          setModal(false);
          updatePenugasan();
          form.resetFields();
          getKesimpulan();
          updateRapat(idRapat);
          message.success(res.data.msg);
        })
        .catch((err) => {
          if (err.response.data.message === 'unauthorized') {
            message.error('Sesi anda telah habis!');
            localStorage.removeItem('user');
            setUser(null);
          } else if (err.response.data.msg === 'Unauthorization Role') {
            message.error(err.response.data.msg);
            history.goBack();
          } else {
            message.error(err.response.data.msg);
          }
          setLoadingM(false);
        });
    } else {
      setLoading(tableLoading);

      let newObj = {
        // meeting_id: idEdit.id_rapat,
        user_id: values.penanggung_jawab,
        conclusion: values.kesimpulan,
        agenda_id: values.agenda,
      };
      myAxios
        .put(`conclusion/${idEdit.id_kesimpulan}`, newObj, {
          headers: {
            Authorization: 'Bearer ' + user.token,
          },
        })
        .then((res) => {
          message.success(res.data.msg);
          updatePenugasan();
          setLoading(false);
          setModal(false);
          setIdEdit(null);
          form.resetFields();
          getKesimpulan();
        })
        .catch((err) => {
          if (err.response.data.message === 'unauthorized') {
            message.error('Sesi anda telah habis!');
            localStorage.removeItem('user');
            setUser(null);
          } else if (err.response.data.msg === 'Unauthorization Role') {
            message.error(err.response.data.msg);
            history.goBack();
          } else {
            message.error(err.response.data.msg);
          }
          setLoading(false);
        });
    }
  };

  const onFinishFailed = (errorInfo) => {};

  const onEditModal = (param) => {
    setModal(true);

    formEdit.setFieldsValue({
      agenda: param.agenda_id,
      penanggung_jawab: param.user_id,
      kesimpulan: param.conclusion,
      due_date: moment(param.due_date),
    });
    setIdEdit({
      id_kesimpulan: param.id,
      id_rapat: param.id_rapat,
      id_agenda: param.agenda_id,
      penanggung_jawab: param.user_id,
      kesimpulan: param.conclusion,
    });
  };

  const onReset = () => {
    formEdit.setFieldsValue({
      penanggung_jawab: idEdit.penanggung_jawab,
      kesimpulan: idEdit.kesimpulan,
    });
  };

  const onChange = (evt) => {
    if (evt === undefined) {
      setIsActive(false);
    } else {
      setIsActive(true);
    }
  };
  const onFocus = () => {};
  const onBlur = () => {};
  const onSearch = () => {};

  const onLihatDetail = (row) => {
    setModalCon({
      judul: 'Detail Kesimpulan',
      visible: true,
      data: row,
    });
  };

  const onSubmitFeedback = (param) => {
    setLoadingVerifikasi(true);

    const url = param.keputusan === 'Terima' ? 'verify-task' : 'decline-task';
    let newObj = {
      feedback: param.feedback,
    };
    myAxios
      .put(`${url}/${modalCon.data.id}`, newObj, {
        headers: {
          Authorization: 'Bearer ' + user.token,
        },
      })
      .then((res) => {
        setLoadingVerifikasi(false);
        getKesimpulan();
        setModalCon({ ...modalCon, visible: false });
        message.success(res.data.msg);
      })
      .catch((err) => {
        if (err.response.data.message === 'unauthorized') {
          message.error('Sesi anda telah habis!');
          localStorage.removeItem('user');
          setUser(null);
        } else if (err.response.data.msg === 'Unauthorization Role') {
          message.error(err.response.data.msg);
          history.goBack();
        } else {
          message.error(err.response.data.msg);
        }
        setLoadingVerifikasi(false);
      });
  };
  const onTolak = (param) => {
    setLoadingVerifikasi(true);
    myAxios
      .put(`tolakKesimpulan/${param}`, null, {
        headers: {
          Authorization: 'Bearer ' + user.token,
        },
      })
      .then((res) => {
        setLoadingVerifikasi(false);
        getKesimpulan();
        setModalCon({ ...modalCon, visible: false });
        message.success(res.data.message);
      })
      .catch((err) => {
        if (err.response.data.message === 'unauthorized') {
          message.error('Sesi anda telah habis!');
          localStorage.removeItem('user');
          setUser(null);
        } else if (err.response.data.msg === 'Unauthorization Role') {
          message.error(err.response.data.msg);
          history.goBack();
        } else {
          message.error(err.response.data.message);
        }
        setLoadingVerifikasi(false);
      });
  };
  const onChangeTable = (pagination, filters, sorter, extra) => {
    const order = sorter.order === 'descend' ? 'DESC' : 'ASC';
    const field = sorter.order === undefined ? undefined : sorter.field;
    getKesimpulan('', '1', field, order);
  };
  const onChangePagination = (evt, show) => {
    setPage(show);
    getKesimpulan('', evt, null, null, show);
  };
  const columns = [
    {
      title: 'Kesimpulan',
      dataIndex: 'conclusion',
      key: 'conclusion',
      sorter: (a, b) => a.conclusion.length - b.conclusion.length,
      ellipsis: true,
    },
    {
      title: 'Agenda',
      dataIndex: 'agenda',
      key: 'agenda',
      sorter: (a, b) => a.agenda.length - b.agenda.length,
      ellipsis: true,
    },
    {
      title: 'Tenggat Waktu',
      dataIndex: 'due_date',
      key: 'due_date',
      sorter: (a, b) => a.due_date.length - b.due_date.length,
      ellipsis: true,
      render: (text) =>
        text !== null ? (
          <>{new Date(text).toLocaleDateString('id-ID', optionsDate)}</>
        ) : null,
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      sorter: (a, b) => a.status.length - b.status.length,
      ellipsis: true,
      render: (text) =>
        text === 'Pending' ? (
          <Tag color='red'>{text}</Tag>
        ) : text === 'Selesai' ? (
          <Tag color='green'>{text}</Tag>
        ) : (
          <Tag color='orange'>{text}</Tag>
        ),
    },
    {
      title: 'Nama Anggota',
      dataIndex: 'pic',
      key: 'pic',
      sorter: (a, b) => a.pic.length - b.pic.length,
      ellipsis: true,
    },

    {
      key: 'action',
      dataIndex: 'id',
      align: 'center',
      render: (dataIndex, row) => (
        <div>
          {!loadingAct && (
            <Space size='middle'>
              <Tooltip
                placement='bottom'
                title='Lihat Detail'
                color='#1f1f1f'
                key='white'>
                <Button
                  onClick={() => onLihatDetail(row)}
                  color='orange'
                  className='aksiHapus'
                  size='mini'
                  icon={<ArrowUpRight />}
                />
              </Tooltip>

              {/* {row.status === "Menunggu Konfirmasi" &&
                Number(auth.chairman_id) === user.id && (
                  <Tooltip
                    placement="bottom"
                    title="Terima"
                    color="#1f1f1f"
                    key="white"
                  >
                    <Popconfirm
                      placement="bottomRight"
                      title="Apakah yakin ingin menerima ?"
                      onConfirm={() => onTerima(dataIndex)}
                      okText="Terima"
                      cancelText="Batal"
                    >
                      <Button
                        color="yellow"
                        className="aksiEdit"
                        size="mini"
                        icon="check"
                      />
                    </Popconfirm>
                  </Tooltip>
                )} */}
              {(row.status === 'Menunggu Konfirmasi' ||
                row.status === 'Pending') &&
                (auth.chairman_id === user.id ||
                  auth.secretary_id === user.id) && (
                  <>
                    <Tooltip
                      placement='bottom'
                      title='Ubah Kesimpulan'
                      color='#1f1f1f'
                      key='white'>
                      <Button
                        color='facebook'
                        className='aksiEdit'
                        size='mini'
                        onClick={() => onEditModal(row)}
                        icon='pencil alternate'
                      />
                    </Tooltip>
                    <Tooltip
                      placement='bottom'
                      title='Hapus Kesimpulan'
                      color='#1f1f1f'
                      key='white'>
                      <Popconfirm
                        placement='bottomRight'
                        title='Apakah yakin ingin menghapus kesimpulan ?'
                        onConfirm={() => onHapus(row)}
                        okText='Hapus'
                        cancelText='Batal'>
                        <Button
                          negative
                          className='aksiHapus'
                          size='mini'
                          icon='trash'
                        />
                      </Popconfirm>
                    </Tooltip>
                  </>
                )}
            </Space>
          )}
          {loadingAct && (
            <Spin className='loading-data-table' indicator={antIcon} />
          )}
        </div>
      ),
    },
  ];

  return (
    <>
      {!auth && (
        <>
          <h1 className='loading-data'>
            <Spin indicator={antIcon} color='green' />
            <p>Mengambil data ...</p>
          </h1>
        </>
      )}
      {auth && (
        <div className='scrollable'>
          <Row justify='space-between'>
            <>
              {(auth.chairman_id == user.id || auth.secretary_id == user.id) &&
                auth.status !== 'Selesai' && (
                  <>
                    <Col md={9} sm={24} className='width-100'>
                      <div className=''>
                        <Form
                          form={form}
                          layout='vertical'
                          name='dynamic_form_nest_item'
                          onFinish={onFinish}
                          autoComplete='off'>
                          <Form.List name='users'>
                            {(fields, { add, remove }) => (
                              <>
                                {fields.map(
                                  ({ key, name, fieldKey, ...restField }) => (
                                    <>
                                      {agendaDD && (
                                        <Form.Item
                                          {...restField}
                                          name={[name, 'agenda_id']}
                                          labelAlign='left'
                                          label='Pilih Agenda'
                                          fieldKey={[fieldKey, 'agenda_id']}
                                          rules={[
                                            {
                                              required: true,
                                              message: 'Bagian ini wajib diisi',
                                            },
                                          ]}>
                                          <Select
                                            style={{ width: '100%' }}
                                            autoComplete='off'
                                            showSearch
                                            placeholder='Pilih agenda'
                                            optionFilterProp='label'
                                            onChange={onChange}
                                            onFocus={onFocus}
                                            onBlur={onBlur}
                                            onSearch={onSearch}>
                                            {agendaDD.map((val, item) => (
                                              <Option
                                                key={val.id}
                                                value={val.id}
                                                label={val.agenda}>
                                                {val.agenda}
                                              </Option>
                                            ))}
                                          </Select>
                                        </Form.Item>
                                      )}
                                      {anggota && (
                                        <>
                                          <Form.Item
                                            {...restField}
                                            label='Kesimpulan'
                                            name={[name, 'conclusion']}
                                            fieldKey={[fieldKey, 'conclusion']}
                                            rules={[
                                              {
                                                required: true,
                                                message:
                                                  'Bagian ini wajib diisi',
                                              },
                                            ]}>
                                            <TextArea rows={7} />
                                          </Form.Item>
                                          <Form.Item
                                            {...restField}
                                            name={[name, 'user_id']}
                                            labelAlign='left'
                                            label='Penanggung Jawab'
                                            fieldKey={[fieldKey, 'user_id']}>
                                            <Select
                                              allowClear={true}
                                              style={{ width: '100%' }}
                                              autoComplete='off'
                                              showSearch
                                              placeholder='Penanggung Jawab'
                                              optionFilterProp='label'
                                              onChange={(fieldKey) =>
                                                onChange(fieldKey)
                                              }
                                              onFocus={onFocus}
                                              onBlur={onBlur}
                                              onSearch={onSearch}>
                                              {anggota.map((val, item) => (
                                                <Option
                                                  key={val.id}
                                                  value={val.id}
                                                  label={val.name}>
                                                  {val.name}
                                                </Option>
                                              ))}
                                            </Select>
                                          </Form.Item>
                                        </>
                                      )}
                                      <Form.Item
                                        {...restField}
                                        labelAlign='left'
                                        label='Tanggal'
                                        name={[name, 'due_date']}
                                        fieldKey={[fieldKey, 'due_date']}>
                                        <DatePicker
                                          placeholder='Masukan tenggat waktu'
                                          disabledDate={(current) => {
                                            return (
                                              current <
                                              moment().subtract(1, 'day')
                                            );
                                          }}
                                        />
                                      </Form.Item>
                                      <MinusCircleOutlined
                                        onClick={() => remove(name)}
                                      />
                                    </>
                                  )
                                )}

                                <Form.Item style={{ marginTop: '20px' }}>
                                  <BA
                                    type='dashed'
                                    onClick={() => add()}
                                    block
                                    icon={<PlusOutlined />}>
                                    Tambah Kesimpulan
                                  </BA>
                                </Form.Item>
                              </>
                            )}
                          </Form.List>
                          <Form.Item>
                            <Button
                              size='small'
                              color='green'
                              type='submit'
                              loading={loadingKesimpulan}>
                              Submit
                            </Button>
                          </Form.Item>
                        </Form>
                      </div>
                    </Col>
                  </>
                )}
            </>

            <Col
              md={
                (auth.chairman_id == user.id || auth.secretary_id == user.id) &&
                auth.status !== 'Selesai'
                  ? 14
                  : 24
              }
              sm={24}>
              <div className='' style={{ minHeight: '500px' }}>
                <Row justify='space-between' style={{ marginBottom: '20px' }}>
                  <Col md={12} sm={24}>
                    <Space>
                      {/* <Button
                      secondary
                      className='btn-hapus-filter'
                      type='danger'
                      onClick={clearFilters}>
                      Hapus Filter
                    </Button> */}
                    </Space>
                  </Col>
                  <Col
                    md={12}
                    sm={24}
                    style={{ width: '100%' }}
                    className='mt-mobile'>
                    <Input
                      value={searchText}
                      style={{ width: '100%' }}
                      placeholder='Cari kesimpulan disini'
                      onChange={onChangeSearch}
                    />
                  </Col>
                </Row>

                <Table
                  onChange={onChangeTable}
                  pagination={false}
                  loading={loading}
                  loadingIndicator={antIcon}
                  columns={columns}
                  dataSource={kesimpulan}
                  scroll={{ x: 200, y: 575 }}
                  size='small'
                />
                {pagination && (
                  <div className='pagination'>
                    <Pagination
                      total={pagination.total}
                      showTotal={(total, range) =>
                        `Menampilkan ${range[0]}-${range[1]} dari ${total} data`
                      }
                      defaultPageSize={Number(pagination.perPage)}
                      current={pagination.current}
                      onChange={onChangePagination}
                    />
                  </div>
                )}
              </div>
            </Col>
          </Row>
        </div>
      )}
      {modalCon && (
        <Modal
          title={modalCon.judul}
          visible={modalCon.visible}
          onCancel={handleCancelDetail}
          footer={[]}>
          <div className='data-rapat'>
            <Row justify='start'>
              <Col md={8} xs={24}>
                <label className='label-bold'>Kesimpulan</label>
              </Col>
              {matches && (
                <Col md={2} xs={1}>
                  :
                </Col>
              )}
              <Col md={14} xs={23}>
                <label> {modalCon.data.conclusion}</label>
              </Col>
            </Row>
            {modalCon.data.due_date && (
              <Row justify='start'>
                <Col md={8} xs={24}>
                  <label className='label-bold'>Tenggat Waktu</label>
                </Col>
                {matches && (
                  <Col md={2} xs={1}>
                    :
                  </Col>
                )}
                <Col md={14} xs={23}>
                  <label>
                    {new Date(modalCon.data.due_date).toLocaleDateString(
                      'id-ID',
                      optionsDate
                    )}
                  </label>
                </Col>
              </Row>
            )}
            <Row justify='start'>
              <Col md={8} xs={24}>
                <label className='label-bold'>Status</label>
              </Col>
              {matches && (
                <Col md={2} xs={1}>
                  :
                </Col>
              )}
              <Col md={14} xs={23}>
                <label> {modalCon.data.status}</label>
              </Col>
            </Row>
            <Row justify='start'>
              <Col md={8} xs={24}>
                <label className='label-bold'>Penanggung Jawab</label>
              </Col>
              {matches && (
                <Col md={2} xs={1}>
                  :
                </Col>
              )}
              <Col md={14} xs={23}>
                <label> {modalCon.data.pic}</label>
              </Col>
            </Row>
            <Row
              justify='start'
              style={{
                paddingBottom:
                  modalCon.data.status === 'Menunggu Konfirmasi'
                    ? '0px'
                    : '24px',
              }}>
              <Col md={8} xs={24}>
                <label className='label-bold'>Hasil</label>
              </Col>
              {matches && (
                <Col md={2} xs={1}>
                  :
                </Col>
              )}
              <Col md={14} xs={23}>
                <label> {modalCon.data.result}</label>
              </Col>
            </Row>

            {modalCon.data.status === 'Menunggu Konfirmasi' &&
              auth.chairman_id == user.id && (
                <Form
                  layout='vertical'
                  form={form}
                  name='basic'
                  initialValues={{ remember: false }}
                  onFinish={onSubmitFeedback}
                  onFinishFailed={onFinishFailed}>
                  <Form.Item
                    labelAlign='left'
                    name='keputusan'
                    rules={[
                      {
                        required: true,
                        message: 'Bagian ini wajib diisi',
                      },
                    ]}>
                    <Select
                      placeholder='Terima/Tolak'
                      onChange={(evt) => setFeedback(evt)}>
                      <Select.Option value='Terima'>Terima</Select.Option>
                      <Select.Option value='Tolak'>Tolak</Select.Option>
                    </Select>
                  </Form.Item>
                  {feedback === 'Tolak' && (
                    <Form.Item
                      labelAlign='left'
                      name='feedback'
                      rules={[
                        {
                          message: 'Bagian ini wajib diisi!',
                          required: true,
                        },
                      ]}>
                      <TextArea placeholder='Masukan feedback' rows={7} />
                    </Form.Item>
                  )}

                  <Form.Item>
                    <Button
                      style={{ width: '100%' }}
                      floated='right'
                      type='submit'
                      loading={loadingVerifikasi}
                      color='green'>
                      Submit
                    </Button>
                  </Form.Item>
                </Form>
              )}
            {/* </Row> */}
          </div>
        </Modal>
      )}
      <Modal
        title='Ubah Kesimpulan'
        visible={modalVisible}
        onCancel={handleCancel}
        footer={[]}>
        <Form
          layout='vertical'
          form={formEdit}
          name='basic'
          initialValues={{ remember: false }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}>
          {agendaDD && (
            <Form.Item
              labelAlign='left'
              label='Pilih Agenda'
              name='agenda'
              rules={[
                {
                  required: true,
                  message: 'Bagian ini wajib diisi',
                },
              ]}>
              <Select
                style={{ width: '100%' }}
                autoComplete='off'
                showSearch
                placeholder='Pilih agenda'
                optionFilterProp='label'
                onChange={onChange}
                onFocus={onFocus}
                onBlur={onBlur}
                onSearch={onSearch}>
                {agendaDD.map((val, item) => (
                  <Option key={val.id} value={val.id} label={val.agenda}>
                    {val.agenda}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          )}
          {anggota && (
            <Form.Item
              labelAlign='left'
              label='Penganggung Jawab'
              name='penanggung_jawab'
              rules={[
                {
                  required: true,
                  message: 'Bagian ini wajib diisi',
                },
              ]}>
              <Select
                style={{ width: '100%' }}
                autoComplete='off'
                showSearch
                placeholder='Penganggung Jawab'
                optionFilterProp='label'
                onChange={onChange}
                onFocus={onFocus}
                onBlur={onBlur}
                onSearch={onSearch}>
                {anggota.map((val, item) => (
                  <Option key={val.id} value={val.id} label={val.name}>
                    {val.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          )}
          <Form.Item
            labelAlign='left'
            label='Tanggal'
            name='due_date'
            rules={[
              {
                required: true,
                message: 'Bagian ini wajib diisi',
              },
            ]}>
            <DatePicker
              placeholder='Masukan tenggat waktu'
              disabledDate={(current) => {
                return current < moment().subtract(1, 'day');
              }}
            />
          </Form.Item>
          <Form.Item
            rules={[
              {
                required: true,
                message: 'Bagian ini wajib diisi',
              },
            ]}
            labelAlign='left'
            label='Kesimpulan'
            name='kesimpulan'>
            <TextArea placeholder='Masukan Kesimpulan Rapat' rows={7} />
          </Form.Item>
          <Form.Item>
            <Button
              color='green'
              size='small'
              floated='right'
              type='submit'
              htmlType='submit'
              loading={loading}>
              Ubah
            </Button>
            <Button
              negative
              type='button'
              size='small'
              floated='right'
              loading={loading}
              onClick={onReset}>
              Reset
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default Kesimpulan;
