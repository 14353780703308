import React, { useState, createContext, useContext } from 'react';
import myAxios from '../myAxios';
import { message } from 'antd';
import { UserContext } from './UserContext';
import { useHistory } from 'react-router-dom';

const optionsDate = {
  weekday: 'long',
  year: 'numeric',
  month: 'long',
  day: 'numeric',
};

export const RapatContext = createContext();

export const RapatProvider = (props) => {
  const [user, setUser] = useContext(UserContext);
  const [detail, setDetail] = useState(null);

  const [tempData, setData] = useState(null);

  const [anggota, setAnggota] = useState(null);
  const [tempAng, setTempAng] = useState(null);
  let history = useHistory();

  const updateRapat = async (idRapat) => {
    await getRapat(idRapat);
    return detail;
  };

  const getRapat = (idRapat) => {
    myAxios
      .get(`meeting-details/${idRapat}`, {
        headers: {
          Authorization: 'Bearer ' + user.token,
        },
      })
      .then((res) => {
        const data = res.data.data;

        data.meeting.date = new Date(data.meeting.date).toLocaleDateString(
          'id-ID',
          optionsDate
        );
        setData(data.meeting);
        setDetail(data.meeting);

        setAnggota(data.absensi);
        setTempAng(data.absensi);
      })
      .catch((err) => {
        if (err.response.data.message === 'unauthorized') {
          message.error('Sesi anda telah habis!');
          localStorage.removeItem('user');
          setUser(null);
        } else if (err.response.data.msg === 'Unauthorization Role') {
          message.error(err.response.data.msg);
          history.goBack();
        } else {
          message.error(err.response.data.msg);
          setDetail([]);
        }
      });
  };

  return (
    <RapatContext.Provider
      value={[
        tempData,
        setData,
        detail,
        setDetail,
        anggota,
        setAnggota,
        tempAng,
        setTempAng,
        updateRapat,
      ]}>
      {props.children}
    </RapatContext.Provider>
  );
};
