import React, { useState, createContext } from "react";

import CryptoJS from "crypto-js";
export const UserContext = createContext();

export const UserProvider = (props) => {
  const getuser = localStorage.getItem("user");
  var bytes = getuser ? CryptoJS.AES.decrypt(getuser, "datauser") : null;
  var currentUser = getuser
    ? JSON.parse(bytes.toString(CryptoJS.enc.Utf8))
    : null;

  const iniateUser = currentUser ? currentUser : null;
  const [user, setUser] = useState(iniateUser);

  return (
    <UserContext.Provider value={[user, setUser]}>
      {props.children}
    </UserContext.Provider>
  );
};
