import React, { useContext } from 'react';
import myAxios from '../../../myAxios';
import {
  message,
  Form,
  Radio,
  Input,
  Spin,
  Row,
  Col,
  DatePicker,
  Image,
  Upload,
} from 'antd';
import { Button } from 'semantic-ui-react';

import 'bootstrap/dist/css/bootstrap.min.css';
import moment from 'moment';
import { useState, useEffect } from 'react';
import { UserContext } from '../../../context/UserContext';
import CryptoJS from 'crypto-js';
import { useHistory } from 'react-router-dom';
import bimAxios from '../../../bimAxios';
import { LoadingOutlined } from '@ant-design/icons';

import NoImgMale from '../../../assets/img/male_avatar.svg';

const optionsDate = {
  weekday: 'long',
  year: 'numeric',
  month: 'long',
  day: 'numeric',
};
const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

const { TextArea } = Input;
const ProfilKaryawan = () => {
  const [user, setUser] = useContext(UserContext);
  const [condition, setCon] = useState(false);
  const [data, setData] = useState(null);
  const [tempDate, setDate] = useState(null);

  const [loading, setLoading] = useState(false);
  const [imageUrl, setImageUrl] = useState(null);
  const [prevImg, setImgPrev] = useState(null);

  let { history } = useHistory;
  const [form] = Form.useForm();

  useEffect(() => {
    if (!condition) {
      getProfilKaryawan();
      setCon(true);
    }
  }, [condition]);

  const getProfilKaryawan = () => {
    bimAxios
      .get(`user`, {
        headers: {
          Authorization: 'Bearer ' + user.token,
        },
      })
      .then((res) => {
        const karyawan = res.data.data;
        if (user.role !== 'superadmin') {
          form.setFieldsValue({
            nama: karyawan.name,
            npp: karyawan.username,
          });

          if (karyawan.detail) {
            const detail = karyawan.detail;
            form.setFieldsValue({
              email: detail.email,
              tanggal_lahir:
                detail.dateOfBirth !== null ? moment(detail.dateOfBirth) : null,

              jenis_kelamin: detail.gender,
              no_hp: detail.phoneNumber,
              tempat_lahir: detail.birthPlace,
              nik: detail.nik,
            });
            var tempDate =
              detail.dateOfBirth !== null
                ? detail.dateOfBirth.split('-', 3)
                : '-';
          }

          setDate(tempDate);
        } else {
          form.setFieldsValue({
            nama: karyawan.name,
            npp: karyawan.username,
          });
        }
        setData(karyawan);
      })
      .catch((err) => {
        if (err.response.data.message === 'unauthorized') {
          message.error('Sesi anda telah habis!');
          localStorage.removeItem('user');
          setUser(null);
        } else if (err.response.data.msg === 'Unauthorization Role') {
          message.error(err.response.data.msg);
          history.goBack();
        } else {
          message.error(err.response.data.message);
        }
        setLoading(false);
      });
  };

  const hpValidation = async (rule, value, callback) => {
    if (value === '' || value === undefined || value === null) {
      rule.message = 'Bagian ini wajib diisi!';
      form.setFields({
        no_hp: {
          value: value,
          errors: [new Error('forbid ha')],
        },
      });
    } else if (value !== '' && value !== null) {
      if (value[0] !== '0' || value[1] !== '8') {
        rule.message = 'Nomor Telepon Harus diawali dengan 08!';
        form.setFields({
          no_hp: {
            value: value,
            errors: [new Error('forbid ha')],
          },
        });
      } else {
        await callback();
      }
    } else if (value === '') {
      rule.message = 'Bagian ini wajib diisi!';
      form.setFields({
        no_hp: {
          value: value,
          errors: [new Error('forbid ha')],
        },
      });
    } else {
      await callback();
    }
  };

  const onFinish = (values) => {
    setLoading(true);
    var dateOfBirth;
    var date;
    var month;
    var year;
    var tanggal_lahir_temp = moment(values.tanggal_lahir).format('l');
    tanggal_lahir_temp = tanggal_lahir_temp.split('/');
    dateOfBirth = `${tanggal_lahir_temp[2]}-${tanggal_lahir_temp[0]}-${tanggal_lahir_temp[1]}`;
    date = Number(tanggal_lahir_temp[1]);
    month = Number(tanggal_lahir_temp[0]);
    year = tanggal_lahir_temp[2];

    let newObj = {
      name: data.name,
      email: values.email,
      nik: data.detail.nik === null ? '' : data.detail.nik,
      address: data.detail.address === null ? '' : data.detail.address,
      currentAddress:
        data.detail.currentAddress === null ? '' : data.detail.currentAddress,
      dateOfBirth,
      date,
      month,
      year,
      birthPlace: data.detail.birthPlace === null ? '' : data.detail.birthPlace,
      username: data.username,
      gender: values.jenis_kelamin,
      phoneNumber: values.no_hp,
    };
    bimAxios
      .post(`updateProfile`, newObj, {
        headers: {
          Authorization: 'Bearer ' + user.token,
        },
      })
      .then((res) => {
        setLoading(false);
        getProfilKaryawan();
        message.success('Berhasil Mengubah Data');
      })
      .catch((err) => {
        if (err.response.data.message === 'unauthorized') {
          message.error('Sesi anda telah habis!');
          localStorage.removeItem('user');
          setUser(null);
        } else if (err.response.data.msg === 'Unauthorization Role') {
          message.error(err.response.data.msg);
          history.goBack();
        } else {
          message.error(err.response.data.message);
        }
        setLoading(false);
      });
  };

  const onImageChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      let img = event.target.files[0];
      setImageUrl(img);
      setImgPrev(URL.createObjectURL(img));
    }
  };

  const onFinishFailed = (errorInfo) => {};

  const handleReset = () => {
    setLoading(false);
    form.setFieldsValue({
      email: data.detail.email,
      no_hp: data.detail.phoneNumber,
      jenis_kelamin: data.detail.gender,
      tanggal_lahir:
        data.detail.dateOfBirth !== null
          ? moment(data.detail.dateOfBirth)
          : null,
    });
  };

  return (
    <div className=''>
      <div className=''>
        {data === null ? (
          <h1 className='loading-data'>
            <Spin indicator={antIcon} />
            <p>Mengambil data..</p>
          </h1>
        ) : (
          <Form
            layout='vertical'
            form={form}
            name='basic'
            initialValues={{ remember: false }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}>
            {/* WITH PHOTO */}
            {/* <Row justify='space-around'> */}
            {/* <Col md={11} xs={24} sm={24}>
                <Form.Item
                  labelAlign='left'
                  label='Foto'
                  name='foto'
                  rules={[
                    {
                      required: true,
                      message: 'Bagian ini wajib diisi',
                    },
                  ]}>
                  <Image
                    beforeUpload={(file) => {
                      if (file.type !== 'image/png') {
                        message.error(`${file.name} is not a png file`);
                      }
                      return file.type === 'image/png'
                        ? true
                        : Upload.LIST_IGNORE;
                    }}
                    name='photo'
                    fallback={NoImgMale}
                    style={{
                      width: '250px',
                      height: '250px',
                      objectFit: 'cover',
                    }}
                    src={prevImg}
                  />
                  <input
                    style={{ marginBottom: '10px' }}
                    type='file'
                    name='myImage'
                    onChange={onImageChange}
                  />
                </Form.Item>
              </Col>
              <Col md={13} xs={24} sm={24}>
                <Form.Item
                  labelAlign='left'
                  label='Nama'
                  name='nama'
                  rules={[
                    {
                      required: true,
                      message: 'Bagian ini wajib diisi',
                    },
                  ]}>
                  <Input
                    placeholder='Masukan Nama'
                    disabled={user.role !== 'superadmin' ? true : false}
                  />
                </Form.Item>
                <Form.Item
                  labelAlign='left'
                  label='Nomor Induk Pegawai'
                  name='npp'
                  rules={[
                    {
                      required: true,
                      message: 'Bagian ini wajib diisi!',
                    },
                  ]}>
                  <Input
                    placeholder='Masukan Nomor Induk Pegawai'
                    disabled={user.role !== 'superadmin' ? true : false}
                  />
                </Form.Item>
                <Form.Item
                  labelAlign='left'
                  label='Jenis Kelamin'
                  name='jenis_kelamin'
                  rules={[
                    {
                      required: true,
                      message: 'Bagian ini wajib diisi!',
                    },
                  ]}>
                  <Radio.Group>
                    <Radio value='Laki-laki'>Laki-Laki</Radio>
                    <Radio value='Perempuan'>Perempuan</Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>
            </Row>
            <Row justify='space-between'>
              <Col md={11} xs={24} sm={24}>
                <Form.Item
                  labelAlign='left'
                  label='Email'
                  name='email'
                  rules={[
                    {
                      required: true,
                      message: 'Bagian ini wajib diisi',
                    },
                  ]}>
                  <Input
                    placeholder='Masukan Email'
                    disabled={user.role !== 'superadmin' ? true : false}
                  />
                </Form.Item>
              </Col>
              <Col md={11} xs={24} sm={24}>
                <Form.Item
                  labelAlign='left'
                  label='Nomor HP'
                  name='no_hp'
                  rules={[
                    {
                      required: true,
                      message: 'Bagian ini wajib diisi!',
                    },
                  ]}>
                  <Input placeholder='Masukan Nomor Handphone' />
                </Form.Item>
              </Col>
            </Row> */}

            {/* <Form.Item
              labelAlign='left'
              label='NIK'
              name='nik'
              rules={[
                {
                  required: true,
                  message: 'Bagian ini wajib diisi!',
                },
              ]}>
              <Input
                placeholder='Masukan NIK'
                disabled={user.role !== 'superadmin' ? true : false}
              />
            </Form.Item> */}
            {/* <Form.Item
              labelAlign='left'
              label='Tempat Lahir'
              name='tempat_lahir'
              rules={[
                {
                  required: true,
                  message: 'Bagian ini wajib diisi!',
                },
              ]}>
              <Input
                placeholder='Masukan Tempat Lahir'
                disabled={user.role !== 'superadmin' ? true : false}
              />
            </Form.Item> */}

            {/* WO PHOTO */}
            <Row justify='space-between'>
              <Col md={11} xs={24} sm={24}>
                <Form.Item
                  labelAlign='left'
                  label='Nama'
                  name='nama'
                  rules={[
                    {
                      required: true,
                      message: 'Bagian ini wajib diisi',
                    },
                  ]}>
                  <Input
                    placeholder='Masukan Nama'
                    disabled={user.role !== 'superadmin' ? true : false}
                  />
                </Form.Item>
              </Col>
              <Col md={11} xs={24} sm={24}>
                <Form.Item
                  labelAlign='left'
                  label='Nomor Induk Pegawai'
                  name='npp'
                  rules={[
                    {
                      required: true,
                      message: 'Bagian ini wajib diisi!',
                    },
                  ]}>
                  <Input
                    placeholder='Masukan Nomor Induk Pegawai'
                    disabled={user.role !== 'superadmin' ? true : false}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row justify='space-between'>
              <Col md={11} xs={24} sm={24}>
                <Form.Item
                  labelAlign='left'
                  label='Email'
                  name='email'
                  rules={[
                    {
                      required: true,
                      message: 'Bagian ini wajib diisi',
                    },
                  ]}>
                  <Input placeholder='Masukan Email' />
                </Form.Item>
              </Col>
              <Col md={11} xs={24} sm={24}>
                <Form.Item
                  labelAlign='left'
                  label='Nomor HP'
                  name='no_hp'
                  rules={[
                    {
                      required: true,
                      validator: hpValidation,
                    },
                  ]}>
                  <Input placeholder='Masukan Nomor Handphone' type='number' />
                </Form.Item>
              </Col>
            </Row>

            {/* <Form.Item
              labelAlign='left'
              label='NIK'
              name='nik'
              rules={[
                {
                  required: true,
                  message: 'Bagian ini wajib diisi!',
                },
              ]}>
              <Input
                placeholder='Masukan NIK'
                disabled={user.role !== 'superadmin' ? true : false}
              />
            </Form.Item> */}
            {/* <Form.Item
              labelAlign='left'
              label='Tempat Lahir'
              name='tempat_lahir'
              rules={[
                {
                  required: true,
                  message: 'Bagian ini wajib diisi!',
                },
              ]}>
              <Input
                placeholder='Masukan Tempat Lahir'
                disabled={user.role !== 'superadmin' ? true : false}
              />
            </Form.Item> */}
            <Row justify='space-between'>
              <Col md={11} xs={24} sm={24}>
                <Form.Item
                  style={{ marginBottom: 0 }}
                  labelAlign='left'
                  label='Tanggal Lahir'
                  name='tanggal_lahir'
                  rules={[
                    {
                      required: true,
                      message: 'Bagian ini wajib diisi!',
                    },
                  ]}>
                  <DatePicker
                    placeholder='Masukan Tanggal Lahir'
                    disabledDate={(current) => {
                      return current > moment().subtract(0, 'day');
                    }}
                  />
                </Form.Item>
                <label style={{ fontSize: '12px', color: 'gray' }}>
                  *Tanggal lahir dapat diketik dengan format YYYY-MM-DD
                </label>
              </Col>
              <Col md={11} xs={24} sm={24}>
                <Form.Item
                  labelAlign='left'
                  label='Jenis Kelamin'
                  name='jenis_kelamin'
                  rules={[
                    {
                      required: true,
                      message: 'Bagian ini wajib diisi!',
                    },
                  ]}>
                  <Radio.Group>
                    <Radio value='Laki-laki'>Laki-Laki</Radio>
                    <Radio value='Perempuan'>Perempuan</Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>
            </Row>
            {/* <Form.Item
              labelAlign='left'
              label='Alamat Sesuai KTP'
              name='alamat_ktp'
              rules={[
                {
                  required: true,
                  message: 'Bagian ini wajib diisi!',
                },
              ]}>
              <TextArea
                rows={4}
                placeholder='Masukan Alamat Sesuai KTP'
                disabled={user.role !== 'superadmin' ? true : false}
              />
            </Form.Item>

            <Form.Item
              labelAlign='left'
              label='Alamat Domisili'
              name='alamat_domisili'
              rules={[
                {
                  required: true,
                  message: 'Bagian ini wajib diisi!',
                },
              ]}>
              <TextArea rows={4} placeholder='Masukan Alamat Domisili' />
            </Form.Item> */}

            <Form.Item>
              <Button
                floated='right'
                type='submit'
                color='green'
                loading={loading}>
                Simpan
              </Button>
              <Button
                floated='right'
                type='button'
                negative
                onClick={handleReset}
                loading={loading}>
                Reset
              </Button>
            </Form.Item>
          </Form>
        )}
      </div>
    </div>
  );
};

export default ProfilKaryawan;
