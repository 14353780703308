import React, { useContext } from 'react';
import myAxios from '../../myAxios';
import {
  Table,
  Space,
  Spin,
  Row,
  Col,
  message,
  Form,
  Modal,
  Select,
  Input,
  Pagination,
  DatePicker,
  Tag,
  Tooltip,
} from 'antd';
import { ArrowUpRight } from 'react-bootstrap-icons';

import { Button, Icon } from 'semantic-ui-react';
import { LoadingOutlined } from '@ant-design/icons';
import moment from 'moment';
import { useState, useEffect } from 'react';
import { UserContext } from '../../context/UserContext';
import { useHistory } from 'react-router-dom';

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
const tableLoading = {
  indicator: <Spin className='loading-data-table' indicator={antIcon} />,
};

const { Option } = Select;
const InfoRapat = () => {
  const [user, setUser] = useContext(UserContext);
  const [rapat, setRapat] = useState(null);
  const [loadingT, setLoadingT] = useState(false);
  const [pagination, setPagination] = useState(null);
  let history = useHistory();
  const [loadingAct, setLoadingAct] = useState(false);

  const [infoPage, setInfoPage] = useState({
    searchText: null,
    page: 1,
    sortBy: undefined,
    sorting: 'ASC',
    show: 10,
  });

  useEffect(() => {
    getRapat();
  }, []);

  const onDetail = (row) => {
    history.push(`/tim/rapat/detailRapat/${row.id}`);
  };

  const getRapat = async (
    search = infoPage.searchText,
    page = infoPage.page,
    sortBy = infoPage.sortBy,
    sorting = infoPage.sorting,
    show = infoPage.show
  ) => {
    setLoadingT(tableLoading);
    await myAxios
      .get(`meeting-department`, {
        params: {
          page: Number(page),
          search: search,
          show: show,
          sortBy: sortBy,
          sorting: sorting,
        },
        headers: {
          Authorization: 'Bearer ' + user.token,
        },
      })
      .then((res) => {
        const data = res.data.data;
        setRapat(data.data);
        setPagination({
          total: data.total,
          current: data.current_page,
          perPage: data.per_page,
        });
        setLoadingT(false);
      })
      .catch((err) => {
        if (err.response.data.message === 'unauthorized') {
          message.error('Sesi anda telah habis!');
          localStorage.removeItem('user');
        } else if (err.response.data.msg === 'Unauthorization Role') {
          message.error(err.response.data.msg);
          history.goBack();
        } else {
          message.error(err.response.data.message);
        }
      });
  };

  const onChangePagination = (page, show) => {
    setInfoPage((prev) => {
      return {
        ...prev,
        page,
        show,
      };
    });
    getRapat(
      infoPage.searchText,
      page,
      infoPage.sortBy,
      infoPage.sorting,
      show
    );
  };

  const onChangeTable = (pagination, filters, sorter, extra) => {
    const order = sorter.order === 'descend' ? 'DESC' : 'ASC';
    const field = sorter.order === undefined ? undefined : sorter.field;
    setInfoPage((prev) => {
      return {
        ...prev,
        sortBy: field,
        sorting: order,
      };
    });
    getRapat(infoPage.searchText, infoPage.page, field, order);
  };

  const onChangeSearch = (evt) => {
    setLoadingT(tableLoading);
    setInfoPage((prev) => {
      return {
        ...prev,
        searchText: evt.target.value,
      };
    });

    const timeOutId = setTimeout(
      () =>
        getRapat(
          evt.target.value,
          null,
          infoPage.sortBy,
          infoPage.sorting,
          infoPage.show
        ),
      500
    );
    return () => clearTimeout(timeOutId);
  };

  const columns = [
    {
      title: 'Nama Tim',
      dataIndex: 'team_name',
      key: 'team_name',
      sorter: (a, b) => a.team_name.length - b.team_name.length,
      ellipsis: true,
    },
    {
      title: 'Nama',
      dataIndex: 'name',
      key: 'name',
      sorter: (a, b) => a.name.length - b.name.length,
    },
    {
      title: 'Tanggal',
      dataIndex: 'date',
      key: 'date',
      sorter: (a, b) => a.date.length - b.date.length,
      ellipsis: true,
    },
    {
      title: 'Jam Mulai',
      dataIndex: 'start_time',
      key: 'start_time',
      sorter: (a, b) => a.start_time.length - b.start_time.length,
      ellipsis: true,
    },
    {
      title: 'Jam Selesai',
      dataIndex: 'end_time',
      key: 'end_time',
      sorter: (a, b) => a.end_time.length - b.end_time.length,
      ellipsis: true,
    },
    {
      title: 'Ruang',
      dataIndex: 'room',
      key: 'room',
      sorter: (a, b) => a.room.length - b.room.length,
      ellipsis: true,
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      sorter: (a, b) => a.status.length - b.status.length,
      ellipsis: true,
      width: '9%',
      render: (text) =>
        text === 'Pending' ? (
          <Tag color='red'>{text}</Tag>
        ) : (
          <Tag color='green'>{text}</Tag>
        ),
    },
    {
      key: 'action',
      dataIndex: 'id',
      align: 'center',
      render: (dataIndex, row) => (
        <div>
          <>
            {!loadingAct && (
              <Space size='middle'>
                <Tooltip
                  placement='bottom'
                  title='Lihat Detail'
                  color='#1f1f1f'
                  key='white'>
                  <Button
                    color='orange'
                    className='aksiDetail'
                    size='mini'
                    onClick={() => onDetail(row)}
                    icon={<ArrowUpRight />}
                  />
                </Tooltip>
              </Space>
            )}
            {loadingAct && (
              <Spin className='loading-data-table' indicator={antIcon} />
            )}
          </>
        </div>
      ),
    },
  ];

  return (
    <div className=''>
      <div className='scrollable'>
        <Row justify='space-between' style={{ marginBottom: '20px' }}>
          <Col md={12} sm={24}>
            <Space wrap={true} style={{ marginBottom: '2px' }}></Space>
          </Col>
          <Col md={12} sm={24} style={{ width: '100%' }} className='mt-mobile'>
            <Input
              value={infoPage.searchText}
              style={{ width: '100%' }}
              placeholder='Cari rapat disini'
              onChange={onChangeSearch}
            />
          </Col>
        </Row>
        <Table
          onChange={onChangeTable}
          loading={loadingT}
          pagination={false}
          loadingIndicator={antIcon}
          columns={columns}
          dataSource={rapat}
          scroll={{ x: 900, y: 1500 }}
          size='small'
        />
        {pagination && (
          <div className='pagination'>
            <Pagination
              showSizeChanger={true}
              pageSize={infoPage.show}
              total={pagination.total}
              showTotal={(total, range) =>
                `Menampilkan ${range[0]}-${range[1]} dari ${total} data`
              }
              defaultPageSize={Number(pagination.perPage)}
              current={pagination.current}
              onChange={onChangePagination}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default InfoRapat;
