import React, { useContext, useState, useEffect } from 'react';

import { Layout } from 'antd';
import { BrowserRouter as Router } from 'react-router-dom';
import { UserContext } from '../context/UserContext';

import ContentCustom from './ContentCustom.jsx';
import FooterCustom from './FooterCustom.jsx';

import './Layout.css';

import MySidebar from './SideBar';

import HeaderCustom from './Header';
import DemoOnly from './DemoOnly';
const { Content } = Layout;

const LayoutCustom = () => {
  const [user] = useContext(UserContext);
  const [collapsed, setCollpsed] = useState(null);
  const [path, setPath] = useState(window.location.pathname);
  const [matches, setMatches] = useState(
    window.matchMedia('(min-width: 991px)').matches
  );
  useEffect(() => {
    function handleResize() {
      setMatches(window.matchMedia('(min-width: 991px)').matches);
    }
    setPath(window.location.pathname);
    window.addEventListener('resize', handleResize);
  }, [window.location.pathname]);

  const getCollapsed = (param) => {
    setCollpsed(param);
  };

  const sendDataToParent = (index) => {
    setCollpsed(index);
  };

  return (
    <Router>
      <Layout>
        <DemoOnly />
        <MySidebar sendDataToParent={sendDataToParent} />
        <Layout
          className='site-layout'
          // style={{
          //   marginLeft:
          //     matches && !collapsed === true
          //       ? '260px'
          //       : collapsed && matches
          //       ? '80px'
          //       : 0,
          // }}
        >
          {user && matches === false ? <HeaderCustom /> : null}
          <Content
            className='backGround'
            // style={{ minHeight: user === null ? '87vh' : '100vh' }}
          >
            <ContentCustom />
          </Content>
          <FooterCustom />
        </Layout>
      </Layout>
    </Router>
  );
};

export default LayoutCustom;
