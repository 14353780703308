import React from 'react';
import { motion } from 'framer-motion';
import './Layout.css';
const DemoOnly = () => {
  return (
    <>
      <motion.div
        className='demo inner'
        initial={{ opacity: 0.4, y: 10 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.6 }}
        exit={{ opacity: 0 }}>
        <p
          style={{
            fontWeight: 'bold',
            fontSize: '15px',
            textAlign: 'center',
            color: 'white',
            fontWeight: 'bolder',
          }}>
          Demo Only
        </p>
      </motion.div>
    </>
  );
};

export default DemoOnly;
