import React from 'react';
import { motion } from 'framer-motion';
import { Tag } from 'antd';

const ComponentNew = ({ status }) => {
  return (
    <>
      <motion.a
        initial={{ opacity: 0.4, y: 10 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.4 }}
        exit={{ opacity: 0 }}>
        <Tag
          style={{
            marginLeft: `${
              status === 'Tim'
                ? '44%'
                : status === 'Tim Mobile'
                ? '54%'
                : status === 'Tugas'
                ? '33%'
                : '46%'
            }`,
            fontWeight: 'bold',
          }}
          color='#B00C0C'>
          Baru
        </Tag>
      </motion.a>
    </>
  );
};

export default ComponentNew;
