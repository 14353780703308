import React, { useContext } from 'react';
import { Tabs } from 'antd';
import TamuSaran from './TamuSaran';
import { UserContext } from '../../../context/UserContext';

const LihatTamu = () => {
  const [user] = useContext(UserContext);

  const { TabPane } = Tabs;

  function callback(key) {}

  return (
    <div className='container-content'>
      <div className='container-detail'>
        <h1 className='table-title'>SARAN TAMU</h1>
        <TamuSaran />
      </div>
    </div>
  );
};

export default LihatTamu;
