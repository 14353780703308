import React, { useContext } from 'react';
import myAxios from '../../myAxios';
import {
  Table,
  Space,
  Spin,
  Row,
  Col,
  message,
  Form,
  Modal,
  Select,
  Input,
  Pagination,
  DatePicker,
} from 'antd';
import { Button, Icon } from 'semantic-ui-react';
import { LoadingOutlined } from '@ant-design/icons';
import moment from 'moment';
import { useState, useEffect } from 'react';
import { UserContext } from '../../context/UserContext';
import { useHistory } from 'react-router-dom';

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
const tableLoading = {
  indicator: <Spin className='loading-data-table' indicator={antIcon} />,
};

const optionsDate = {
  weekday: 'long',
  year: 'numeric',
  month: 'long',
  day: 'numeric',
};
const { Option } = Select;
const InfoRuang = () => {
  const [user, setUser] = useContext(UserContext);
  const [infoRuang, setInfoRuang] = useState(null);
  const [lokasi, setLokasi] = useState(null);
  const [modalCon, setModalCon] = useState(null);
  const [tempData, setData] = useState(null);
  const [searchText, setSearch] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loadingCari, setLoadingCari] = useState(false);
  const [loadingT, setLoadingT] = useState(false);
  const [modalVisible, setModal] = useState(false);
  const [pagination, setPagination] = useState(null);
  let { history } = useHistory;
  const [form] = Form.useForm();

  useEffect(() => {
    // const timeOutId = setTimeout(() => getKaryawan(searchText), 500);
    // return () => clearTimeout(timeOutId);
  }, []);

  const getLokasi = async () => {
    await myAxios
      .get(`locations-for-search`, {
        headers: {
          Authorization: 'Bearer ' + user.token,
        },
      })
      .then((res) => {
        const data = res.data.data;
        if (data.length > 0) {
          setLokasi(data);
        } else {
          setLokasi([]);
        }
      })
      .catch((err) => {
        if (err.response.data.message === 'unauthorized') {
          message.error('Sesi anda telah habis!');
          localStorage.removeItem('user');
        } else if (err.response.data.msg === 'Unauthorization Role') {
          message.error(err.response.data.msg);
          history.goBack();
        } else {
          message.error(err.response.data.message);
        }
      });
  };

  const onChangePagination = (evt) => {
    // getKaryawan('', evt);
  };

  const clearFilters = () => {
    setInfoRuang(tempData);
    setSearch('');
  };

  const onChangeSearch = (evt) => {
    setLoadingT(tableLoading);
    setSearch(evt.target.value);
  };

  const handleCancel = () => {
    setModal(false);
  };

  const onFinish = (values) => {
    setLoading(true);

    let newObj = {
      date: values.tanggal,
      location_id: values.location,
    };
    myAxios
      .post(`used-rooms`, newObj, {
        headers: {
          Authorization: 'Bearer ' + user.token,
        },
      })
      .then((res) => {
        setInfoRuang(res.data.data);
        message.info(res.data.msg);
        setLoading(false);
        setModal(false);
      })
      .catch((err) => {
        if (err.response.data.message === 'unauthorized') {
          message.error('Sesi anda telah habis!');
          localStorage.removeItem('user');
          setUser(null);
        } else if (err.response.data.msg === 'Unauthorization Role') {
          message.error(err.response.data.msg);
          history.goBack();
        } else {
          message.error(err.response.data.msg);
        }
        setLoading(false);
      });
  };

  const onFinishFailed = (errorInfo) => {};

  const onTambahModal = async () => {
    setLoadingCari(true);
    await getLokasi();
    setLoadingCari(false);
    setModal(true);
    setModalCon({
      judul: 'Info Ruang',
    });

    form.setFieldsValue({
      location: 'All',
    });
  };

  const onChange = () => {};
  const onFocus = () => {};
  const onBlur = () => {};
  const onSearch = () => {};

  const columns = [
    {
      title: 'Nama',
      dataIndex: 'room',
      key: 'room',
      sorter: (a, b) => a.room.length - b.room.length,
      // ellipsis: true,
    },
    {
      title: 'Lokasi',
      dataIndex: 'location',
      key: 'location',
      sorter: (a, b) => a.location.length - b.location.length,
      // ellipsis: true,
    },
    {
      title: 'Tim',
      dataIndex: 'team',
      key: 'team',
      sorter: (a, b) => a.team.length - b.team.length,
      // ellipsis: true,
    },
    {
      title: 'Rapat',
      dataIndex: 'meeting',
      key: 'meeting',
      sorter: (a, b) => a.meeting.length - b.meeting.length,
      // ellipsis: true,
    },
    {
      title: 'Ketua',
      dataIndex: 'chairman',
      key: 'chairman',
      sorter: (a, b) => a.chairman.length - b.chairman.length,
      // ellipsis: true,
    },
    {
      title: 'Anggota',
      dataIndex: 'anggota',
      key: 'anggota',
      sorter: (a, b) => a.anggota - b.anggota,
      // ellipsis: true,
    },
    {
      title: 'Tanggal',
      dataIndex: 'date',
      key: 'date',
      sorter: (a, b) => a.date.length - b.date.length,
      // ellipsis: true,
      render: (text) =>
        text !== null ? (
          <>{new Date(text).toLocaleDateString('id-ID', optionsDate)}</>
        ) : null,
    },

    {
      title: 'Jam Mulai',
      dataIndex: 'start_time',
      key: 'start_time',
      sorter: (a, b) =>
        new Date('2000-02-02' + a.start_time) -
        new Date('2000-02-02' + b.start_time),
      // ellipsis: true,
    },
    {
      title: 'Jam Selesai',
      dataIndex: 'end_time',
      key: 'end_time',
      sorter: (a, b) => a.end_time.length - b.end_time.length,
      // ellipsis: true,
    },
  ];

  return (
    <div className='container-content infoRuang'>
      <div className='container-table'>
        <h1 className='table-title'>INFO RUANG</h1>
        <Row justify='space-between' style={{ marginBottom: '20px' }}>
          <Col md={12} sm={24}>
            <Space wrap={true} style={{ marginBottom: '2px' }}>
              <Button
                className='btn-hapus-filter'
                onClick={onTambahModal}
                icon
                labelPosition='left'
                color='green'
                loading={loadingCari}>
                <Icon name='search' />
                Cari Ruang
              </Button>
              <Button
                secondary
                className='btn-hapus-filter'
                type='danger'
                onClick={clearFilters}>
                Hapus Filter
              </Button>
            </Space>
          </Col>
          <Col md={12} sm={24} style={{ width: '100%' }} className='mt-mobile'>
            <Input
              value={searchText}
              style={{ width: '100%' }}
              placeholder='Cari ruangan disini'
              onChange={onChangeSearch}
            />
          </Col>
        </Row>
        <Table
          loading={loadingT}
          // pagination={false}
          loadingIndicator={antIcon}
          columns={columns}
          dataSource={infoRuang}
          scroll={{ x: 900, y: 1500 }}
          size='small'
        />
        {pagination && (
          <div className='pagination'>
            <Pagination
              total={pagination.total}
              showTotal={(total, range) =>
                `Menampilkan ${range[0]}-${range[1]} dari ${total} data`
              }
              defaultPageSize={Number(pagination.perPage)}
              current={pagination.current}
              onChange={onChangePagination}
            />
          </div>
        )}

        {modalCon && (
          <Modal
            centered
            title={modalCon.judul}
            visible={modalVisible}
            onCancel={handleCancel}
            footer={[]}>
            <Form
              layout='vertical'
              form={form}
              name='basic'
              initialValues={{ remember: false }}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}>
              <Form.Item
                labelAlign='left'
                label='Tanggal'
                name='tanggal'
                rules={[
                  {
                    required: true,
                    message: 'Bagian ini wajib diisi',
                  },
                ]}>
                <DatePicker
                  placeholder='Masukan Tanggal Rapat'
                  disabledDate={(current) => {
                    return current < moment().subtract(1, 'day');
                  }}
                />
              </Form.Item>

              {lokasi && (
                <Form.Item
                  labelAlign='left'
                  label='Lokasi'
                  name='location'
                  rules={[
                    {
                      required: true,
                      message: 'Bagian ini wajib diisi',
                    },
                  ]}>
                  <Select
                    style={{ width: '100%' }}
                    autoComplete='off'
                    showSearch
                    placeholder='Pilih Lokasi'
                    optionFilterProp='children'
                    onChange={onChange}
                    onFocus={onFocus}
                    onBlur={onBlur}
                    onSearch={onSearch}
                    optionFilterProp='label'
                    // filterOption={(input, option) =>
                    //   option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                    //   0
                    // }
                  >
                    {lokasi.map((val, item) => (
                      <Option key={val.id} value={val.id} label={val.name}>
                        {val.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              )}

              <Form.Item>
                <Button
                  size='small'
                  floated='right'
                  type='submit'
                  color='green'
                  loading={loading}>
                  Cari
                </Button>

                <Button
                  negative
                  size='small'
                  floated='right'
                  type='button'
                  onClick={() => form.setFieldsValue({ tanggal: null })}
                  loading={loading}>
                  Reset
                </Button>
              </Form.Item>
            </Form>
          </Modal>
        )}
      </div>
    </div>
  );
};

export default InfoRuang;
