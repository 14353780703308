import React, { useContext } from 'react';
import myAxios from '../../../myAxios';
import {
  Table,
  Space,
  Spin,
  Row,
  Col,
  message,
  Modal,
  Tag,
  Tooltip,
  Popconfirm,
  Form,
  Pagination,
  Input,
} from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { Button, Icon } from 'semantic-ui-react';
import { useState, useEffect } from 'react';
import { UserContext } from '../../../context/UserContext';
import { useHistory } from 'react-router-dom';

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
const tableLoading = {
  indicator: <Spin className='loading-data-table' indicator={antIcon} />,
};
const Lokasi = () => {
  const [user, setUser] = useContext(UserContext);
  const [lokasi, setLokasi] = useState(null);
  const [modalCon, setModalCon] = useState(null);
  const [tempData, setData] = useState(false);
  const [searchText, setSearch] = useState('');
  const [loading, setLoading] = useState(false);
  const [loadingT, setLoadingT] = useState(false);
  const [loadingAct, setLoadingAct] = useState(false);
  const [modalVisible, setModal] = useState(false);
  const [pagination, setPagination] = useState(null);
  const [page, setPage] = useState('10');
  let { history } = useHistory;
  const [form] = Form.useForm();

  useEffect(() => {
    // if (!lokasi) {
    setLoadingT(tableLoading);
    const timeOutId = setTimeout(
      () => getLokasi(searchText, null, null, null, page),
      500
    );
    return () => clearTimeout(timeOutId);
    // }
  }, [searchText]);

  const getLokasi = (
    search = undefined,
    page = '1',
    sortBy = undefined,
    sorting = 'ASC',
    show = '10'
  ) => {
    setLoadingT(tableLoading);

    myAxios
      .get(`locations`, {
        params: {
          page: Number(page),
          search: search,
          show: show,
          sortBy: sortBy,
          sorting: sorting,
        },
        headers: {
          Authorization: 'Bearer ' + user.token,
        },
      })
      .then((res) => {
        const data = res.data.data.data;
        if (data.length > 0) {
          setData(data);
          setLokasi(data);
        } else {
          setLokasi([]);
        }
        setPagination({
          total: res.data.data.total,
          current: res.data.data.current_page,
          perPage: res.data.data.per_page,
        });
        setLoadingT(false);
      })
      .catch((err) => {
        if (err.response.data.message === 'unauthorized') {
          message.error('Sesi anda telah habis!');
          localStorage.removeItem('user');
        } else if (err.response.data.msg === 'Unauthorization Role') {
          message.error(err.response.data.msg);
          history.goBack();
        } else {
          message.error(err.response.data.msg);
        }
        setLoadingT(false);
      });
  };

  const onHapus = (param) => {
    setLoadingAct(true);
    myAxios
      .delete(`location/${param}`, {
        headers: {
          Authorization: 'Bearer ' + user.token,
        },
      })
      .then((res) => {
        getLokasi();
        message.success(res.data.msg);
        setLoadingAct(false);
      })
      .catch((err) => {
        if (err.response.data.message === 'unauthorized') {
          message.error('Sesi anda telah habis!');
          localStorage.removeItem('user');
          setUser(null);
        } else if (err.response.data.msg === 'Unauthorization Role') {
          message.error(err.response.data.msg);
          history.goBack();
        } else {
          message.error(err.response.data.message);
        }
        setLoading(false);
      });
  };

  const clearFilters = () => {
    getLokasi();
  };

  const onChangeSearch = (evt) => {
    // setSearch(evt.target.value);
    setLoadingT(tableLoading);
    getLokasi(evt.target.value);
  };

  const handleCancel = () => {
    setModal(false);
    form.resetFields();
  };

  const onFinish = (values) => {
    setLoading(true);
    if (modalCon.judul === 'Tambah Lokasi') {
      let newObj = {
        name: values.nama_lokasi,
      };

      myAxios
        .post(`location`, newObj, {
          headers: {
            Authorization: 'Bearer ' + user.token,
          },
        })
        .then((res) => {
          message.success(res.data.msg);
          setLoading(false);
          setModal(false);
          form.resetFields();
          getLokasi();
        })
        .catch((err) => {
          if (err.response.data.message === 'unauthorized') {
            message.error('Sesi anda telah habis!');
            localStorage.removeItem('user');
            setUser(null);
          } else if (err.response.data.msg === 'Unauthorization Role') {
            message.error(err.response.data.msg);
            history.goBack();
          } else {
            message.error(err.response.data.msg);
          }
          setLoading(false);
        });
    } else {
      let newObj = {
        id: modalCon.id,
        name: values.nama_lokasi,
      };

      myAxios
        .put(`location/${modalCon.id}`, newObj, {
          headers: {
            Authorization: 'Bearer ' + user.token,
          },
        })
        .then((res) => {
          message.success(res.data.msg);
          setLoading(false);
          setModal(false);
          form.resetFields();
          getLokasi();
        })
        .catch((err) => {
          if (err.response.data.message === 'unauthorized') {
            message.error('Sesi anda telah habis!');
            localStorage.removeItem('user');
            setUser(null);
          } else if (err.response.data.msg === 'Unauthorization Role') {
            message.error(err.response.data.msg);
            history.goBack();
          } else {
            message.error(err.response.data.msg);
          }
          setLoading(false);
        });
    }
  };

  const onFinishFailed = (errorInfo) => {};

  const onTambahModal = () => {
    setModal(true);
    setModalCon({
      judul: 'Tambah Lokasi',
    });
  };
  const onEditModal = (param) => {
    setModal(true);
    setModalCon({
      judul: 'Ubah Lokasi',
      id: param.id,
    });

    form.setFieldsValue({
      nama_lokasi: param.name,
    });
  };

  const onChangePagination = (evt, show) => {
    setPage(show);
    getLokasi('', evt, null, null, show);
  };

  const onChangeTable = (pagination, filters, sorter, extra) => {
    const order = sorter.order === 'descend' ? 'DESC' : 'ASC';
    const field = sorter.order === undefined ? undefined : sorter.field;
    getLokasi('', '1', field, order);
  };

  const columns = [
    {
      title: 'Nama Lokasi',
      dataIndex: 'name',
      key: 'name',
      sorter: () => {},
      ellipsis: true,
    },
    {
      key: 'action',
      dataIndex: 'id',
      align: 'center',
      render: (dataIndex, row) => (
        <div>
          {!loadingAct && (
            <Space size='middle'>
              <Tooltip
                placement='bottom'
                title='Ubah Lokasi'
                color='#1f1f1f'
                key='white'>
                <Button
                  size='mini'
                  color='facebook'
                  className='aksiEdit'
                  onClick={() => onEditModal(row)}
                  icon='pencil alternate'
                />
              </Tooltip>
              <Tooltip
                placement='bottom'
                title='Hapus Lokasi'
                color='#1f1f1f'
                key='white'>
                <Popconfirm
                  placement='bottomRight'
                  title='Apakah yakin ingin menghapus lokasi ?'
                  onConfirm={() => onHapus(dataIndex)}
                  okText='Hapus'
                  cancelText='Batal'>
                  <Button
                    negative
                    className='aksiHapus'
                    size='mini'
                    icon='trash'
                  />
                </Popconfirm>
              </Tooltip>
            </Space>
          )}
          {loadingAct && (
            <Spin className='loading-data-table' indicator={antIcon} />
          )}
        </div>
      ),
    },
  ];

  return (
    <div className='container-content lokasi'>
      <div className='container-table'>
        <h1 className='table-title'>DATA LOKASI</h1>
        <Row justify='space-between' style={{ marginBottom: '20px' }}>
          <Col md={12} sm={24}>
            <Space>
              <Button
                icon
                color='green'
                className='btn-hapus-filter'
                onClick={onTambahModal}
                labelPosition='left'>
                <Icon name='add square' />
                Tambah Lokasi
              </Button>
              {/* <Button
                secondary
                className="btn-hapus-filter"
                onClick={clearFilters}
              >
                Hapus Filter
              </Button> */}
            </Space>
          </Col>
          <Col md={12} sm={24} style={{ width: '100%' }} className='mt-mobile'>
            <Input
              value={searchText}
              style={{ width: '100%' }}
              placeholder='Cari data lokasi disini'
              onChange={(evt) => setSearch(evt.target.value)}
            />
          </Col>
        </Row>
        <Table
          onChange={onChangeTable}
          pagination={false}
          loading={loadingT}
          loadingIndicator={antIcon}
          columns={columns}
          dataSource={lokasi}
          scroll={{ x: 900, y: 1500 }}
          size='small'
        />
        {pagination && (
          <div className='pagination'>
            <Pagination
              total={pagination.total}
              showTotal={(total, range) =>
                `Menampilkan ${range[0]}-${range[1]} dari ${total} data`
              }
              defaultPageSize={Number(pagination.perPage)}
              current={pagination.current}
              onChange={onChangePagination}
            />
          </div>
        )}
        {/* <Button
          onClick={nextPage}
          size="small"
          floated="right"
          type="submit"
          color="green"
          loading={loading}
        >
          Pagination
        </Button> */}
        {modalCon && (
          <Modal
            centered
            title={modalCon.judul}
            visible={modalVisible}
            onCancel={handleCancel}
            footer={[]}>
            <Form
              layout='vertical'
              form={form}
              name='basic'
              initialValues={{ remember: false }}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}>
              <Form.Item
                labelAlign='left'
                label='Nama Lokasi'
                name='nama_lokasi'
                rules={[
                  {
                    required: true,
                    message: 'Bagian ini wajib diisi',
                  },
                ]}>
                <Input placeholder='Masukan nama lokasi' />
              </Form.Item>

              <Form.Item>
                <Button
                  size='small'
                  floated='right'
                  type='submit'
                  color='green'
                  loading={loading}>
                  Simpan
                </Button>

                <Button
                  negative
                  size='small'
                  floated='right'
                  type='button'
                  onClick={() => form.resetFields()}
                  loading={loading}>
                  Reset
                </Button>
              </Form.Item>
            </Form>
          </Modal>
        )}
      </div>
    </div>
  );
};

export default Lokasi;
