import React, { useContext } from 'react';
import myAxios from '../../../myAxios';
import {
  Table,
  Space,
  Spin,
  Row,
  Col,
  message,
  Modal,
  Tag,
  Tooltip,
  Popconfirm,
  Form,
  Pagination,
  Input,
  Select,
} from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { Button, Icon } from 'semantic-ui-react';
import { useState, useEffect } from 'react';
import { UserContext } from '../../../context/UserContext';
import bimAxios from '../../../bimAxios';
import { useHistory } from 'react-router-dom';

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
const tableLoading = {
  indicator: <Spin className='loading-data-table' indicator={antIcon} />,
};
const { Option } = Select;
const Unit = () => {
  const [user, setUser] = useContext(UserContext);
  const [unit, setUnit] = useState(null);
  const [fakultas, setFakultas] = useState([]);
  const [modalCon, setModalCon] = useState(null);
  const [searchText, setSearch] = useState('');
  const [loading, setLoading] = useState(false);
  const [loadingAdmin, setLoadingAdmin] = useState(false);
  const [loadingT, setLoadingT] = useState(false);
  const [loadingTambah, setLoadingTambah] = useState(false);
  const [loadingAct, setLoadingAct] = useState(false);
  const [modalVisible, setModal] = useState(false);
  const [pagination, setPagination] = useState(null);
  const [control, setControl] = useState('Unit');
  const [page, setPage] = useState('10');
  let { history } = useHistory;
  const [form] = Form.useForm();

  useEffect(() => {
    setLoadingT(tableLoading);
    const timeOutId = setTimeout(() => getUnit(searchText), 500);
    return () => clearTimeout(timeOutId);
  }, [searchText, control]);

  const getFakultas = async () => {
    await myAxios
      .get(`faculty/autocomplete`, {
        headers: {
          Authorization: 'Bearer ' + user.token,
        },
      })
      .then((res) => {
        const data = res.data.data;
        if (data.length > 0) {
          setFakultas(data);
        } else {
          setFakultas([]);
        }
        setLoadingT(false);
      })
      .catch((err) => {
        if (err.response.data.message === 'unauthorized') {
          message.error('Sesi anda telah habis!');
          localStorage.removeItem('user');
        } else if (err.response.data.msg === 'Unauthorization Role') {
          message.error(err.response.data.msg);
          history.goBack();
        } else {
          message.error(err.response.data.msg);
        }
        setLoadingT(false);
      });
  };
  const getUnit = (
    search = undefined,
    page = '1',
    sortBy = undefined,
    sorting = 'ASC',
    show = 10
  ) => {
    setLoadingT(tableLoading);

    myAxios
      .get(`unit`, {
        params: {
          page: Number(page),
          search: search,
          show: show,
          sortBy: sortBy,
          sorting: sorting,
        },
        headers: {
          Authorization: 'Bearer ' + user.token,
        },
      })
      .then((res) => {
        const data = res.data.data.data;

        if (data.length > 0) {
          setUnit(data);
        } else {
          setUnit([]);
        }
        setPagination({
          total: res.data.data.total,
          current: res.data.data.current_page,
          perPage: res.data.data.per_page,
        });
        setLoadingT(false);
      })
      .catch((err) => {
        if (err.response.data.message === 'unauthorized') {
          message.error('Sesi anda telah habis!');
          localStorage.removeItem('user');
        } else if (err.response.data.msg === 'Unauthorization Role') {
          message.error(err.response.data.msg);
          history.goBack();
        } else {
          message.error(err.response.data.msg);
        }
        setLoadingT(false);
      });
  };

  const getKaryawan = async (ignore_id = undefined) => {
    // await myAxios;
    // .get(`users-office`, {
    //   params: {
    //     ignore_id: ignore_id,
    //   },
    //   headers: {
    //     Authorization: 'Bearer ' + user.token,
    //   },
    // })
    // .then((res) => {
    //   const data = res.data.data;
    //
    //   if (data.length > 0) {
    //     setFakultas(data);
    //   } else {
    //     setFakultas([]);
    //   }
    //   setLoadingAdmin(false);
    // })
    // .catch((err) => {
    //   if (err.response.data.message === 'unauthorized') {
    //     message.error('Sesi anda telah habis!');
    //     localStorage.removeItem('user');
    //   } else {
    //     message.error(err.response.data.msg);
    //   }
    //   setLoadingAdmin(false);
    // });
  };

  const onChange = () => {};
  const onFocus = () => {};
  const onBlur = () => {};
  const onSearch = () => {};

  const onHapus = (param) => {
    setLoadingAct(true);
    myAxios
      .delete(`delete-admin/${param}`, {
        headers: {
          Authorization: 'Bearer ' + user.token,
        },
      })
      .then((res) => {
        getUnit();
        message.success(res.data.msg);
        setLoadingAct(false);
      })
      .catch((err) => {
        if (err.response.data.message === 'unauthorized') {
          message.error('Sesi anda telah habis!');
          localStorage.removeItem('user');
          setUser(null);
        } else if (err.response.data.msg === 'Unauthorization Role') {
          message.error(err.response.data.msg);
          history.goBack();
        } else {
          message.error(err.response.data.message);
        }
        setLoading(false);
      });
  };

  const clearFilters = () => {
    // getUnit();
  };

  const onChangeSearch = (evt) => {
    setSearch(evt.target.value);
    setControl('Unit');
  };

  const handleCancel = () => {
    setModal(false);
    form.resetFields();
  };

  const onFinish = (values) => {
    setLoading(true);
    if (modalCon.judul === 'Tambah Admin') {
      let newObj = {
        faculty_id: values.fakultas,
        username: values.username,
        name: values.nama,
        email: values.email,
      };

      myAxios
        .post(`create-admin`, newObj, {
          headers: {
            Authorization: 'Bearer ' + user.token,
          },
        })
        .then((res) => {
          message.success(res.data.msg);
          setLoading(false);
          setModal(false);
          form.resetFields();
          getUnit();
        })
        .catch((err) => {
          if (err.response.data.message === 'unauthorized') {
            message.error('Sesi anda telah habis!');
            localStorage.removeItem('user');
            setUser(null);
          } else if (err.response.data.msg === 'Unauthorization Role') {
            message.error(err.response.data.msg);
            history.goBack();
          } else {
            message.error(err.response.data.msg);
          }
          setLoading(false);
        });
    } else {
      let newObj = {
        name: values.nama_unit,
        admin_id: values.admin,
      };

      myAxios
        .put(`office/${modalCon.id}`, newObj, {
          headers: {
            Authorization: 'Bearer ' + user.token,
          },
        })
        .then((res) => {
          message.success(res.data.msg);
          setLoading(false);
          setModal(false);
          form.resetFields();
          // getUnit();
        })
        .catch((err) => {
          if (err.response.data.message === 'unauthorized') {
            message.error('Sesi anda telah habis!');
            localStorage.removeItem('user');
            setUser(null);
          } else if (err.response.data.msg === 'Unauthorization Role') {
            message.error(err.response.data.msg);
            history.goBack();
          } else {
            message.error(err.response.data.msg);
          }
          setLoading(false);
        });
    }
  };

  const onFinishFailed = (errorInfo) => {};

  const onTambahModal = async () => {
    setLoadingTambah(true);
    await getKaryawan();
    await getFakultas();
    setModal(true);
    setModalCon({
      judul: 'Tambah Admin',
    });
    setLoadingTambah(false);
  };
  const onEditModal = async (param) => {
    setLoadingAct(true);
    await getKaryawan(param.admin_id);

    setLoadingAct(false);
    setModal(true);
    setModalCon({
      judul: 'Ubah Unit',
      id: param.id,
    });
    form.setFieldsValue({
      fakultas: param.faculty_id,
      nama: param.admin,
      username: param.username,
      email: param.email,
    });
  };

  const onChangePagination = (evt, show) => {
    setPage(show);
    getUnit('', evt, null, null, show);
  };

  const onChangeTable = (pagination, filters, sorter, extra) => {
    const order = sorter.order === 'descend' ? 'DESC' : 'ASC';
    const field = sorter.order === undefined ? undefined : sorter.field;
    getUnit('', '1', field, order);
  };

  const columns = [
    {
      title: 'Nama Unit',
      dataIndex: 'faculty_name',
      key: 'faculty_name',
      sorter: () => {},
      ellipsis: true,
    },
    {
      title: 'Kode Unit',
      dataIndex: 'faculty_code',
      key: 'faculty_code',
      sorter: () => {},
      ellipsis: true,
    },
    {
      title: 'Admin',
      dataIndex: 'admin',
      key: 'admin',
      sorter: () => {},
      ellipsis: true,
    },
    {
      key: 'action',
      dataIndex: 'id',
      align: 'center',
      render: (dataIndex, row) => (
        <div>
          {!loadingAct && (
            <Space size='middle'>
              {/* <Tooltip
                placement='bottom'
                title='Ubah Unit'
                color='#1f1f1f'
                key='white'>
                <Button
                  size='mini'
                  color='facebook'
                  className='aksiEdit'
                  onClick={() => onEditModal(row)}
                  icon='pencil alternate'
                />
              </Tooltip> */}
              <Tooltip
                placement='bottom'
                title='Hapus Unit'
                color='#1f1f1f'
                key='white'>
                <Popconfirm
                  placement='bottomRight'
                  title='Apakah yakin ingin menghapus unit ?'
                  onConfirm={() => onHapus(row.faculty_id)}
                  okText='Hapus'
                  cancelText='Batal'>
                  <Button
                    negative
                    className='aksiHapus'
                    size='mini'
                    icon='trash'
                  />
                </Popconfirm>
              </Tooltip>
            </Space>
          )}
          {loadingAct && (
            <Spin className='loading-data-table' indicator={antIcon} />
          )}
        </div>
      ),
    },
  ];

  return (
    <div className='container-content'>
      <div className='container-detail'>
        <h1 className='table-title'>Unit</h1>
        <Row justify='space-between' style={{ marginBottom: '20px' }}>
          <Col md={12} sm={24}>
            <Space>
              <Button
                icon
                loading={loadingTambah}
                color='green'
                className='btn-hapus-filter'
                onClick={onTambahModal}
                labelPosition='left'>
                <Icon name='add square' />
                Tambah Admin
              </Button>
              {/* <Button
              secondary
              className="btn-hapus-filter"
              onClick={clearFilters}
            >
              Hapus Filter
            </Button> */}
            </Space>
          </Col>
          <Col md={12} sm={24} style={{ width: '100%' }} className='mt-mobile'>
            <Input
              value={searchText}
              style={{ width: '100%' }}
              placeholder='Cari data unit disini'
              onChange={onChangeSearch}
            />
          </Col>
        </Row>
        <Table
          onChange={onChangeTable}
          pagination={false}
          loading={loadingT}
          loadingIndicator={antIcon}
          columns={columns}
          dataSource={unit}
          scroll={{ x: 900, y: 1500 }}
          size='small'
        />
        {pagination && (
          <div className='pagination'>
            <Pagination
              total={pagination.total}
              showTotal={(total, range) =>
                `Menampilkan ${range[0]}-${range[1]} dari ${total} data`
              }
              defaultPageSize={Number(pagination.perPage)}
              current={pagination.current}
              onChange={onChangePagination}
            />
          </div>
        )}
        {/* <Button
        onClick={nextPage}
        size="small"
        floated="right"
        type="submit"
        color="green"
        loading={loading}
      >
        Pagination
      </Button> */}
        {modalCon && (
          <Modal
            centered
            title={modalCon.judul}
            visible={modalVisible}
            onCancel={handleCancel}
            footer={[]}>
            <Form
              layout='vertical'
              form={form}
              name='basic'
              initialValues={{ remember: false }}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}>
              <Form.Item
                labelAlign='left'
                label='Fakultas'
                name='fakultas'
                rules={[
                  {
                    required: true,
                    message: 'Bagian ini wajib diisi',
                  },
                ]}>
                {fakultas && (
                  <Select
                    style={{ width: '100%' }}
                    autoComplete='off'
                    showSearch
                    placeholder='Cari Fakultas'
                    optionFilterProp='label'
                    onChange={onChange}
                    onFocus={onFocus}
                    onBlur={onBlur}
                    onSearch={onSearch}
                    // filterOption={(input, option) =>
                    //   option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                    //   0
                    // }
                  >
                    {fakultas.map((val, item) => (
                      <Option key={val.id} value={val.id} label={val.name}>
                        {val.name}
                      </Option>
                    ))}
                  </Select>
                )}
              </Form.Item>

              <Form.Item
                labelAlign='left'
                label='Username (NIP)'
                name='username'
                rules={[
                  {
                    required: true,
                    message: 'Bagian ini wajib diisi',
                  },
                ]}>
                <Input placeholder='Masukan username' />
              </Form.Item>

              <Form.Item
                labelAlign='left'
                label='Nama'
                name='nama'
                rules={[
                  {
                    required: true,
                    message: 'Bagian ini wajib diisi',
                  },
                ]}>
                <Input placeholder='Masukan nama' />
              </Form.Item>

              <Form.Item
                labelAlign='left'
                label='Email'
                name='email'
                rules={[
                  {
                    type: 'email',
                    required: true,
                    message: 'Bagian ini wajib diisi',
                  },
                ]}>
                <Input placeholder='email@uajy.com' />
              </Form.Item>

              <Form.Item>
                <Button
                  size='small'
                  floated='right'
                  type='submit'
                  color='green'
                  loading={loading}>
                  Simpan
                </Button>

                <Button
                  negative
                  size='small'
                  floated='right'
                  type='button'
                  onClick={() => form.resetFields()}
                  loading={loading}>
                  Reset
                </Button>
              </Form.Item>
            </Form>
          </Modal>
        )}
      </div>
    </div>
  );
};

export default Unit;
